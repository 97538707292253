import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, fromEvent } from "rxjs";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { UtilsProvider } from "@shared/providers/utils.provider";
import { GeoPoint } from "@domain/data/structures/point";
import { WegaEvent, ExtentHistory } from "./ui.utils";
import { GeoExtent } from "@domain/data/structures/extent";
import { UserState } from "@domain/data/structures/user-state";
import { LocaleProvider } from "../../i18n/providers/i18n.provider";
import { MAP_MODE, MAP_SELECTON_MODE, MAP_MEASURE_MODE } from "@shared/wega-utils/wega-enums";
import { map, startWith } from "rxjs/operators";

declare var window: any;

@Injectable()
export class UiProvider {
  private isMobile$$: BehaviorSubject<boolean>;

  constructor(public appConfig: AppConfigProvider, public utils: UtilsProvider, private locale: LocaleProvider) {
    const isMobile = this.checkIsMobile();
    this.isMobile$$ = new BehaviorSubject<boolean>(isMobile);

    const mapsLoadedOnStart = this.appConfig.Environment.StartupMaps.map((m) => m.id);
    const mapsLoadedByUrl = this.appConfig.Environment.StartupMapIdList.filter((mid) => mapsLoadedOnStart.indexOf(mid) == -1);
    let hideCatalogs = isMobile ? mapsLoadedByUrl.length != 0 : !this.appConfig.Environment.ShowCatalogs;

    this.catalogOpen$$ = new BehaviorSubject<boolean>(!hideCatalogs);
    this.catalogOpen$ = this.catalogOpen$$.asObservable();

    fromEvent(window, "resize")
      .pipe(map(() => this.checkIsMobile()))
      .subscribe((isMobile) => {
        this.isMobile$$.next(isMobile);
        if (isMobile) {
          this.catalogOpen$$.next(false);
        } else {
          this.catalogOpen$$.next(this.appConfig.Environment.ShowCatalogs);
        }
      });

    this.zoom = appConfig.Environment.Zoom;
    this.center = appConfig.Environment.Center;

    this._mapMode = appConfig.Environment.MapMode;

    this.selectionMode = appConfig.Environment.MapSelectionMode;
    this.measureMode = appConfig.Environment.MapMeasureMode;
    this.showExtendedLayersInfo = appConfig.Environment.ShowLayersList;
    this.showOverviewMap = appConfig.Environment.ShowOverviewMap;

    utils.setFrameworkModule("UI", this);
  }

  private checkIsMobile(): boolean {
    return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  setCatalogOpen(value: boolean): void {
    this.catalogOpen$$.next(value);
  }

  get mapMode(): MAP_MODE {
    return this._mapMode;
  }

  set mapMode(mode: MAP_MODE) {
    this.selectChooseBoxHidden = mode !== MAP_MODE.SELECT;
    this.measureChooseBoxHidden = mode !== MAP_MODE.MEASURE;

    this._mapMode = mode;

    this.notify({ type: "MEASURE_TRIGGER", enabled: this._mapMode === MAP_MODE.MEASURE });
  }

  get log() {
    return this.utils.notify.bind(this.utils);
  }

  /// визуализация панели каталога
  private catalogOpen$$: BehaviorSubject<boolean>;
  public catalogOpen$: Observable<boolean>;

  /// визуализация панели сценариев (TODO)
  public scriptsOpen = false;

  /// визуализация панели отобранных карт
  public resourcesOpen = false;

  /// визуализация панели территориального фильтра
  public filterOpen = false;

  /// визуализация панели аналитического блока
  public analysisOpen = false;

  /// визуализация режима экспорта и печати карты
  public exportOpen = false;

  /// визуализация режима глубокого поиска
  public deepSearch = false;

  /// визуализация режима смены проекции
  public projectionsOpened = false;

  /// визуализация режима работы с ассистентом
  public assistantOpened = false;

  /// визуализация режима работы с данными
  public infoOpen = false;
  public infoWindowMaximized = false;

  public queryActivated = false;

  public overflowHidden = true;

  public basemap: string;
  public zoom = 1; // Init.Zoom;
  public center: number[] = [0, 0]; // Init.Center;

  public extent: GeoExtent = new GeoExtent("", 0, 0, 0, 0);
  public coordinates: GeoPoint = new GeoPoint(0, 0);

  private _mapMode: MAP_MODE = MAP_MODE.PAN; // Init.MapMode;

  public selectionMode: MAP_SELECTON_MODE = MAP_SELECTON_MODE.SELECT_POINT; // Init.MapSelectionMode;
  public measureMode: MAP_MEASURE_MODE = MAP_MEASURE_MODE.MEASURE_AREA; // Init.MapMeasureMode;

  public selectChooseBoxHidden = true;
  public measureChooseBoxHidden = true;

  public showExtendedLayersInfo = true; // Init.ShowExtendedLayersInfo;
  public showOverviewMap = true; // Init.ShowOverviewMap;

  public mapView: "esri" | "ol" | "cesium" = "esri";

  private event$ = new Subject<WegaEvent>();
  private eventSource$ = this.event$.asObservable();

  // toggleMapMode(mapMode: MAP_MODE): void {
  //   this.mapMode = mapMode;
  // }

  public swipeActivated = false;

  _timeout: any;
  isLoading = false;

  public extentHistory = new ExtentHistory();

  notify(event: WegaEvent) {
    this.event$.next(event);
  }

  listen(next: (message: WegaEvent) => void) {
    // TODO - все эти подписки надо фиксировать и destroy при вызове деструктора сервиса
    return this.eventSource$.subscribe(next);
  }

  public toggleCatalog() {
    this.setCatalogOpen(!this.catalogOpen$$.value);

    this.resourcesOpen = this.filterOpen = this.infoOpen = this.analysisOpen = false;
    this.disableSelect();
  }

  public toggleScripts() {
    this.scriptsOpen = !this.scriptsOpen;
  }

  public toggleResources() {
    this.setCatalogOpen(false);

    this.infoOpen = this.filterOpen = this.analysisOpen = false;
    this.disableSelect();

    this.resourcesOpen = !this.resourcesOpen;
  }

  public toggleFilter() {
    this.setCatalogOpen(false);

    this.infoOpen = this.resourcesOpen = this.analysisOpen = false;
    this.disableSelect();

    this.filterOpen = !this.filterOpen;

    this.notify({ type: "RESIZE", state: "open" });
  }

  public toggleAnalysis() {
    this.setCatalogOpen(false);

    this.infoOpen = this.resourcesOpen = this.filterOpen = false;
    this.disableSelect();

    this.analysisOpen = !this.analysisOpen;
  }

  public toggleInfo(force: boolean = false) {
    this.setCatalogOpen(false);

    this.filterOpen = this.resourcesOpen = this.analysisOpen = false;
    this.infoOpen = force || !this.infoOpen;

    if (this.infoOpen) {
      this.mapMode = MAP_MODE.SELECT;

      if (this.selectionMode === MAP_SELECTON_MODE.SELECT_EXTENT) {
        this.notify({ type: "START_SELECT" });
      }

      this.queryActivated = true;
    } else {
      this.disableSelect();
    }

    // if (this.ui.mapMode == MAP_MODE.MEASURE) {
    //   this.ui.mapMode = MAP_MODE.PAN;
    // }
  }

  disableSelect() {
    this.notify({ type: "DROP_SELECT" });

    // this.mapMode = MAP_MODE.PAN;
    // this.selectionMode = MAP_SELECTON_MODE.SELECT_POINT;
  }

  public toggleExport() {
    // this.overflowHidden = false;
    this.exportOpen = !this.exportOpen;
  }

  public toggleSearch() {
    this.deepSearch = !this.deepSearch;
  }

  public toggleProjections() {
    this.projectionsOpened = !this.projectionsOpened;
  }

  setExtent(extent: GeoExtent) {
    this.center = extent.getCenter();
    this.zoom = extent.getZoomLevel();
  }

  isMeasuring() {
    return this.mapMode === MAP_MODE.MEASURE;
  }

  loading(active: boolean) {
    const show = () => (this.isLoading = true);
    const hide = () => (this.isLoading = false);

    if (active) {
      show();
      this._timeout = setTimeout(() => {
        this.utils.notify(
          this.locale.current !== "en"
            ? "Выполнение операции занимает слишком много времени. Возможно, это происходит из-за нестабильного сетевого соединения."
            : "The operation takes too long to complete. This may be due to an unstable network connection.",
          this.appConfig.Environment.SupressLog,
          5000
        );

        hide();
      }, this.appConfig.Environment.LoadingAnimationTimeout);
    } else {
      clearTimeout(this._timeout);
      hide();
    }
  }

  recoverState(state: UserState) {
    if (state.Zoom && state.Center) {
      this.notify({ type: "CUSTOM_EXTENT", zoom: state.Zoom, center: state.Center });
    }

    if (state.Resources.length) {
      this.notify({ type: "CUSTOM_RESOURCES", resources: state.Resources });
    }
  }
}
