import { Component, Input, ChangeDetectionStrategy } from "@angular/core";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      .wega-unified-search-details-container {
        font-size: 12px;
        margin-bottom: 10px;
        margin-top: 10px;
      }
    `,
    `
      .wega-unified-search-details-expand {
        border: none;
        border-radius: 5px;
        background: white;
        box-shadow: 0px 0px 3px 0px silver;
        font-size: 11px;
        font-family: verdana;
        line-height: 15px;
        cursor: pointer;
      }
    `,
    `
      .wega-unified-search-details-expand:hover {
        background: #eeeeee;
      }
    `,
    `
      .wega-unified-search-details-list {
        width: 100%;
        display: block;
        overflow: hidden;
        border-spacing: initial;
      }
    `,
    `
      .wega-unified-search-details-list tr:nth-child(2n) {
      }
    `,
    `
      .wega-unified-search-details-list tr:not(:last-child) td {
        border-bottom: 1px solid #0000004f;
        vertical-align: top;
      }
    `,
  ],
  selector: "wega-unified-search-details",
  template: `
    <div
      class="wega-unified-search-details-container"
      *ngIf="{ opened: false } as state"
    >
      <button
        class="wega-unified-search-details-expand"
        *ngIf="!state.opened"
        (click)="state.opened = true"
        [locale]="{ innerHTML: { en: 'Show attribute data' } }"
      >
        Показать атрибутивные данные
      </button>

      <table class="wega-unified-search-details-list" *ngIf="state.opened">
        <thead style="display: hidden;">
          <tr>
            <th style="width: 20%;">Ключ</th>
            <th style="width: 80%;">Значение</th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="
              let property of input.properties | keyvalue;
              let index = index
            "
          >
            <tr>
              <td>{{ property.key }}</td>
              <td>{{ property.value }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  `,
})
export class UnifiedSearchDetailsComponent {
  constructor() {}

  @Input() input: {
    properties: { [key: string]: string };
  };
}
