import { NgModule, ModuleWithProviders, InjectionToken } from "@angular/core";
import { WegaDomainProvider } from "./wega-domain.provider";

interface DomainInfo {}

export enum DOMAIN_INFO {
  DEFAULT,
}

const DomainInfoProvider = new InjectionToken<DomainInfo>("DomainInfo");

@NgModule({
  providers: [],
})
export class WegaDomainModule {
  static forRoot(domainInfo: DomainInfo | DOMAIN_INFO): ModuleWithProviders {
    return {
      ngModule: WegaDomainModule,
      providers: [
        WegaDomainProvider,
        {
          provide: DomainInfoProvider,
          useValue: domainInfo,
        },
      ],
    };
  }

  static forChild(): ModuleWithProviders {
    return {
      ngModule: WegaDomainModule,
    };
  }
}
