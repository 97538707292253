import { WegaSearchResult, StatefulSearchEngine } from '../../search.engine';
import { ArcGisMapProvider } from 'src/app/modules/wega-ui/components/arcgis-map/providers/arcgis-map.provider';
import { EsriProvider } from '@wega-providers/esri.provider';
import { LocaleProvider } from 'src/app/modules/i18n/providers/i18n.provider';

export class ArcGisLocatorSearchEngine extends StatefulSearchEngine {
  constructor(
  private arcgis: ArcGisMapProvider,
  private esri: EsriProvider,
  private locale: LocaleProvider) {
    super();
  }

  get title(): string {return this.locale.current === 'en' ? 'Geographical objects' : 'Географические объекты'; }
  get description(): string {return this.locale.current === 'en' ? 'Search for a location by name' :  'Поиск местоположения по названию'; }

  // поиск через локатор не поддерживает продвинутые поисковые запросы (И, ИЛИ, НЕ и т.п.),
  // так как обработка запроса происходит на сервере ESRI
  // в теории можно формировать запрос по каждой из составляющих фраз, а потом формировать итоговый запрос на клиенте,
  // но это может в несколько раз увеличит время обработки запроса, которое и сейчас не мгновенное
  supportsComplexSearch = false;

  async engineSearch(text: string): Promise<WegaSearchResult[]> {
    const search = window['SEARCH'];

    // поэтому просто берем первое слово из введенной фразы и ищем по нему
    const txt = text.split(' ').reverse().pop();
    const response = await search.suggest(txt);

    if (!response) {
      return [];
    }

    const suggestions = response[0];
    const answers = [];

    for (const suggestion of suggestions) {
      const result = await search.search(suggestion);
      answers.push(result);
    }

    return answers.map(res => {
      const ft = res[0][0];

      return <WegaSearchResult> {
        name: ft.name,
        zoom: async () => {
          this.zoomToExtent(ft.extent);

          const marker = await this.esri.putMarker(ft.extent.getCenter(), this.arcgis.EsriMap);
          await this.esri.clearMarker(marker, this.arcgis.EsriMap);

          return true;
        },

        values: [txt]
      };
    });
  }

  zoomToExtent(extent: any) {
    this.arcgis.EsriMap.setExtent(extent);
  }
}
