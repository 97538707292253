import { ConfigService } from "./config-service";
import { Required } from "@system/decorators";
import { FeatureDescription } from "@domain/data/feature/feature-description";
import { GeoExtent } from "@domain/data/structures/extent";
import { WegaUtils } from "@shared/wega-utils/wega-utils";
import { FieldConfig } from "./config-field";

export class ConfigResource {
  /** Идентификатор ресурса. Если не задано, то генерируется автоматически при инициализации ресурса. */
  public id: string;

  /** Название (отображается пользователю). */
  public title: string;

  /** Полное название (может отсутствовать). */
  public longTitle: string;

  /** Источник ресурса (карты). */
  public origin: string;

  /** Актуальность ресурса. */
  public relevance: string;

  /** Коллекция сервисов, составляющих ресурс. По умолчанию = []. */
  public servicesList: ConfigService[] = [];

  /** Описание ресурса (отображается для пользователя). */
  public description: string;

  /** Список полей, которых нужно выводить в виде форматированного текста. По умолчанию = []. */
  public formattedFields: string[] = [];

  /** Выполнять сортировку атрибутивных полей по названию.  По умолчанию = false. */
  public sortFields: boolean = false;

  /** Экстент карты ресурса. */
  public extent: GeoExtent;

  /** Атрибуты для вывода названия в окне выделенных features. По умолчанию = []. */
  public featureDisplayAttrs: string[] = [];

  /** Разрешается скачивать выбранные объекты (shapefile). По умолчанию = true. */
  public allowDownloadSelected = true;

  /// ресурс всегда должен выводиться поверх прочих карт
  public pinned = false;

  /** При добавлении ресурс выводится включенным. По умолчанию = true. */
  public visibility = true;

  /** Ресурс не будет показан в каталоге. По умолчанию = false. */
  public hidden = false;

  /** Явное указание способности ресурса реализовать запрос-фильтр. По умолчанию = false. */
  public filterEnabled = false;

  /** Список всех слоев ресурса (создается динамически). По умолчанию = []. */
  availableLayers: Array<{ name: string; title: string }> = [];

  /** Ресурс отключен. По умолчанию = false. */
  public disabled = false;

  /** Разрешены ли функции редактирования данных для данного ресурса. По умолчанию = false.
   * В перспективе этот параметр должен определяться автоматически, на основе анализа составляющих сервисов. */
  public editAllowed = false;

  public fields: FieldConfig[] = [];
  public extendFields: FieldConfig[] = [];

  /** Поля, которые не нужно выводить при запросе. По умолчанию = []. */
  public forbiddenFields: string[] = [];

  /** Нужно ли прятать пустые поля при выводе атрибутивных данных. Имеет приоритет над глобальным параметром HideEmptyAttributeValues. По умолчанию = undefined. */
  public hideEmptyFields: boolean | undefined = undefined;

  /** Нужно ли прятать легенду для тех слоев, в которых вообще отсутствуют элементы легенды. По умолчанию = false. */
  public hideEmptyLegends: boolean = false;

  /** Использовать для названий слоев только имена конечных слоев (без титулов групповых слоев). По умолчанию = false. */
  public onlyLastTitles: boolean = false;

  /** Если включен, то подбор необходимых слоев для панели слоев выполняется черехз indexOf (например, если layers=['Geol'], то в отбор попадут 'Geol', 'Geol_layer', 'Geol1'). По умолчанию = false. */
  public fuzzyLayersGrouping: boolean = false;

  /** Массив файловых легенд слоев. Используется для подмены динамической легенды слоя списком файлов.
   * Чтобы добавить легенду в дополнение к уже существующей легенде, а не вместо нее, можно указать "+{имя слоя}" в качестве name.
   * По умолчанию = []. */
  public fileLegends: Array<{ name: string; title: string; legends: string[] }> = [];

  /** Массив файловЫх легенда карты, которые будут отображаться перед легендами слоев. По умолчанию = []. */
  public mapFileLegends: Array<{ name: string; legends: string[] }> = [];

  /** Ресурс является контейнером - при его выборе нужно только включить/выключить дочерние элементы. По умолчанию = false. */
  public switchChildrenOnly: boolean = false;

  /** Исходный объект JSON-конфига. */
  public _source: any = {};

  /** Напрямую не задаeтся! По умолчанию = false. */
  public filterLegendMode: "none" | "extent" | "spatial" = "none";

  /**  Напрямую не задаются! Извлекаются из поля fields. */
  public featureDescription: FeatureDescription;

  /**  Напрямую не задаются! Извлекаются из поля extendFields. */
  public featureExtensions: FeatureDescription;

  constructor(JSONConfig: any) {
    this._source = JSONConfig;

    /// строки
    this.id = JSONConfig["id"] || WegaUtils.createGuid();
    this.description = JSONConfig["description"];
    this.title = JSONConfig["title"];
    this.longTitle = JSONConfig["longTitle"];
    this.origin = JSONConfig["origin"];
    this.relevance = JSONConfig["relevance"];

    // массивы
    this.featureDisplayAttrs = JSONConfig["featureDisplayAttrs"] || this.featureDisplayAttrs;
    this.formattedFields = JSONConfig["formattedFields"] || this.formattedFields;
    this.fileLegends = JSONConfig["fileLegends"] || this.fileLegends;
    this.mapFileLegends = JSONConfig["mapFileLegends"] || this.mapFileLegends;

    // булевы значения
    this.allowDownloadSelected = ConfigService.getConfigValue(JSONConfig, "allowDownloadSelected", this.allowDownloadSelected);
    this.filterEnabled = ConfigService.getConfigValue(JSONConfig, "filterEnabled", this.filterEnabled);
    this.sortFields = ConfigService.getConfigValue(JSONConfig, "sortFields", this.sortFields);
    this.pinned = ConfigService.getConfigValue(JSONConfig, "pinned", this.pinned);
    this.disabled = ConfigService.getConfigValue(JSONConfig, "disabled", this.disabled);
    this.editAllowed = ConfigService.getConfigValue(JSONConfig, "editAllowed", this.editAllowed);
    this.hidden = ConfigService.getConfigValue(JSONConfig, "hidden", this.hidden);
    this.hideEmptyFields = ConfigService.getConfigValue(JSONConfig, "hideEmptyFields", undefined);
    this.onlyLastTitles = ConfigService.getConfigValue(JSONConfig, "onlyLastTitles", this.onlyLastTitles);
    this.fuzzyLayersGrouping = ConfigService.getConfigValue(JSONConfig, "fuzzyLayersGrouping", this.fuzzyLayersGrouping);
    this.hideEmptyLegends = ConfigService.getConfigValue(JSONConfig, "hideEmptyLegends", this.hideEmptyLegends);
    this.switchChildrenOnly = ConfigService.getConfigValue(JSONConfig, "switchChildrenOnly", this.switchChildrenOnly);

    // используются не напрямую, а для извлечения производных данных
    if (JSONConfig["fields"]) {
      this.featureDescription = new FeatureDescription(JSONConfig["fields"]);
    }

    if (JSONConfig["extendFields"]) {
      this.featureExtensions = new FeatureDescription(JSONConfig["extendFields"]);
    }

    if (JSONConfig["forbiddenFields"]) {
      this.forbiddenFields = JSONConfig["forbiddenFields"];
    }

    if (JSONConfig["extent"]) {
      const extentNumbers = JSONConfig["extent"] as number[];
      this.extent = new GeoExtent("3857", extentNumbers[0], extentNumbers[1], extentNumbers[2], extentNumbers[3]);
    }

    if (JSONConfig["service"]) {
      let serviceList: any[] = JSONConfig["service"];
      !serviceList.length && (serviceList = [serviceList]);

      serviceList.forEach((service) => {
        const serviceConfig = new ConfigService(service);
        this.servicesList.push(serviceConfig);

        serviceConfig.attributeName.forEach((attrName) => {
          this.featureDisplayAttrs.push(attrName);
        });
      });
    }
  }

  merge(configResource: ConfigResource) {
    this.title = configResource.title || this.title;
    this.description = configResource.description || this.description;

    for (const anotherService of configResource.servicesList) {
      for (const service of this.servicesList) {
        if (service.id === anotherService.id) {
          service.merge(anotherService);

          return;
        }
      }

      this.servicesList.push(anotherService);
    }
  }
}
