import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { OverlayModule } from "@angular/cdk/overlay";
import { ScrollingModule } from "@angular/cdk/scrolling";

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule, DragDropModule, OverlayModule, ScrollingModule],

  exports: [CommonModule, ReactiveFormsModule, FormsModule, DragDropModule, OverlayModule, ScrollingModule],
})
export class AngularStandardModule {}
