import { FieldsInfo } from "../../feature/fields-info";
import { QueryFeature } from "../../feature/query-feature";
import { ResourceFilter } from "../../filter/resource-filter";
import { LegendDescription } from "../../legend/legend-description";
import { EsriProvider } from "@wega-providers/esri.provider";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { MapService } from "@domain/data/resource/map-service";
import { ConfigService } from "@shared/config/config-service";
import { MapClickPoint } from "@domain/data/structures/map-click-point";
import { MapGeometry } from "./map-geometry";
import { LayerData } from "@domain/data/structures/layer-data";
import { GeoExtent } from "@domain/data/structures/extent";
import { WegaServiceCapabilities } from "../service-capabilities";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";
import { WebClientLegacyProvider } from "@shared/providers/web-client.provider.legacy";
import { ServiceState } from "@shared/wega-utils/wega-enums";
import { LayerProvider } from "@wega-providers/layer.provider";
import { LayerStatistic } from "@domain/data/structures/layerStatistic";

export class WmsService implements MapService {
  state: ServiceState;
  canUseFilter: boolean = true;
  layer: any;
  cqlFilter = "";

  webLegacy: WebClientLegacyProvider;

  constructor(
    private config: ConfigService,
    private web: WebClientProvider,
    private appConfig: AppConfigProvider,
    private esri: EsriProvider,
    private layerProvider: LayerProvider,
    private locale: LocaleProvider,
    private capabilities: WegaServiceCapabilities
  ) {
    this.esri.setProxy(this.config);
    this.webLegacy = new WebClientLegacyProvider(this.web.client);
  }

  public getLegend(): LegendDescription[] {
    const description = new LegendDescription();

    this.config.layers.forEach((layer) => {
      const url = this.createLegendUrl(layer);
      description.setURL(layer, url);
    });

    return [description];
  }

  async getLayerStatistic(layer: string, filter: ResourceFilter) {
    return new LayerStatistic();
  }

  createLegendUrl(layer: string) {
    const endsWithQuestionMark = this.config.url.slice(this.config.url.length - 1, this.config.url.length) == "?";
    const url = `${this.config.url}${endsWithQuestionMark ? "" : "?"}SERVICE=WMS&REQUEST=GetLegendGraphic&FORMAT=image/png&TRANSPARENT=TRUE&STYLES=&VERSION=${this.config.version || "1.3.0"
      }&SLD_VERSION=1.1.0&LAYER=${layer}`;

    return url;
  }

  async getLayer(): Promise<any> {
    const [WMSLayer, Extent, SpatialReference] = await this.esri.loadModules(["esri/layers/WMSLayer", "esri/geometry/Extent", "esri/SpatialReference"]);

    const worldExtent = new Extent({
      // TODO - полный экстент должен определяться динамически
      xmin: 1798248.3188846242, // -20026376.39
      ymin: 4452614.762526358, // -20048966.10
      xmax: 20388887.552838176, // 20026376.3
      ymax: 13883491.123917378, // 20048966.10
      spatialReference: new SpatialReference(3857),
    }),
      customParameters = {};

    Object.assign(customParameters, this.config.customParameters);
    if (this.config.style) {
      customParameters["styles"] = this.config.style;
    }

    this.layer = new WMSLayer(this.config.url, {
      resourceInfo: { extent: worldExtent, layerInfos: [] },
      visibleLayers: this.config.layers,
      customLayerParameters: this.config.customParameters,
    });

    this.layer.version = this.config.version || "1.3.0";
    this.layer.spatialReferences[0] = 3857;
    this.layer.setVisibility(true);

    return this.layer;
  }

  getAttributesByID(featureID: string): Promise<any> {
    return null;
  }

  loadLayerInfo(): Promise<FieldsInfo> {
    return null;
  }

  async getPointFeatureInfo(coordinates: MapClickPoint): Promise<any> {
    let features = [];

    var queryLayers = this.config.queryLayers ?? this.config.layers;
    /// из-за отдельных странностей при запросе данных к набору слоев
    /// используется вариант с запросом к слоям по отдельности и последующим объединением результатов
    for (const layer of queryLayers) {
      const layerFeatures = await this.getPointFeatureInfoForSingleLayer(layer, coordinates);
      features = features.concat(layerFeatures);
    }

    return features;
  }

  async getPointFeatureInfoForSingleLayer(layer: string, coordinates: MapClickPoint): Promise<any> {
    const x = coordinates.x;
    const y = coordinates.y;
    const layersList = layer;
    const featuresCount = 100;
    const bboxX = coordinates.extentXDiff;
    const bboxY = coordinates.extentYDiff;
    const vX = Math.round(coordinates.mapWidth / 2);
    const vY = Math.round(coordinates.mapHeight / 2);
    /// для запроса достаточно только координат - запрашиваем BBOX с центром в нужных координатах
    /// запрашиваем щелчок в центр картинки размером 100 на 100.

    // layersList = this.config.layers.map(l => encodeURI(l)).join(',');
    let srs = coordinates.srs;

    /// замена проекции веб-меркатора из аркгиса (102100) на обычный веб-меркатор (3857)
    srs = srs.toString().replace("102100", "3857");

    /// замена проекции веб-меркатора гугла (900913) на обычный веб-меркатор (3857).. вряд ли встретиться, но вдруг.
    srs = srs.toString().replace("900913", "3857");

    const queryParams = {
      REQUEST: "GetFeatureInfo",
      SERVICE: "WMS",
      BBOX: x - bboxX + "," + (y - bboxY) + "," + (x + bboxX) + "," + (y + bboxY),
      x: vX,
      i: vX,
      y: vY,
      j: vY,
      VERSION: "1.1.1",
      INFO_FORMAT: this.config.responseFormat,
      LAYERS: layersList,
      QUERY_LAYERS: layersList,
      WIDTH: coordinates.mapWidth,
      HEIGHT: coordinates.mapHeight,
      SRS: "EPSG:" + srs,
      CRS: "EPSG:" + srs,
      FEATURE_COUNT: featuresCount,
    };

    if (this.cqlFilter) {
      queryParams["CQL_FILTER"] = this.cqlFilter;
    }

    //let getFeaturesRequest = await this.web.httpGet<any>(this.config.url, {queryParams});
    let getFeaturesRequest = await this.webLegacy.httpGet(this.config.url, this.appConfig.Environment.CorsScript, {}, queryParams, true);

    if (typeof getFeaturesRequest === "string") {
      this.config.htmlRewrite.forEach((rule) => {
        getFeaturesRequest = getFeaturesRequest.replace(new RegExp(rule.find, "g"), rule.replaceWith);
      });

      const newFeature = new QueryFeature(this.config, this.capabilities, this.locale);

      newFeature.addHtmlResponse(this.config.title, getFeaturesRequest);
      return newFeature;
    }

    const features: QueryFeature[] = this.getFeaturesFromWMSResponse(getFeaturesRequest);
    return features;
  }

  getFeaturesFromWMSResponse(wmsResponse: any): QueryFeature[] {
    const wmsKnownResponse = wmsResponse["wfs:FeatureCollection"] || wmsResponse["msGMLOutput"];

    if (wmsKnownResponse) {
      const features: QueryFeature[] = [];
      const keys = Object.keys(wmsKnownResponse[0]);

      for (const key of keys) {
        if (key !== "gml:boundedBy" && key !== "_attributes") {
          let featureName = key;
          const featuresList = wmsKnownResponse[0][featureName];

          if (featuresList) {
            for (let i = 0; i < featuresList.length; i++) {
              const layersList = featuresList[i];
              for (const layerName in layersList) {
                if (layerName === "gml:name") {
                  continue;
                }

                const layerFeaturesList = featuresList[i][layerName];
                for (const feature of layerFeaturesList) {
                  const newQueryResult = this.createQueryFeature(feature);
                  features.push(newQueryResult);
                }
              }
            }
          }
        }
      }

      return features;
    }

    const dummy = new QueryFeature(this.config, this.capabilities, this.locale);
    dummy.addAtributes(this.config.title, wmsResponse);
    return [dummy];
  }

  createQueryFeature(wmsFeatureDefinition: any) {
    const feature = new QueryFeature(this.config, this.capabilities, this.locale);
    const attributes = {};

    for (const attrName in wmsFeatureDefinition) {
      if (attrName[0] === "_") {
        continue;
      }

      if (attrName === "gml:boundedBy" || attrName === "gml:coordinates" || attrName === "geometry") {
        const geometry = MapGeometry.fromGML(wmsFeatureDefinition[attrName][0]).getEsriGeometry();

        feature.setGeometry(geometry);
      } else {
        if (wmsFeatureDefinition[attrName][0]["_text"]) {
          let newAttrName = attrName;
          if (newAttrName && newAttrName.indexOf(":") > -1) {
            // Это чтобы очистить namespace от имени колонки. ( rasterdb:NAME -> NAME)
            newAttrName = newAttrName.substr(newAttrName.indexOf(":") + 1);
          }

          attributes[newAttrName] = wmsFeatureDefinition[attrName][0]["_text"][0];
        }
      }
    }

    feature.addAtributes(this.config.title, attributes, this.config.fieldID);

    /// для совместимости с механизмом приближения
    feature.arcgisFeature = { geometry: feature.coords };

    return feature;
  }

  getFeaturesByQuery(pFilter: ResourceFilter): Promise<[QueryFeature[], boolean]> {
    throw new Error("Method not implemented.");
  }

  setFilter(filter: ResourceFilter) {
    this.cqlFilter = filter.sqlWms;
    if (this.cqlFilter) {
      this.layer.customLayerParameters["CQL_FILTER"] = this.cqlFilter;
    } else {
      delete this.layer.customLayerParameters["CQL_FILTER"];
    }
  }

  saveFeatures(queryFeature: any): Promise<boolean> {
    throw new Error("Method not implemented.");
  }

  getExtentFeatureInfo(extent: GeoExtent): Promise<QueryFeature[]> {
    throw new Error("Method not implemented.");
  }

  public async loadSpatialData(): Promise<LayerData[]> {
    return null;
  }
}
