import { ServiceType } from "@shared/wega-utils/wega-enums";
import { WegaResource } from "./wega-resource";
import { DUMMY_RESOURCE_TOKEN } from "src/app/modules/wega-providers/utils/resource.utils";

export class WegaResourceCapabilities {
  resource: WegaResource;
  constructor(resource: WegaResource) {
    this.resource = resource;
  }

  get canUseFilter() {
    return this.resource.serviceModuleList.filter((sm) => sm.canUseFilter()).length > 0 && this.resource.config.filterEnabled;
  }

  get canUseSearch() {
    return this.resource.serviceModuleList.filter((sm) => sm.getConfig().supportClick).length > 0;
  }

  get canRenderLegend() {
    return this.resource.serviceModuleList.filter((sm) => sm.capabilities.canGetLegend).length > 0;
  }

  get canShowLayers() {
    return this.resource.serviceModuleList.filter((sm) => sm.getConfig().layersListEnabled).length > 0;
  }

  get canUseExtentQuery() {
    return !this.isBaseResource && this.resource.serviceModuleList.filter((sm) => sm.getConfig().type === ServiceType.arcgis).length > 0;
  }

  get isBaseResource() {
    return this.resource.config.id.indexOf("-base-") !== -1;
  }

  get isDummyResource() {
    return this.resource.config.id.startsWith(DUMMY_RESOURCE_TOKEN);
  }

  get extentQueryNotSupported() {
    return this.resource.serviceModuleList.every((sm) => sm.getConfig().type === ServiceType.wms);
  }

  get canBeDownloaded() {
    const allowDownload = this.resource.config.allowDownloadSelected;
    const selectedFeaturesExist = this.resource.selectedFeatures?.length !== 0;
    const isAppropriateType = this.resource.serviceModuleList.filter((s) => s.capabilities.canBeServedAsShape).length > 0;

    return allowDownload && selectedFeaturesExist && isAppropriateType;
  }
}
