import { loadModules } from "esri-loader";
import { LegendDescription } from "../../legend/legend-description";
import { ResourceFilter } from "../../filter/resource-filter";
import { FieldsInfo } from "../../feature/fields-info";
import { QueryFeature } from "../../feature/query-feature";
import { EsriProvider } from "@wega-providers/esri.provider";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { MapService } from "@domain/data/resource/map-service";
import { ConfigService } from "@shared/config/config-service";
import { LayerData } from "@domain/data/structures/layer-data";
import { MapClickPoint } from "@domain/data/structures/map-click-point";
import { WegaServiceCapabilities } from "../service-capabilities";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";
import { GenericService } from "../generic-service";
import { ServiceState } from "@shared/wega-utils/wega-enums";
import { LayerProvider } from "@wega-providers/layer.provider";
import { FieldConfig } from "@shared/config/config-field";
import * as terraformerAG from "terraformer-arcgis-parser";
import newxtGisApi from "./nextGisApi";
import { LegendRender } from "@domain/data/legend/legend-render";
import { LegendRecord } from "@domain/data/legend/legend-record";
import { LegendParser } from "@domain/data/legend/legend-parser";
import { LayerStatistic } from "@domain/data/structures/layerStatistic";

export class NextGisService implements MapService {
  constructor(public service: GenericService) {
    this.config = service.getConfig();
    this.webClient = service.web;
    this.esri = service.esri;
    this.globalConfig = service.appConfig;
    this.capabilities = service.capabilities;
    this.locale = service.locale;
    this.layerProvider = service.layerProvider;
    // this.clickRadius = this.config.clickRadius;

    this.initApi(this.config);
    this.esri.setProxy(this.config);

  }

  state: ServiceState;
  canUseFilter: boolean = true;

  config: ConfigService;
  webClient: WebClientProvider;
  esri: EsriProvider;
  globalConfig: AppConfigProvider;
  capabilities: WegaServiceCapabilities;
  locale: LocaleProvider;
  layerProvider: LayerProvider;
  layer: any;
  drawResource: number;

  clickRadius: number = 10;
  maxRecordsCount = 10000;
  mainResource: string;
  api: newxtGisApi;
  coordinateSystemMap = {
    '102100': 3857
  }
  webMapLayersInfo = {}
  webMapStylesId = [];
  webMapInfoPromise = null;
  fieldsAlias = {}
  getLayerPromise: any = null;
  getLayerInfoPromise: any = null;
  //https://docs.nextgis.ru/docs_ngweb_dev/doc/developer/resource.html#get--api-resource-(int-id)-feature-?limit=(int-limit)&offset=(int-offset)&intersects=(string-wkt_string)&fields=(string-field_name_1,string-field_name_2,...)&fld_field_name_1=(string-value)&fld_field_name_2=(string-value)&fld_field_name_3__ilike=(string-value)&fld_field_name_4__like=(string-value)&extensions=(string-extensions)
  sqlToNextGisConditionsMap = {
    '>': 'gt',
    '<': 'lt',
    '>=': 'ge',
    '<=': 'le',
    '=': 'eq',
    '!=': 'ne',
    'like': 'like'
  }




  layerLegend: LegendDescription[] = [];

  private getWebMapLayers(node: any = {}) {
    let result = [];

    if (node.item_type == 'layer') {
      if (this.config.layers && this.config.layers.length > 0 && this.config.layers.find((l) => l == node.style_parent_id)) {
        result.push(node);
      }

    }

    if (Array.isArray(node.children)) {
      node.children.forEach(_child => {
        result = result.concat(this.getWebMapLayers(_child));
      });
    }

    return result;
  }

  private initApi({ url }) {
    let urlObj = (new URL(url));
    let resourceParts = urlObj.pathname.match(/\/resource\/(\d+)/);
    let resource = resourceParts ? resourceParts[1] : '';

    this.api = new newxtGisApi(urlObj.origin);
    this.mainResource = resource;
  }

  getLegend(): LegendDescription[] {
    return this.layerLegend;
  };

  public getTileLayerUrl(layers: String[]) {
    let layersListStr = layers.join(',');

    return this.api.getRenderTileUrl(layersListStr);
  }

  async getLayer(): Promise<any> {
    if (!this.getLayerPromise) {
      let resolve: any = () => { };
      this.getLayerPromise = new Promise((res) => { resolve = res; });
      await this.loadLayerInfo();
      const [WebTiledLayer] = await loadModules([
        "esri/layers/WebTiledLayer",
      ]);
      let tileLayerUrl = this.getTileLayerUrl(this.webMapStylesId);

      this.layer = new WebTiledLayer(tileLayerUrl);
      resolve();

      return this.layer;
    } else {
      await this.getLayerPromise;
      return this.layer;
    }
  };

  async loadLayerInfo() {
    if (!this.getLayerInfoPromise) {
      let resolve: any = () => { };
      let loadPromise = new Promise((res) => { resolve = res; });
      this.getLayerInfoPromise = loadPromise;


      let infoJSON = await this.api.getResourceInfo(this.mainResource);
      const fields: FieldsInfo = new FieldsInfo();

      try {
        let layers = this.getWebMapLayers(infoJSON.webmap.root_item);

        for (const layerInfo of layers) {
          let layerId = layerInfo.style_parent_id;
          let legendId = layerInfo.layer_style_id;

          /// не индексировать данные для тех слоев, которых нет в явно заданном списке слоеа
          if (this.config.layers && this.config.layers.length > 0 && null === this.config.layers.find((l) => l == layerId)) {
            continue;
          }

          layerInfo.id = legendId;
          this.webMapLayersInfo[layerId] = layerInfo;
          if (!~this.webMapStylesId.indexOf(legendId)) {
            this.webMapStylesId.push(legendId);
          }

          //   /// Формируем легенду
          const newLayerLegend = new LegendDescription();
          let legendInfoArray = await this.api.getLegendInfo(legendId);

          newLayerLegend.name = `${this.locale.current !== "en" ? "Слой: " : "Layer: "} ${layerInfo.display_name} [${legendId}]`;
          newLayerLegend.layer = layerInfo;
          const newRender = new LegendRender();
          for (const record of legendInfoArray) {
            const dataUrl = "data:image/" + record.icon.format + ";base64," + record.icon.data;
            const newLegendRecord = await LegendRecord.fromDataURL(legendId, this.config, record.display_name, record.index, dataUrl, null, null);

            if (!newLayerLegend.recordWithLabelAndSymbolExists(newRender, newLegendRecord)) {// && !newLayerLegend.recordIsExcluded(legendId, this.config, newLegendRecord.sourceLabel)) {
              newRender.addRecord(newLegendRecord);
            }
          }

          this.config.legendParsers.forEach((parserId) => {
            LegendParser.transform(newRender, parserId);
          });

          newLayerLegend.rendersList.push(newRender);

          const showLegend = (config: ConfigService, layerId: string) =>
            config.hideLegends.length == 0 || (config.hideLegends[0] != "*" && !config.hideLegends.find((l) => l.toString().trim() == layerId.toString().trim()));

          if (showLegend(this.config, layerId)) {
            this.layerLegend.push(newLayerLegend);
          }

          /// Формируется список полей (для поиска)
          try {
            let layerInfoJson = await this.api.getResourceInfo('' + layerId);
            let fieldsInfo = layerInfoJson.feature_layer.fields || [];

            for (const _fInfo of fieldsInfo) {
              const newFieldDesc = new FieldConfig();

              newFieldDesc.name = _fInfo.keyname;
              newFieldDesc.type = _fInfo.datatype;
              newFieldDesc.filterName = _fInfo.keyname;
              newFieldDesc.title = _fInfo.display_name;
              newFieldDesc.layer = layerId;
              newFieldDesc.service = this.service;

              this.fieldsAlias[_fInfo.keyname] = _fInfo.display_name;
              fields.addField(newFieldDesc);
            }
          } catch (e) {
            console.warn('Не удалось получить информацию по слою NextGis - ' + layerId, e);
          }
        }

        this.mainResource = layers[0].layer_style_id;
      } catch (e) {
        console.log('NEXTGIS LAYER INIT FAIL', e);
      }

      resolve(fields);
      return fields;
    } else {
      return await this.getLayerInfoPromise;
    }
  }

  async queryFeaturesOnExtent(geom, initSrs, layers, coordinateSystemMap) {
    let srs = coordinateSystemMap.hasOwnProperty('' + initSrs) ? coordinateSystemMap[initSrs] : initSrs;
    let json = await this.api.identify({ srs, geom, layers });
    let result = [];

    for (let _lId of layers) {
      if (json.hasOwnProperty(_lId)) {
        let features = json[_lId].features || [];

        result = result.concat(await this.nextGisFeatureToQueryFeature(features, this.fieldsAlias, srs));
      }
    }

    return result;
  }

  async getPointFeatureInfo(coordinates: MapClickPoint): Promise<QueryFeature[]> {
    try {
      let rX = (coordinates.extentXDiff / coordinates.mapWidth) * this.clickRadius;
      let rY = (coordinates.extentYDiff / coordinates.mapHeight) * this.clickRadius;
      let rtXY = `${coordinates.x + rX} ${coordinates.y + rY}`;
      let ltXY = `${coordinates.x - rX} ${coordinates.y + rY}`;
      let lbXY = `${coordinates.x - rX} ${coordinates.y - rY}`;
      let rbXY = `${coordinates.x + rX} ${coordinates.y - rY}`;
      let geom = `POLYGON((${rbXY},${rtXY},${ltXY},${lbXY},${rbXY}))`;
      let layers = Object.keys(this.webMapLayersInfo);

      return await this.queryFeaturesOnExtent(geom, coordinates.srs, layers, this.coordinateSystemMap);
    } catch (e) {
      console.error('EEROR query point features [NEXTGIS]', e);
      return [];
    }
  };

  async getExtentFeatureInfo(extentEvt: any): Promise<QueryFeature[]> {
    try {
      let geom = 'POLYGON(';
      let srs = extentEvt.geometry.spatialReference.wkid;
      let layers = Object.keys(this.webMapLayersInfo);

      extentEvt.geometry?.rings.forEach(_coords => {
        geom += `(${_coords.map(_xy => _xy.join(" ")).join(",")})`;
      });

      geom += ')';

      return await this.queryFeaturesOnExtent(geom, srs, layers, this.coordinateSystemMap);
    } catch (e) {
      console.error('EEROR query extent features [NEXTGIS]', e);
      return [];
    }
  };
  getAttributesByID(featureID: string): Promise<any> { return new Promise(() => []); };
  async getFeaturesByQuery(filter: ResourceFilter): Promise<[QueryFeature[], boolean]> {
    let fieldFilters = filter ? filter.filterRecords.filter(_f => _f.enabled).map(_fR => { return [_fR.attrName, _fR.attrValue, _fR.operator] }) : [];
    let allFeatures = [];
    let paging = filter.usePaging ? filter.paging : null;

    for (let _resource in this.webMapLayersInfo) {
      let result = await this.api.filterFeatures(_resource, fieldFilters, paging);
      let qfArr = await this.nextGisFeatureToQueryFeature(result, this.fieldsAlias);

      allFeatures = allFeatures.concat(qfArr);
    }

    return [allFeatures, false]
  };
  async getLayerStatistic(layer: string, filter: ResourceFilter) {
    let response = new LayerStatistic();
    let count = await this.api.getFeaturesCount(this.getREquestLayerByVectorLayer(layer));

    response.featureCount = count;

    return response;
  }

  getREquestLayerByVectorLayer(layer) {
    let result = '';

    for (let _l in this.webMapLayersInfo) {
      if (this.webMapLayersInfo[_l].id == layer) {
        result = _l;
      }
    }

    return result;
  }

  async loadSpatialData(layer?: string): Promise<LayerData[]> {
    const response: LayerData[] = [];
    let layersForLoading = !!layer ? [layer] : null;
    let vectorToExportMap = {};

    if (!layersForLoading) {
      let excludeList = !!this.config.layers ? this.config.layers : [];

      for (let _vectorLayer in this.webMapLayersInfo) {
        let _exportLayerId = this.webMapLayersInfo[_vectorLayer].style_parent_id;

        if (!~excludeList.indexOf(_exportLayerId)) {
          layersForLoading.push(_exportLayerId);
          vectorToExportMap[_vectorLayer] = _exportLayerId;
        }
      }
    } else {
      let result = [];

      for (let _vectorLayer in this.webMapLayersInfo) {
        let parentId = this.webMapLayersInfo[_vectorLayer].layer_style_id;
        let requested = layersForLoading.filter(_c => _c == parentId);

        if (requested.length) {
          let _exportLayerId = this.webMapLayersInfo[_vectorLayer].style_parent_id;

          result.push(_exportLayerId);
          vectorToExportMap[_vectorLayer] = parentId;
        }
      }

      layersForLoading = result;
    }

    for await (const layerId of layersForLoading) {
      let features = await this.api.filterFeatures(layerId, [], null);
      let qfArr = await this.nextGisFeatureToQueryFeature(features, {});
      let newLayerData = new LayerData();

      newLayerData.name = vectorToExportMap[layerId];
      newLayerData.featuresList = qfArr;

      response.push(newLayerData);
    }

    return response;
  };
  async setFilter(filter: ResourceFilter) { };
  saveFeatures(queryFeature): Promise<boolean> { return new Promise(() => []); };

  private async nextGisFeatureToQueryFeature(resultsList: any[], fieldAliases: any = null, srs = 3857) {
    const features: QueryFeature[] = [];

    for (const result of resultsList) {
      const newQueryFeature = new QueryFeature(this.config, this.capabilities, this.locale);

      if (result) {
        let featureData = (!result.geom) ? await this.api.getFeatureInfo('' + result.layerId, '' + result.id, 'geojson') : result;
        let fields = newQueryFeature.translateAttributes(featureData.fields, fieldAliases);
        let geometry = terraformerAG.convert(featureData.geom);


        geometry.spatialReference = { wkid: srs }
        newQueryFeature.addAtributes(this.config.title, fields, this.config.fieldID);
        newQueryFeature.geometry = geometry;
        newQueryFeature.setGeometry(geometry);
        newQueryFeature.arcgisFeature = { geometry, attributes: featureData.fields }
      }

      features.push(newQueryFeature);
    }

    return features;
  }

}
