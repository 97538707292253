import { Injectable, Injector, InjectionToken } from "@angular/core";
import { GenericService } from "@domain/data/service/generic-service";
import { WegaResource } from "@domain/data/resource/wega-resource";
import { ConfigService } from "@shared/config/config-service";
import { REGISTERED_PLUGINS } from "@wega3/wega3.deps";

export type WegaPlugin = {
  apply: (params: any, serviceConfig: ConfigService) => Promise<void>;
  cancel: () => Promise<void>;
};

export type WegaPluginCondition = "service-start" | "control-click";

@Injectable()
export class PluginsProvider {
  constructor(public injector: Injector) {}

  applyPlugin(plugName: string, params: any, serviceConfig: ConfigService) {
    const wegaPlugin = this.injector.get(REGISTERED_PLUGINS)[plugName];

    if (!wegaPlugin) {
      console.error(`'${plugName}' is not registered!`);
      return;
    }

    wegaPlugin.apply(params, serviceConfig);
  }

  cancelPlugin(plugName: string) {
    const wegaPlugin = this.injector.get(REGISTERED_PLUGINS)[plugName];
    wegaPlugin.cancel();
  }

  private _usePlugin(serviceConfig: ConfigService, condition: WegaPluginCondition, action: (plugin: WegaPlugin, config: any) => void): void {
    const plugins = serviceConfig.plugins;

    for (const plugin in plugins) {
      const activatedOn = plugins[plugin].call;

      if (activatedOn.indexOf(condition) !== -1) {
        const wegaPlugin = this.injector.get(REGISTERED_PLUGINS)[plugin];
        const config = serviceConfig.plugins[plugin];

        action(wegaPlugin, config);
      }
    }
  }

  applyCondition(serviceConfig: ConfigService, condition: WegaPluginCondition) {
    this._usePlugin(serviceConfig, condition, (plg, cnf) => plg.apply(serviceConfig, cnf));
  }

  cancelCondition(serviceConfig: ConfigService, condition: WegaPluginCondition) {
    this._usePlugin(serviceConfig, condition, (plg) => plg.cancel());
  }

  applyConditionalPlugins(resource: WegaResource, condition: WegaPluginCondition) {
    resource?.serviceModuleList.forEach((service) => {
      this.applyCondition(service.getConfig(), condition);
    });
  }

  cancelConditionalPlugins(resource: WegaResource, condition: WegaPluginCondition) {
    resource?.serviceModuleList.forEach((service) => {
      this.cancelCondition(service.getConfig(), condition);
    });
  }

  isPluginRegistered(serviceConfig: ConfigService, pluginName: string) {
    return !!serviceConfig.plugins[pluginName];
  }
}
