export enum ServiceState {
  new = "new",
  loading = "loading",
  ready = "ready",
  error = "error"
}

export enum ServiceType {
  wms = "wms",
  wmts = "wmts",
  wfs = "wfs",
  xyz = "xyz",
  arcgis = "arcgis",
  arcgistiled = "arcgistiled",
  arcgisfeature = "arcgisfeature",
  nextgiswebmap = 'nextgiswebmap',
  rest = "rest",
  url = "url",
  vector = "vector",
  geojson = "geojson",
  kml = "kml"
}

export enum NodeIcon {
  Map,
  Image,
  Link,
  Doc,
  Folder,
  Plain,

  Problem
}

export enum RESOURCE_TYPE {
  WMS,
  WFS,
  WMTS,
  ARCGIS_MAPSERVICE
}

export enum WEGA_PANEL {
  CATALOG = "catalog",
  RESOURCES = "resources",
  INFO = "info",
  AREA = "area"
}

export enum MAP_MODE {
  PAN = "pan",
  SELECT = "select",
  ZOOM_IN = "zoom_in",
  ZOOM_OUT = "zoom_out",

  MEASURE = "measure"
}

export enum MAP_SELECTON_MODE {
  SELECT_POINT = "select_point",
  SELECT_EXTENT = "select_extent",
  SELECT_AREA = "select_area"
}

export enum MAP_MEASURE_MODE {
  MEASURE_LENGTH = "measure_length",
  MEASURE_AREA = "measure_area"
}

export enum GEOMETRY_OPERATION {
  INTERSECT = "intersect",
  DIFFERENCE = "difference",
  CLIP = "clip",
  CUT = "CUT"
}

export enum eSpatialRelationship {
  SPATIAL_REL_CONTAINS = "SPATIAL_REL_CONTAINS",
  SPATIAL_REL_CROSSES = "SPATIAL_REL_CROSSES",
  SPATIAL_REL_ENVELOPEINTERSECTS = "SPATIAL_REL_ENVELOPEINTERSECTS",
  SPATIAL_REL_INDEXINTERSECTS = "SPATIAL_REL_INDEXINTERSECTS",
  SPATIAL_REL_INTERSECTS = "SPATIAL_REL_INTERSECTS",
  SPATIAL_REL_OVERLAPS = "SPATIAL_REL_OVERLAPS",
  SPATIAL_REL_RELATION = "SPATIAL_REL_RELATION",
  SPATIAL_REL_TOUCHES = "SPATIAL_REL_TOUCHES",
  SPATIAL_REL_WITHIN = "SPATIAL_REL_WITHIN"
}
