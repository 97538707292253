import { LegendRecord } from "./legend-record";

export class LegendRender {
  public renderType: string; /// тип рендера - по одному значению.
  public recordsList: LegendRecord[] = [];
  public fieldName: string;

  addRecord(newLegendRecord: LegendRecord) {
    this.recordsList.push(newLegendRecord);
  }
}
