import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MapService } from "@domain/data/resource/map-service";
import { LegendDescription } from "@domain/data/legend/legend-description";
import { FieldsInfo } from "@domain/data/feature/fields-info";
import { MapClickPoint } from "@domain/data/structures/map-click-point";
import { QueryFeature } from "@domain/data/feature/query-feature";
import { ResourceFilter } from "@domain/data/filter/resource-filter";
import { LayerData } from "@domain/data/structures/layer-data";
import { ConfigService } from "@shared/config/config-service";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { EsriProvider } from "@wega-providers/esri.provider";
import { ServiceState } from "@shared/wega-utils/wega-enums";
import { LayerStatistic } from "@domain/data/structures/layerStatistic";

export class XyzService implements MapService {
  state: ServiceState;
  canUseFilter: boolean = false;

  constructor(private config: ConfigService, public webClient: WebClientProvider, public esri: EsriProvider, public appConfig: AppConfigProvider) { }

  layer: any;

  async getLayer(): Promise<any> {
    const [WebTiledLayer, Extent, SpatialReference] = await this.esri.loadModules([
      "esri/layers/WebTiledLayer",
      "esri/geometry/Extent",
      "esri/SpatialReference",
    ]);

    this.layer = new WebTiledLayer(this.config.url, {});

    return this.layer;
  }

  async getLayerStatistic(layer: string, filter: ResourceFilter) {
    return new LayerStatistic();
  }

  loadLayerInfo(): Promise<FieldsInfo> {
    return null;
  }

  getLegend(): LegendDescription[] {
    return [];
  }

  getPointFeatureInfo(pCoordinates: MapClickPoint): Promise<QueryFeature[]> {
    return null;
  }

  getExtentFeatureInfo(pExtentEvt: any): Promise<QueryFeature[]> {
    return null;
  }

  getAttributesByID(featureID: string): Promise<any> {
    return null;
  }

  getFeaturesByQuery(pFilter: ResourceFilter): Promise<[QueryFeature[], boolean]> {
    return null;
  }

  loadSpatialData(): Promise<LayerData[]> {
    return null;
  }

  setFilter(pFilter: ResourceFilter) { }

  saveFeatures(QueryFeature: any): Promise<boolean> {
    return null;
  }
}
