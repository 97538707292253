export class GeoExtent {
  public srs: string;
  public xmin: number;
  public ymin: number;
  public xmax: number;
  public ymax: number;

  constructor(srs = "4326", minx = null, miny = null, maxx = null, maxy = null) {
    this.srs = srs;
    this.xmin = +minx;
    this.ymin = +miny;
    this.xmax = +maxx;
    this.ymax = +maxy;
  }

  public static areEqual(someExtent: GeoExtent, anotherExtent: GeoExtent): boolean {
    return (
      someExtent.srs === anotherExtent.srs &&
      someExtent.xmin === anotherExtent.xmin &&
      someExtent.ymin === anotherExtent.ymin &&
      someExtent.xmax === anotherExtent.xmax &&
      someExtent.ymax === anotherExtent.ymax
    );
  }

  extend(newExtent: GeoExtent) {
    if (!newExtent) {
      return null;
    }

    if (this.srs !== newExtent.srs) {
      // return null;
    }

    if (newExtent.xmin < this.xmin || !this.xmin) {
      this.xmin = newExtent.xmin;
    }

    if (newExtent.ymin < this.ymin || !this.ymin) {
      this.ymin = newExtent.ymin;
    }

    if (newExtent.xmax > this.xmax || !this.xmax) {
      this.xmax = newExtent.xmax;
    }

    if (newExtent.ymax > this.ymax || !this.ymax) {
      this.ymax = newExtent.ymax;
    }
  }

  public getCenter(): number[] {
    const centerX = (this.xmin + this.xmax) / 2;
    const centerY = (this.ymin + this.ymax) / 2;

    return [centerX, centerY];
  }

  public getZoomLevel(): number {
    const browserWidth = 1024;
    const level0resolution = 360 / browserWidth;
    let currentLevelResolution = level0resolution;
    const extentXdistance = this.xmax - this.xmin;
    const currentResolution = extentXdistance / browserWidth;

    /// Идея расчета zoomLevel заключается в том, что на каждом zoomLevel разрешение (метров на пиксель) удваивается.
    /// TODO: нужно переписать через логарифм (мне было лень думать как это делать - типа лог_по2_от level0resolution/currentResolution )
    for (let zoomLevel = 1; zoomLevel < 50; zoomLevel++) {
      if (currentLevelResolution < currentResolution) {
        return zoomLevel;
      }

      currentLevelResolution = currentLevelResolution / 2;
    }

    return null;
  }
}
