import { NgModule } from "@angular/core";
import { AngularStandardModule } from "../standard/angular-standard.module";
import { LocaleProvider } from "./providers/i18n.provider";
import { LocaleValuePipe } from "./pipes/locale-value.pipe";
import { LocaleValueDirective } from "./directives/locale-value.directive";

@NgModule({
  imports: [AngularStandardModule],

  declarations: [LocaleValuePipe, LocaleValueDirective],

  exports: [LocaleValuePipe, LocaleValueDirective],

  providers: [LocaleProvider],
})
export class WegaI18nModule {}
