const sqlToNextGisOperatorsMap = {
  '>': 'gt',
  '<': 'lt',
  '>=': 'ge',
  '<=': 'le',
  '=': 'eq',
  '!=': 'ne',
  '<>': 'ne'
}



export default class nextGisApi {
  baseUrl: string;
  paths = {
    resourceInfo: '/api/resource/',
    identify: '/api/feature_layer/identify',
    renderTile: '/api/component/render/tile',
    featureInfoSub: '/feature/',
    legendInfosub: '/legend_symbols',
    exportSub: '/export',
    featureCountSub: '/feature_count'
  }

  constructor(baseUrl: string = '') {
    this.baseUrl = baseUrl;
  }

  async doRequest(path: string, options = {}) {
    let resp = await fetch(this.baseUrl + path, options);
    let json = await resp.json();

    return json;
  }

  async getResourceInfo(resource: string) {
    let path = this.paths.resourceInfo + resource;

    return await this.doRequest(path);
  }

  async identify(params: any = {}) {
    return await this.doRequest(this.paths.identify, {
      headers: {
        "content-type": "application/json",
      },
      method: 'POST',
      body: JSON.stringify(params)
    });
  }

  async getFeatureInfo(resource: string, feature: string, geomFormat: string = '') {
    let path = this.paths.resourceInfo + resource + this.paths.featureInfoSub + feature;

    if (geomFormat) {
      path += `?geom_format=${geomFormat}`;
    }

    return await this.doRequest(path);
  }

  async getFeaturesCount(resource) {
    let countPath = `${this.paths.resourceInfo}${resource}${this.paths.featureCountSub}`;
    let count: any = null;

    count = await this.doRequest(countPath);

    return count.total_count;
  }

  async filterFeatures(resource: string, filterArr: any, paging: any) {
    let path = `${this.paths.resourceInfo}${resource}${this.paths.featureInfoSub}`;
    let getParams = this.fieldFilterToParams(filterArr);

    if (paging) {
      getParams += `&limit=${paging.size}&offset=${(paging.page - 1) * paging.size}`;
    }

    getParams += '&geom_format=geojson&extensions=description';
    return this.doRequest(path + '?' + getParams, {});
  }

  async getLegendInfo(resource: string) {
    let path = this.paths.resourceInfo + resource + this.paths.legendInfosub;

    return await this.doRequest(path);
  }

  getRenderTileUrl(resource: string) {
    return this.baseUrl + this.paths.renderTile + `?resource=${resource}&nd=204` + '&z=${level}&x=${col}&y=${row}';
  }

  private fieldFilterToParams(filterArr: []): string {
    let params = new URLSearchParams();
    filterArr.forEach(_filter => {
      let _field = _filter[0];
      let _value = _filter[1];
      let _operate = _filter[2];

      params.append(`fld_${_field}__${sqlToNextGisOperatorsMap[_operate]}`, _value);
    });

    return params.toString();
  }

  async export({ resource, fields = [] }) {
    let path = `${this.paths.resourceInfo}${resource}${this.paths.exportSub}`;
    let params = new URLSearchParams();

    params.append('format', 'ESRI Shapefile');
    params.append('encoding', 'UTF-8');
    params.append('fid', 'ngw_id');
    params.append('display_name', 'false');
    params.append('fields', fields.join(','));
    params.append('zipped', 'false');

    return await this.doRequest(path + '?' + params.toString());
  }
}