import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WegaAuthenticationProvider } from './providers/authentication.provider';
import { WegaAuthenticationGuard } from './guards/authentication.guard';
import { WegaLoginComponent } from './components/login.component';
import { WegaLogoutComponent } from './components/logout.component';
import { AuthModule, OidcConfigService, LogLevel, OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { WebClientProvider } from '@shared/providers/web-client.provider';
import { WegaAuthControlComponent } from './components/auth-control.component';

export const configureAuth = async (
  oidcConfig: OidcConfigService,
  oidcSecurity: OidcSecurityService,
  webClient: WebClientProvider) => {
  const authConfigJson = await webClient.httpGet('assets/auth/client.config.json');
  const keyStsServer = 'stsServer';
  const keyClientId = 'clientId';
  const keyResponseType = 'responseType';
  const keyScope = 'scope';

  oidcConfig.withConfig({
    stsServer: authConfigJson[keyStsServer],
    clientId: authConfigJson[keyClientId],
    responseType: authConfigJson[keyResponseType],
    scope: authConfigJson[keyScope],

    redirectUrl: window.location.origin,
    postLogoutRedirectUri: window.location.origin,

    silentRenew: false,
    silentRenewUrl: `${window.location.origin}/auth-renew.html`,
    logLevel: LogLevel.Error
  });

  oidcSecurity.checkAuth().subscribe();
};

@NgModule({
  declarations: [
    WegaLoginComponent,
    WegaLogoutComponent,
    WegaAuthControlComponent
  ],

  imports: [
    CommonModule,
    FormsModule,

    AuthModule.forRoot(),

    RouterModule.forChild([
    { path: 'auth-control', component: WegaAuthControlComponent },
    { path: 'auth-login', component: WegaLoginComponent },
    { path: 'auth-logout', component: WegaLogoutComponent }
  ])
  ],

  providers: [
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (oidc: OidcConfigService, security: OidcSecurityService, webClient: WebClientProvider) =>
        () => Promise.all([configureAuth(oidc, security, webClient)]),

      deps: [OidcConfigService, OidcSecurityService, WebClientProvider],
      multi: true,
    },

    WegaAuthenticationGuard,
    WegaAuthenticationProvider
  ],

  exports: [
    WegaLoginComponent,
    WegaLogoutComponent,
    WegaAuthControlComponent
  ]
})

export class WegaAuthenticationModule {
  constructor() {

  }
}
