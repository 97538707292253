import { Component, Inject, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { StorageProvider } from "@wega-providers/storage.provider";
import { UiProvider } from "@wega3/providers/ui.provider";
import { WegaAuthenticationProvider } from "src/app/modules/authentication/providers/authentication.provider";
import { UtilsProvider } from "@shared/providers/utils.provider";
import { environment } from "src/environments/environment";
import { ProfileStateComponent } from "./profile-state.component";
import { WegaUtils } from "@shared/wega-utils/wega-utils";

@Component({
  selector: "wega-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  public static loginOriginKey = "wega-login-origin";

  constructor(
    public storage: StorageProvider,
    public ui: UiProvider,
    public utils: UtilsProvider,
    public auth: WegaAuthenticationProvider,
    public dialogRef: MatDialogRef<ProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  get canLogin() {
    return !environment.production || this.utils.origin.isSafe;
  }

  @ViewChild(ProfileStateComponent) profileState: ProfileStateComponent;

  userId = WegaUtils.createGuid();
  ngOnInit(): void {}

  cancel(): void {
    this.dialogRef.close();
  }

  forceHttps() {
    this.utils.browser.window.location.replace(this.utils.origin.safeOrigin);
  }

  onLogin() {
    this.storage.write(ProfileComponent.loginOriginKey, window.location.pathname.split("/").pop());
    this.auth.login();
  }

  onLogout() {
    this.storage.write(ProfileComponent.loginOriginKey, window.location.pathname.split("/").pop());
    this.auth.logout();
  }
}
