import { Injectable } from "@angular/core";
import { ConfigService } from "@shared/config/config-service";
import { EsriProvider } from "./esri.provider";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { LegendRender } from "@domain/data/legend/legend-render";
import { ArcGisMapProvider } from "../../wega-ui/components/arcgis-map/providers/arcgis-map.provider";
import { environment } from "src/environments/environment";
import { FilterSpatial } from "@domain/data/filter/filter-spatial";

@Injectable()
export class LegendProvider {
  constructor(private esri: EsriProvider, private arcgis: ArcGisMapProvider, private web: WebClientProvider) {}

  async filterCurrentLegendByExtent(
    config: ConfigService,
    layer: string,
    rendersList: LegendRender[],
    renderer: any
  ): Promise<"none" | "inproc" | "done" | "failed"> {
    try {
      const records = rendersList.map((r) => r.recordsList).reduce((a, b) => a.concat(b));
      const [Extent] = await this.esri.loadModules(["esri/geometry/Extent"]);
      const map = this.arcgis.EsriMap;
      const geometry = new Extent({
        xmin: map.extent.xmin,
        ymin: map.extent.ymin,
        xmax: map.extent.xmax,
        ymax: map.extent.ymax,
        spatialReference: { wkid: map.extent.spatialReference.wkid },
      });

      const fields = [renderer.field1, renderer.field2, renderer.field3].filter((f) => f);

      /// сначала попробовать извлечь только уникальные записи (returnDistinctValues)
      let features = await this.executeSpatialLegendQuery(config, layer, renderer, geometry, fields, true);
      if (!features) {
        /// это получается не всегда, в этом случае извлекать все записи
        features = await this.executeSpatialLegendQuery(config, layer, renderer, geometry, fields, false);
      }

      return await this.filterLegendElements(features, records, renderer, fields);
    } catch (e) {
      return "failed";
    }
  }

  async filterCurrentLegendBySpatialFilters(
    config: ConfigService,
    layer: string,
    spatialFilters: FilterSpatial[],
    rendersList: LegendRender[],
    renderer: any
  ): Promise<"none" | "inproc" | "done" | "failed"> {
    try {
      const [Polygon] = await this.esri.loadModules(["esri/geometry/Polygon"]);

      const records = rendersList.map((r) => r.recordsList).reduce((a, b) => a.concat(b));
      const fields = [renderer.field1, renderer.field2, renderer.field3].filter((f) => f);
      let features = [];

      for await (const spatialFilter of spatialFilters) {
        const geometry = new Polygon(spatialFilter.geometry);
        let filteredFeatures = await this.executeSpatialLegendQuery(config, layer, renderer, geometry, fields, true);
        if (!filteredFeatures) {
          filteredFeatures = await this.executeSpatialLegendQuery(config, layer, renderer, geometry, fields, false);
        }

        features = features.concat(filteredFeatures);
      }

      return await this.filterLegendElements(features, records, renderer, fields);
    } catch (e) {
      return "failed";
    }
  }

  async filterLegendElements(features: any[], records: any[], renderer: any, fields: string[]): Promise<"none" | "inproc" | "done" | "failed"> {
    if (!features) {
      !environment.production && console.log("Не удалось выполнить извлечение элементов легенды (generic_error)");
      throw new Error("Не удалось выполнить извлечение элементов легенды (generic_error)");
    }

    if (renderer.type == "uniqueValue") {
      const delimiter = renderer.fieldDelimiter;
      let extentLegendValues = [];

      features.forEach((feature) => {
        const label = fields.map((fld) => feature.attributes[fld]).join(`${delimiter}`);
        extentLegendValues.push(label);
      });

      const extentLegendLabels = extentLegendValues.map((v) => {
        const foundLabel = renderer.uniqueValueInfos.find((uv) => uv.value == v)?.label;
        return foundLabel || renderer.defaultLabel;
      });

      records.forEach((record) => {
        record.hidden = extentLegendLabels.indexOf(record.label) == -1;
      });
    } else if (renderer.type == "simple") {
      records.forEach((record) => {
        record.hidden = features.length === 0;
      });
    } else {
      !environment.production && console.log("Не удалось выполнить извлечение элементов легенды для текущего экстента (renderer_not_impl)");
      throw new Error("Не удалось выполнить извлечение элементов легенды для текущего экстента (renderer_not_impl)");
    }

    return "done";
  }

  async executeSpatialLegendQuery(config: ConfigService, layer: string, renderer: any, geometry: any, fields: string[], returnDistinctValues: boolean) {
    try {
      const [Query, QueryTask] = await this.esri.loadModules(["esri/tasks/query", "esri/tasks/QueryTask"]);
      const query = new Query();

      query.where = "1=1";
      query.outSpatialReference = { wkid: 3857 };
      query.geometry = geometry;
      query.spatialRelationship = "esriSpatialRelIntersects";
      query.returnDistinctValues = returnDistinctValues;
      query.outFields = fields;

      const queryTask = new QueryTask(`${config.url}/${layer}/`);
      const { features } = await queryTask.execute(query);

      return features;
    } catch (e) {
      return null;
    }
  }

  extractAttributesFromRenderer(
    renderer: any
  ): { type: "uniqueValue"; values: Array<{ attribute: string; value: string }> } | { type: "classBreaks"; attibute: string; min: string; max: string } {
    throw new Error("Method not implemented.");
  }
}
