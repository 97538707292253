import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { FilterItem, AreaItem, AreaSource } from "../utils/area-filter.utils";
import { UtilsProvider } from "@shared/providers/utils.provider";

@Injectable()
export class FilterProvider {
  constructor(private appConfig: AppConfigProvider, private web: WebClientProvider, private utils: UtilsProvider) {
    utils.setFrameworkModule("Filter", {});
  }
}
