import { WegaCatalog } from "./wega-catalog";
import { CatalogNode } from "@wega3/components/catalog-tree/catalog-node";
import { WegaUtils } from "@shared/wega-utils/wega-utils";
import { WegaCompareFilter, WegaDefaultCompareFilter } from "src/app/modules/search-engines/common/search.engine.utils";

export interface WegaSearchScanner {
  search(catalog: WegaCatalog, text: string, comparator?: WegaCompareFilter): Promise<CatalogNode[]>;
}

export class DefaultSearchScanner implements WegaSearchScanner {
  async search(catalog: WegaCatalog, text: string, comparator: WegaCompareFilter = new WegaDefaultCompareFilter(text)): Promise<CatalogNode[]> {
    const txt = text.toLowerCase();
    const rootNodes = await catalog.getEntries();
    const found = <Array<CatalogNode>>[];
    const processed = <Array<CatalogNode>>[];

    for (const node of rootNodes) {
      await this.recursiveSearch(catalog, node, txt, found, processed, comparator);
    }

    return Promise.resolve(this.unique(found));
  }

  async recursiveSearch(
    catalog: WegaCatalog,
    node: CatalogNode,
    txt: string,
    found: Array<CatalogNode>,
    processed: Array<CatalogNode>,
    comparator: WegaCompareFilter
  ): Promise<void> {
    /// при ленивой подгрузке дерева одни и те же узлы могут появляться несколько раз
    /// поэтому нужно проверять, не выполнялся ли уже поиск в данном узле
    /// если выполнялся, то нет смысла продолжать в нем поиск
    if (processed.find((n) => this.equal(n, node))) {
      return Promise.resolve();
    }

    /// запомнить, что узел уже обыскивался
    processed.push(node);

    if (!node.expandable) {
      /// если узел не содержит дочерних узлов,
      /// проверить его предикатом поиска и добавить в найденные, если есть положительный результат
      if (comparator.scan({ sample: node.title.toLowerCase(), _source: node })) {
        found.push(node);
      }
    } else {
      /// если у узла есть дочки, то выполнить рекурсивный поиск по ним
      const childNodes = await catalog.getChildEntries(node);

      for (const child of childNodes) {
        await this.recursiveSearch(catalog, child, txt, found, processed, comparator);
      }
    }

    /// если узел содержит модуль подкаталога, то его также нужно рекурсивно сканировать
    node.isModuleNode && ((await node.catalog.searchScanner?.search(node.catalog, txt, comparator)) ?? []).forEach((_node) => found.push(_node));
  }

  equal(first: CatalogNode, second: CatalogNode): boolean {
    return first.id === second.id;
  }

  unique(nodes: CatalogNode[]): CatalogNode[] {
    /// несовпадения по id недостаточно (см. метод equal), чтобы гарантировать, что в результатах не окажется двух одинаковых ресурсов
    /// часто в конфиге ресурса не задан id, в этом случае id ресурса генерируется автоматически
    /// а при поиске по каталогу типа FileCatalog почему-то один и тот же ресурс может встретиться несколько (2?) раз
    /// и тогда один и тот же ресурс получает разные id, что приводит к ошибке [#4707]
    /// поэтому нужно дополнительная проверка найденных ресурсов на совпадение исходных конфигов сервисов ресурса
    /// здесь предполагается, что если url и layers сервисов ресурса совпадают, то ресурсы "равны"

    const filtered: Array<{ hash: string; node: CatalogNode }> = [];

    nodes.forEach((node) => {
      const hash = node.resource?.servicesList.map((service) => service.url + service.layers?.join("")).reduce((p, c) => p + c) || WegaUtils.createGuid();

      filtered.find((item) => item.hash === hash) || filtered.push({ hash, node });
    });

    return filtered.map((item) => item.node);
  }
}
