import { QueryFeature } from "../feature/query-feature";

export class LayerData {
  name: any;
  featuresList: QueryFeature[] = [];

  addArcGISFeatures(featuresList: QueryFeature[]) {
    for (const feature of featuresList) {
      this.featuresList.push(feature);
    }
  }
}
