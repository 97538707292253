import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({ name: "searchHighlight" })
export class SearchHighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, sought: string[], color: string): SafeHtml {
    let newValue = value.toString();

    sought.forEach((highlightee) => {
      const regex = new RegExp(highlightee, "gi");
      const found = regex.exec(value);
      newValue = found ? newValue.replace(regex, `<span style="background: ${color};">${found[0]}</span>`) : newValue;
    });

    return this.sanitizer.bypassSecurityTrustHtml(newValue);
  }
}
