import { Component, Input, ChangeDetectionStrategy } from "@angular/core";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      .wega-attribute-search-details-container {
        font-size: 12px;
      }
    `,
  ],
  selector: "wega-attribute-search-details",
  template: `
    <div class="wega-attribute-search-details-container" *ngFor="let attr of input.attrs; let index = index">
      <span [locale]="{ innerHTML: { en: 'Match (' } }">Совпадение (</span>{{ index + 1 }} <span [locale]="{ innerHTML: { en: ' of ' } }"> из </span
      >{{ input.attrs.length }}<span [locale]="{ innerHTML: { en: '): attribute ' } }">): атрибут </span> '{{ attr.key }}'
      <span [locale]="{ innerHTML: { en: ', value ' } }">, значение </span>
      '<span [innerHtml]="attr.value | searchHighlight : [input.searchKey] : 'yellow'"> </span>'.
    </div>
  `,
})
export class AttributeDataDetailsComponent {
  constructor() {}

  @Input() input: {
    attrs: Array<{ key: string; value: string }>;
    searchKey: string;
  };
}
