import { WegaResource } from "@domain/data/resource/wega-resource";
import { ResourceProvider } from "../providers/resource.provider";
import { ConfigResource } from "@shared/config/config-resource";
import { ResourceFilter } from "@domain/data/filter/resource-filter";
import { UtilsProvider } from "@shared/providers/utils.provider";
import { RasterDBCatalog } from "@domain/data/catalogs/rasterdb-catalog";

export class ExternalSitesAdapter {
  constructor(private resourceProvider: ResourceProvider, private utils: UtilsProvider) {
    if (window.addEventListener) {
      window.addEventListener("message", this.handleMessage.bind(this), false);
    } else {
      (<any>window).attachEvent("onmessage", this.handleMessage.bind(this));
    }
  }

  async handleMessage(event: Event) {
    const message = event as MessageEvent;
    // console.log(event);

    var eventType = message.data.type;

    if (eventType == "corrTable") {
      this.ExecuteCorrtableCommand(message.data);
    }

    if (eventType == "selectWegaMap") {
      this.ExecuteSelectByFilterWegaMap(message.data);
    }

    if (eventType == "filterWegaMap") {
      this.ExecuteFilterWegaMap(message.data);
    }

    if (eventType == "AddWegaMap") {
      await this.ExecuteAddWegaMap(message.data);
    }
  }

  async ExecuteAddWegaMap(data: any) {
    var resourceConfig = data.resource;
    var newResourceConfig = new ConfigResource(resourceConfig);
    await this.resourceProvider.addResourceConfig(newResourceConfig);
  }

  async ExecuteFilterWegaMap(data: any) {
    this.utils.reportFeatureUse("ext-adapter-filter");
    //var command = <string>data.command;
    var mapId = <string>data.mapId;
    var filtersList = <any[]>data.filter;
    var map = await this.FindOrAddMapById(mapId);
    if (!map) {
      this.Log("map: " + mapId + " not found!");
      return; //
    }

    map.filter.reset();
    for (var filterRecord of filtersList) {
      var fieldName = filterRecord.field;
      var condition = filterRecord.condition;
      var value = filterRecord.value;
      map.filter.addConditionStructure(fieldName, condition, value);
      //map.filter.addCondition(filterString);
    }
    map.applyFilter();
  }

  async ExecuteSelectByFilterWegaMap(data: any) {
    this.utils.reportFeatureUse("ext-adapter-selectByFilter");
    //var command = <string>data.command;
    var mapId = <string>data.mapId;
    var filtersList = <any[]>data.filter;
    var map = await this.FindOrAddMapById(mapId);
    if (!map) {
      this.Log("map: " + mapId + " not found!");
      return; //
    }

    var selectFilter = new ResourceFilter(null);
    for (var filterRecord of filtersList) {
      var fieldName = filterRecord.field;
      var condition = filterRecord.condition;
      var value = filterRecord.value;
      selectFilter.addConditionStructure(fieldName, condition, value);
    }

    map.selectByFilter(selectFilter);
    map.searchExpired = false;
  }

  async ExecuteCorrtableCommand(data: any) {
    var objectIdList = (<string[]>data.params).join(", ");

    var map = await this.FindOrAddMapById("24e49e1a-67b0-b057-17ba-5e62659ca508");

    if (map) {
      map.filter.reset();
      //map.filter.addConditionStructure("FORMATION_CODE1", "like", "g");
      map.filter.addConditionStructure("BASEA_OBJECTID", "in", objectIdList);
      map.applyFilter();
    }
  }

  async FindOrAddMapById(layerId: string): Promise<WegaResource> {
    var map = this.FindMapById(layerId);
    if (map) {
      return map;
    }
    var newResource = await this.AddLayerById(layerId);
    return newResource;
  }

  FindMapById(layerId: string): WegaResource {
    var mapsList = this.resourceProvider.getResourceList();
    for (var map of mapsList) {
      if (map.id == layerId) {
        return map;
      }
    }
    return null;
  }

  async AddLayerById(resourceId: string): Promise<WegaResource> {
    var foundNodesList = await this.resourceProvider.database.findById(resourceId, (n) => (n.catalog ? n.catalog instanceof RasterDBCatalog : false));
    if (foundNodesList && foundNodesList.length == 1) {
      var node = foundNodesList[0];
      var newResource = await this.resourceProvider.addResourceConfig(node.resource);
      return newResource;
    }
    return null;
  }

  Log(message: string) {
    console.log(message);
  }
}
