import { SpatialFilterInfo } from "@shared/wega-utils/global-config";

export type AreaFilterType = "reset" | "geojson" | "arcgisgeojson" | "label";

export interface AreaSource {
  key: string;
  items: Array<AreaItem>;
  lazyFetch: { url: string; params: string[]; attribute: string };
  filterSource: Array<SpatialFilterInfo>;
}

export interface AreaItem {
  name: string;
  value: string;
  bounds: {};
  type: AreaFilterType;
  url: string;
  hidden: boolean;
  isDefault: boolean;
  children: Array<AreaItem>;
}

/// конвертируется из AreaItem и используется для построения компонента простр. фильтра
export class FilterItem {
  public id: string;
  public title: string;
  public alias: string;
  public bounds: any;
  public type: AreaFilterType;
  public address: string;

  public parent: string;
  public children?: FilterItem[];
  public active: boolean;
  public default: boolean;

  public source: AreaSource;
  public lazyLoaded: boolean;

  public expanded: boolean;
}

export interface FilterFlatNode {
  expandable: boolean;
  item: FilterItem;
  level: number;
}

export const parseUrlTemplate = (expression: string, values: { [key: string]: string }) => {
  const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g;

  const text = expression.replace(templateMatcher, (substring, value, index) => {
    value = values[value];
    return value;
  });

  return text;
};

export const EngToCyr = {
  q: "й",
  w: "ц",
  e: "у",
  r: "к",
  t: "е",
  y: "н",
  u: "г",
  i: "ш",
  o: "щ",
  p: "з",
  "[": "х",
  "]": "ъ",
  a: "ф",
  s: "ы",
  d: "в",
  f: "а",
  g: "п",
  h: "р",
  j: "о",
  k: "л",
  l: "д",
  ";": "ж",
  "'": "э",
  z: "я",
  x: "ч",
  c: "с",
  v: "м",
  b: "и",
  n: "т",
  m: "ь",
  ",": "б",
  ".": "ю",
  "`": "ё",
};
