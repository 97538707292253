import { Injectable } from "@angular/core";
import { OlProvider } from "./ol.provider";
import { WegaResource } from "@domain/data/resource/wega-resource";
import { ConfigService } from "@shared/config/config-service";
import { WfsReader } from "@domain/data/wxs/wfs-reader";
import { ArcGisService } from "@domain/data/service/arcgis/arcgis.service";
import { GenericService } from "@domain/data/service/generic-service";
import { ServiceType } from "@shared/wega-utils/wega-enums";
import { WegaUtils } from "@shared/wega-utils/wega-utils";
import { NextGisService } from "@domain/data/service/nextgis/nextgis.service";

export enum LAYER_VIEW_MODE {
  NONE,
  LEGEND,
  ATTRIBUTES,
  DATA,
  SEARCH,
}

export type ViewResourceLayer = {
  uid: string;
  name: string;
  title: string;
  readable: boolean;
  isActive: boolean;
  viewMode: LAYER_VIEW_MODE;
  service: GenericService;
  table: any;
  tableReady: boolean;
  mountId: string;
  operated: string;
  subordinates: any[];
};

@Injectable()
export class LayerProvider {
  private layerCache: Array<{
    resource: WegaResource;
    layers: Array<ViewResourceLayer>;
  }> = [];

  constructor(private ol: OlProvider) { }

  async findResourceLayers(resource: WegaResource): Promise<ViewResourceLayer[]> {
    const cached = this.layerCache.find((c) => c.resource === resource);

    if (!cached) {
      const layers = await this.readResourceLayers(resource);
      this.layerCache.push({ resource, layers });

      return layers;
    }

    return cached.layers;
  }

  async readResourceLayers(resource: WegaResource): Promise<ViewResourceLayer[]> {
    let layers: Array<ViewResourceLayer> = [];
    for (const service of resource.serviceModuleList) {
      // TODO: https://eslint.org/docs/rules/no-await-in-loop
      const config = service.getConfig();
      const canBeRead = this.layersCouldBeRead(config);

      config.availableLayers = [];

      if (canBeRead) {
        await this.readServiceLayers(resource, service, config);

        const serviceLayers: Array<ViewResourceLayer> = config.availableLayers.map((sl) => {
          return {
            name: sl.name,
            title: this.readableLayerTitle(service, sl.title),
            viewMode: LAYER_VIEW_MODE.NONE,
            readable: sl.enlisted,
            service,
            isActive: this.isActiveLayer(sl.name, service.getConfig()),
            table: null,
            tableReady: false,
            mountId: "info-table-arcgis-" + WegaUtils.createGuid(),
            operated: null,
            subordinates: sl.children,
            uid: "",
          };
        });

        layers = [...layers, ...serviceLayers];
      } else {
        const nonExistantLayer: ViewResourceLayer = {
          name: "-",
          title: "?",
          viewMode: LAYER_VIEW_MODE.NONE,
          readable: false,
          service,
          isActive: false,
          table: null,
          tableReady: false,
          mountId: "info-table-arcgis-" + WegaUtils.createGuid(),
          operated: null,
          subordinates: [],
          uid: "",
        };

        layers = [...layers, ...[nonExistantLayer]];
      }
    }

    layers.forEach((layer) => {
      layer.subordinates = layer.subordinates.map((sl) => layers.find((l) => l.name == sl));
    });

    layers.forEach((l, i) => {
      l.uid = i.toString();
    });

    return layers;
  }

  async readServiceLayers(resource: WegaResource, serviceModule: GenericService, config: ConfigService): Promise<void> {
    const type = config.type;
    const url = config.url;

    return new Promise(async (resolve) => {
      const noPredefinedLayers = !config.layers || config.layers.length == 0;
      if (type === ServiceType.wms || type === ServiceType.wfs) {
        const layers = type === ServiceType.wms ? await this.ol.readWMSLayers(url, null) : await new WfsReader(url).getLayersList();

        config.availableLayers = config.availableLayers
          .concat(
            layers.map((l, i) => {
              return {
                name: l.name,
                title: l.title || l.name || `Слой ${type === ServiceType.wms ? "WMS" : "WFS"} (${i})`,
                active: true,
                enlisted: true,
                children: [],
              };
            })
          )
          .filter(
            (l) =>
              noPredefinedLayers ||
              config.layers.find((pl) => (resource.config.fuzzyLayersGrouping ? l.name.indexOf(pl.toString()) != -1 : pl.toString() == l.name))
          );

        resolve();
      } else if (type === ServiceType.arcgis || type === ServiceType.arcgisfeature || type === ServiceType.arcgistiled) {
        const layersInfo = (<ArcGisService>serviceModule.getMapService()).getArcGISLayersInfo();

        Object.keys(layersInfo).forEach((lk) => {
          const layerInfo = layersInfo[lk];
          const id = layerInfo.id.toString();
          let includeLayer = false;

          // если в конфиге определены слои, то при нахождении слоя с совпадающим id он должен отображаться в списке
          if (!noPredefinedLayers) {
            if (undefined !== config.layers.find((pl) => pl.toString() == id)) {
              includeLayer = true;
            }
          } else if (layerInfo.subLayers.length === 0) {
            /// если это не так, то нужно проверять, является ли этот слой конечным (бездетным) и раскручивать иерархию до конца
            /// слой-контейнер (содержащий группу слоев) не включается, включаются только конечный (терминальный) слой, не имеющий детей
            includeLayer = true;
          }

          config.availableLayers.push({
            name: layerInfo.id,
            title: this.createLayerTitle(layersInfo, layerInfo, config.layerAliases, resource.config.onlyLastTitles),
            active: true, /// TODO: для не определенных через конфиг слоев нужно дополнительно ресолвить их видимость
            enlisted: includeLayer,
            children: this.retrieveChildren(layersInfo, layerInfo),
          });
        });

        if (noPredefinedLayers) {
          config.layers = config.availableLayers.map((l) => l.name);
        }

        resolve();

        // new ArcGisReader(url).getLayersList(ll => {
        //   ll.forEach(l => {
        //     config.availableLayers.push({name: l.name, title: 'Слой ArcGIS'});
        //   });
        // });
      } else if (type === ServiceType.nextgiswebmap) {
        const layersInfo = (<NextGisService>serviceModule.getMapService()).webMapLayersInfo;

        Object.keys(layersInfo).forEach((lk) => {
          const layerInfo = layersInfo[lk];
          const id = layerInfo.layer_style_id;
          let includeLayer = false;

          // если в конфиге определены слои, то при нахождении слоя с совпадающим id он должен отображаться в списке
          if (!noPredefinedLayers) {
            if (undefined !== config.layers.find((pl) => pl.toString() == id)) {
              includeLayer = true;
            }
          }

          config.availableLayers.push({
            name: id,
            title: layerInfo.display_name,
            active: true, /// TODO: для не определенных через конфиг слоев нужно дополнительно ресолвить их видимость
            enlisted: true,
            children: [id],
          });
        });

        if (noPredefinedLayers) {
          config.layers = config.availableLayers.map((l) => l.name);
        }

        resolve();
      } else {
        resolve();
      }
    });
  }

  retrieveChildren(layersInfo: {}, layerInfo: any) {
    let dataLayers = [];
    if (layerInfo.subLayers.length === 0) {
      dataLayers.push(layerInfo.id);
    } else {
      layerInfo.subLayers.forEach((sl: any) => {
        dataLayers = dataLayers.concat(this.retrieveChildren(layersInfo, layersInfo[sl.id]));
      });
    }

    return dataLayers;
  }

  createLayerTitle(layersInfo: any, layerInfo: any, aliases: any[], onlyLast: boolean) {
    const aliasInfo = aliases.find((a) => a.name == layerInfo.id);
    if (aliasInfo) {
      return aliasInfo.alias;
    }

    const fnTitle = (li) => li.description || li.name || `Слой ArcGIS (${this.defineLayerType(li)})`;

    const titleStack = [fnTitle(layerInfo)];
    let parent = layerInfo.parentLayer;

    while (parent) {
      const parentLayer = layersInfo[parent.id];
      titleStack.push(fnTitle(parentLayer));

      parent = parentLayer.parentLayer;
    }

    return onlyLast ? titleStack.reverse().pop() : titleStack.reverse().join("; ");
  }

  defineLayerType(agLayer: any): string {
    switch (agLayer.geometryType) {
      case "esriGeometryPoint": {
        return "точечный";
      }

      case "esriGeometryPolygon": {
        return "полигональный";
      }

      case "esriGeometryLine": {
        return "линейный";
      }

      default: {
        return "тип не определен";
      }
    }
  }

  layersCouldBeRead(service: ConfigService) {
    // service.availableLayers.length === 0 && (service.type === ServiceType.wms || service.type === ServiceType.wfs ||
    // service.type === ServiceType.arcgis || service.type === ServiceType.arcgisfeature || service.type === ServiceType.arcgistiled);

    return true;
  }

  readableLayerTitle(service: GenericService, layerName: string) {
    let targetLayerName = layerName;

    if (service.getConfig().type.toString() === ServiceType.arcgis.toString()) {
      const layersInfo = (service.getMapService() as ArcGisService).getArcGISLayersInfo();

      Object.keys(layersInfo).forEach((lk) => {
        const layer = layersInfo[lk];

        if (layer.id === layerName) {
          targetLayerName = layer.name;
        }
      });
    }

    return targetLayerName;
  }

  isActiveLayer(layerName: string, service: ConfigService) {
    if (!service.startLayers) {
      return true;
    }

    return -1 != service.startLayers.indexOf(layerName);
  }

  extractNonGroupLayers(layerInfo: any[], layerIds: string[]) {
    const extractedLayerIds = [];

    const extract = (l, output) => {
      const subLayers = layerInfo.find((lr) => lr.id == l).subLayers;
      if (subLayers.length == 0) {
        output.push(l);
      } else {
        subLayers.forEach((subLayer) => {
          extract(subLayer.id, output);
        });
      }
    };

    layerIds.forEach((l) => extract(l, extractedLayerIds));
    return extractedLayerIds;
  }
}
