import { UiProvider } from "@wega3/providers/ui.provider";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { CatalogNode } from "@wega3/components/catalog-tree/catalog-node";
import { FilterSpatial } from "../filter/filter-spatial";
import { ConfigResource } from "@shared/config/config-resource";
import { ConfigService } from "@shared/config/config-service";
import { GeoExtent } from "../structures/extent";
import { WegaCatalog } from "./wega-catalog";
import { WegaSearchScanner } from "./wega-search";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";
import { NodeIcon, ServiceType } from "@shared/wega-utils/wega-enums";

export class RasterDBCatalog implements WegaCatalog {
  constructor(title: string, baseRasterDBUrl: string, public appConfig: AppConfigProvider, private locale: LocaleProvider) {
    this.title = title;
    // конструкция "&?" нужна чтобы базовый URL мог включать другие параметры, напр: "/tree.php?preset=xxx&"
    this.baseUrl = baseRasterDBUrl + "?&node=";
  }

  // отладочный вариант
  // TODO: возможно, стоит вынести в общие параметры интерфейса каталога
  readonly PAGING_ENABLED: boolean = false;
  readonly NODE_LIMIT: number = 50;

  childrenJsonCache: {
    [key: string]: {
      currentPage: number;
      totalPagesCount: number;
      nodes: any[];
    };
  } = {};

  title: string;
  baseUrl: string;

  _nodes: CatalogNode[] = [];

  searchScanner: WegaSearchScanner = null;

  nodeLimit = -1;

  async getEntries(): Promise<CatalogNode[]> {
    this._nodes = await this.getChildrenFor(null);

    return this._nodes;
  }

  async getChildEntries(pNode: CatalogNode): Promise<CatalogNode[]> {
    this._nodes = await this.getChildrenFor(pNode);

    return this._nodes;
  }

  resetFilter() {
    throw new Error("Method not implemented.");
  }

  addTextFilter(pSearchText: string) {
    throw new Error("Method not implemented.");
  }

  addAttributeFilter(pAttribute: string, pValue: string) {
    throw new Error("Method not implemented.");
  }

  setSpatialFilter(pSpatFilter: FilterSpatial) {
    throw new Error("Method not implemented.");
  }

  getNodeLimit() {
    // TODO: вообще говоря, это значение следует заблаговременно вычислять через (например) UiService
    // пока пусть будет грубо и некрасиво
    // 40 - приблизительная высота контейнера единичного узла дерева (в пикселях)
    if (this.nodeLimit === -1) {
      this.nodeLimit = Math.ceil(window.innerHeight / 40);
    }

    return this.nodeLimit;
  }

  async getChildrenFor(node: CatalogNode): Promise<CatalogNode[]> {
    const nodeKey = !!node ? node.id : "";
    const nodeLimit = this.getNodeLimit();

    if (!this.childrenJsonCache[nodeKey]) {
      const url = this.baseUrl + nodeKey;
      const rasterdbAnswer = await fetch(url);

      if (!rasterdbAnswer.ok) {
        return [];
      }

      const resJson = await rasterdbAnswer.json();
      this.childrenJsonCache[nodeKey] = {
        currentPage: 0,
        totalPagesCount: this.PAGING_ENABLED ? Math.ceil(resJson.length / nodeLimit) : 1,
        nodes: resJson,
      };
    }

    const nodeCache = this.childrenJsonCache[nodeKey];
    const jsonNodesList = nodeCache.nodes;
    const nodeList: CatalogNode[] = [];

    const start = this.PAGING_ENABLED ? nodeLimit * nodeCache.currentPage : 0;
    const end = this.PAGING_ENABLED ? Math.min(nodeLimit * (nodeCache.currentPage + 1), jsonNodesList.length) : jsonNodesList.length;

    for (let index = start; index < end; index++) {
      const jsNode = jsonNodesList[index];
      const isLastNode = this.PAGING_ENABLED && jsonNodesList.length > end - start && index == end - 1;

      const newNode = this.getNodeFromJson(jsNode);

      if (isLastNode) {
        newNode.isLastOnPage = true;
        newNode.parent = node;
        newNode.total = nodeCache.totalPagesCount;
        newNode.current = nodeCache.currentPage + 1;

        if (0 === start) {
          // последний узел на первой странице
          newNode.isOnFirstPage = true;
        }

        if (end === jsonNodesList.length) {
          // последний узел на последней странице
          newNode.isOnLastPage = true;
        }
      }

      nodeList.push(newNode);
    }

    return nodeList;
  }

  async togglePage(goNextPage: boolean, node: CatalogNode): Promise<boolean> {
    const key = node.id;
    const cache = this.childrenJsonCache[key];

    let isValidPageChange = false;

    if (goNextPage && cache.currentPage < cache.totalPagesCount - 1) {
      cache.currentPage++;
      isValidPageChange = true;
    } else if (!goNextPage && cache.currentPage > 0) {
      cache.currentPage--;
      isValidPageChange = true;
    }

    if (isValidPageChange) {
      this._nodes = await this.getChildrenFor(node);
    }

    return isValidPageChange;
  }

  getNodeFromJson(jsNode: any): CatalogNode {
    var newNode = new CatalogNode(this, this.appConfig, this.locale);

    newNode.id = jsNode["key"];
    newNode.title = jsNode["title"];
    newNode.expandable = false;

    var resourceConfig = new ConfigResource("");

    resourceConfig.title = newNode.title;
    resourceConfig.id = newNode.id;
    newNode.resource = resourceConfig;
    newNode.resource.origin = "База данных Государственных геологических карт (ГИС-Атлас «Недра России»)";
    newNode.expandable = jsNode["lazy"] == true;

    /// TODO: тут нужен рефакторинг. вынести в функцию GetServiceByType();
    switch (jsNode["type"]) {
      case "folder":
        newNode.expandable = true;
        newNode.resource = null;
        newNode.setIcon(NodeIcon.Folder);

        break;

      case "zaramka":
        var cfgService = new ConfigService({});
        cfgService.type = ServiceType.url;
        cfgService.url = jsNode["URL"];

        cfgService.visible = true;

        newNode.resource.servicesList.push(cfgService);
        newNode.setIcon(NodeIcon.Image);

        break;

      case "doc":
        var cfgService = new ConfigService({});
        cfgService.type = ServiceType.url;
        cfgService.url = jsNode["URL"];
        cfgService.visible = true;

        newNode.resource.servicesList.push(cfgService);
        newNode.setIcon(NodeIcon.Doc);

        break;

      case "ref":
        var cfgUrlService = new ConfigService({});
        cfgUrlService.type = ServiceType.url;
        cfgUrlService.url = jsNode["URL"];
        cfgUrlService.visible = true;

        newNode.resource.servicesList.push(cfgUrlService);
        newNode.setIcon(NodeIcon.Link);

        break;

      case "raster":
        var cfgService = new ConfigService({});
        cfgService.type = ServiceType.wms;
        cfgService.url = jsNode["WMS_URL"];

        if (this.appConfig.Environment.TransformHttpToHttps && cfgService.url.startsWith("http:")) {
          cfgService.url = cfgService.url.replace("http:", "https:");
        }

        cfgService.layers = [jsNode["WMS_LAYER"]];
        cfgService.layersListEnabled = false;
        cfgService.visible = true;
        cfgService.responseFormat = "text/html";
        // console.log(jsNode);
        var mbrCoords = jsNode["MBR"].split(",");
        cfgService.extent = new GeoExtent("epsg:4326", mbrCoords[0], mbrCoords[1], mbrCoords[2], mbrCoords[3]);

        newNode.resource.servicesList.push(cfgService);
        newNode.setIcon(NodeIcon.Map);

        break;

      default:
        break;
    }

    return newNode;
  }
}
