import { WegaCatalog } from "@domain/data/catalogs/wega-catalog";
import { CatalogNode } from "@wega3/components/catalog-tree/catalog-node";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";
import { WegaSearchScanner } from "@domain/data/catalogs/wega-search";
import { FilterSpatial } from "@domain/data/filter/filter-spatial";
import { DomSanitizer } from "@angular/platform-browser";
import { WegaUtils } from "@shared/wega-utils/wega-utils";

export class TemplateCatalog implements WegaCatalog {
  title: string;
  url: string;

  private rootElements: CatalogNode[] = [];

  constructor(
    title: string,
    public appConfig: AppConfigProvider,
    public web: WebClientProvider,
    public locale: LocaleProvider,
    private sanitizer: DomSanitizer,
    url: string
  ) {
    this.url = url;
    this.title = title;

    this.getEntries();
  }

  searchScanner: WegaSearchScanner = null;
  list: [] = null;
  props: {} = null;

  async getEntries(): Promise<CatalogNode[]> {
    if (!this.list || !this.props) {
      const { props, maps } = await (await fetch(this.url)).json();

      this.list = maps;
      this.props = props;
    }

    if (!this.rootElements.length) {
      const titleSrc = this.props["path"];
      const parser = new DOMParser();

      this.list.forEach((item) => {
        const title = <string>this.traverseObjectByPath(item, titleSrc);
        const node = new CatalogNode(this, this.appConfig, this.locale);

        node.id = WegaUtils.createGuid();
        node.title = title;
        node.expandable = false;

        node.exposeData = () => {
          const htmlString = this.props["data"]
            .map((property) => {
              const propertyValue = this.traverseObjectByPath(item, property.path);
              let url = property.urlPath ? this.traverseObjectByPath(item, property.urlPath) : null;

              return `<div style="margin-bottom: 5px;">
              <span style="font-weight: bold; display: block;">${property.title}:</span>
              ${url == null ? `<span>${propertyValue}</span>` : `<a target="_blank" href="${this.props["baseUrl"]}${url}">${propertyValue}</a>`}
            </div>`;
            })
            .join("");

          const parsedHTML = parser.parseFromString(`<div style="width: 355px;">${htmlString}</div>`, "text/html");
          const node = parsedHTML.body.firstChild;
          document.body.appendChild(node);

          const clientHeight = (node as HTMLElement).clientHeight;
          document.body.removeChild(node);

          return [this.sanitizer.bypassSecurityTrustHtml(htmlString), clientHeight];
        };

        this.rootElements.push(node);
      });
    }

    return this.rootElements;
  }

  traverseObjectByPath(obj: any, path: string[]) {
    let currentObj = obj;

    for (const key of path) {
      if (currentObj[key]) {
        currentObj = currentObj[key];
      } else {
        console.log(`Свойство '${key}' не найдено`);
        return null;
      }
    }

    return currentObj;
  }

  getChildEntries(node: CatalogNode): Promise<CatalogNode[]> {
    throw new Error("Method not implemented.");
  }

  togglePage(goNextPage: boolean, node: CatalogNode): Promise<boolean> {
    throw new Error("Method not implemented.");
  }

  resetFilter() {
    throw new Error("Method not implemented.");
  }

  addTextFilter(searchText: string) {
    throw new Error("Method not implemented.");
  }

  addAttributeFilter(attribute: string, value: string) {
    throw new Error("Method not implemented.");
  }

  setSpatialFilter(spatialFilter: FilterSpatial) {
    throw new Error("Method not implemented.");
  }
}
