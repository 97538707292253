import { EsriProvider } from "@wega-providers/esri.provider";
import { GeoExtent } from "../structures/extent";
import { eSpatialRelationship } from "@shared/wega-utils/wega-enums";

export class FilterSpatial {
  public relation: eSpatialRelationship;
  public name: string;

  constructor(private _relation: string, public _name: string, public geometry: any, private esri: EsriProvider) {
    this.relation = this.parseRelation(_relation);
    this.name = _name;
  }

  public async getExtent(): Promise<GeoExtent> {
    const [webMercatorUtils] = await this.esri.loadModules(["esri/geometry/webMercatorUtils"]);

    const wkid = this.geometry.spatialReference.latestWkid || this.geometry.spatialReference.wkid;
    const mbr = this.getMinMaxXY(this.geometry.rings);
    const minXY = webMercatorUtils.xyToLngLat(mbr[0], mbr[1]);
    const maxXY = webMercatorUtils.xyToLngLat(mbr[2], mbr[3]);
    const answerExtent = new GeoExtent(wkid, minXY[0], minXY[1], maxXY[0], maxXY[1]);

    return answerExtent;
  }

  getMinMaxXY(coordsArray: any[][][]) {
    let minx, miny, maxx, maxy;
    minx = maxx = coordsArray[0][0][0];
    miny = maxy = coordsArray[0][0][1];

    for (const ring of coordsArray) {
      for (const coord of ring) {
        if (coord[0] < minx) {
          minx = coord[0];
        }

        if (coord[0] > maxx) {
          maxx = coord[0];
        }

        if (coord[1] < miny) {
          miny = coord[1];
        }

        if (coord[1] > maxy) {
          maxy = coord[1];
        }
      }
    }

    return [minx, miny, maxx, maxy];
  }

  async pointIsInside(x: number, y: number, srs: string): Promise<boolean> {
    const [Point, Polygon, SpatialReference] = await this.esri.loadModules(["esri/geometry/Point", "esri/geometry/Polygon", "esri/SpatialReference"]);

    const area = new Polygon(this.geometry);
    const point = new Point(x, y, new SpatialReference(srs));
    const pointInside = area.contains(point);

    return pointInside;
  }

  async intersectionWithGeometry(geometry: any, srs: any): Promise<boolean> {
    const [geometryEngine, Polygon, SpatialReference] = await this.esri.loadModules([
      "esri/geometry/geometryEngine",
      "esri/geometry/Polygon",
      "esri/SpatialReference",
    ]);

    const area = new Polygon(this.geometry);
    const extent = new Polygon(geometry);
    // var polygon = new Polygon(new SpatialReference(srs));
    // polygon.addRing([extent.xmin,extent.ymin], [extent.xmin, extent.ymax], [extent.xmax, extent.ymax], [extent.xmax, extent.ymin]);

    const pointInside = area.intersect(geometry);
    return pointInside;
  }

  /// Пока мы используем отношения ESRI и карту ESRI преобразование - 1 к 1
  parseRelation(relation: string): eSpatialRelationship {
    switch (relation) {
      case "SPATIAL_REL_CONTAINS":
      case "CONTAINS":
        return eSpatialRelationship.SPATIAL_REL_CONTAINS;

      case "SPATIAL_REL_CROSSES":
      case "CROSSES":
        return eSpatialRelationship.SPATIAL_REL_CROSSES;

      case "SPATIAL_REL_ENVELOPEINTERSECTS":
        return eSpatialRelationship.SPATIAL_REL_ENVELOPEINTERSECTS;

      case "SPATIAL_REL_INDEXINTERSECTS":
        return eSpatialRelationship.SPATIAL_REL_INDEXINTERSECTS;

      case "SPATIAL_REL_INTERSECTS":
      case "INTERSECTS":
        return eSpatialRelationship.SPATIAL_REL_INTERSECTS;

      case "SPATIAL_REL_OVERLAPS":
      case "OVERLAPS":
        return eSpatialRelationship.SPATIAL_REL_OVERLAPS;

      case "SPATIAL_REL_RELATION":
      case "RELATION":
        return eSpatialRelationship.SPATIAL_REL_RELATION;

      case "SPATIAL_REL_TOUCHES":
        return eSpatialRelationship.SPATIAL_REL_TOUCHES;

      case "SPATIAL_REL_WITHIN":
      case "WITHIN":
        return eSpatialRelationship.SPATIAL_REL_WITHIN;

      default:
        return eSpatialRelationship.SPATIAL_REL_INTERSECTS;
    }
  }
}
