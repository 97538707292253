import { Injectable } from "@angular/core";
import { UserState } from "@domain/data/structures/user-state";
import { GeoExtent } from "@domain/data/structures/extent";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { UtilsProvider } from "@shared/providers/utils.provider";
import { Observable, of, Subject, BehaviorSubject } from "rxjs";
import { QueryFeature } from "@domain/data/feature/query-feature";

export type AnalysisDataObject = { title: string; data: [{}, any[], any[]]; features: QueryFeature[]; hasChart: boolean; chartZIndex: number };

@Injectable()
export class AnalysisProvider {
  public sourcesList = Array<AnalysisDataObject>();

  constructor() {}

  takeQueryFeatures(title: string, queryFeatures: QueryFeature[]) {
    console.log(queryFeatures);

    const allAttributes = {};

    for (const featureKey in queryFeatures) {
      const feature = queryFeatures[featureKey];
      for (const attributeKey in feature.attributes) {
        allAttributes[attributeKey] = feature.attributes[attributeKey];
      }
    }

    const uniqueAttributes = Object.keys(allAttributes[Object.keys(allAttributes)[0]]);

    const output = queryFeatures.map((qf) => {
      const first = Object.keys(qf.attributes)[0];
      const attributes = qf.attributes[first];
      const transformedAttributes = {};

      uniqueAttributes.forEach((attr) => {
        const value = attributes[attr];
        if (value) {
          const parsed = +value.toString();
          const parsed2 = +value.toString().replaceAll(",", ".");

          if (!isNaN(parsed)) {
            transformedAttributes[attr] = parsed;
          } else if (!isNaN(parsed2)) {
            transformedAttributes[attr] = parsed2;
          } else {
            transformedAttributes[attr] = value;
          }
        }
      });

      return transformedAttributes;
    });

    const xVs = uniqueAttributes.map((name, i) => ({ id: `x-${i}`, name }));
    const yVs = uniqueAttributes.map((name, i) => ({ id: `y-${i}`, name }));

    this.sourcesList.push({ title, data: [output, xVs, yVs], chartZIndex: 1000, hasChart: false, features: queryFeatures });
    // this.ui.notify({
    //   type: "SHOW_CHART",
    //   state: {
    //     enabled: true,
    //     input: {
    //       data,
    //       xValues: xVs,
    //       yValues: yVs,
    //     },
    //   },
    // });
  }
}
