import { AndOrNotCompareFilter, WegaCompareFilter } from "./common/search.engine.utils";

export interface WegaSearchEngine {
  title: string;
  description: string;
  message: string;
  results: WegaSearchResult[];
  templateInputKey: string;
  supportsComplexSearch: boolean;
  comparator: (input: string) => WegaCompareFilter;
  template(): { new (...args: any[]): any };
  search(text: string): Promise<void>;
  clear(): Promise<void>;
}

export type WegaSearchEnginePageInfo = {
  currentPage: number;
  pageSize: number;
  totalPages: number;
};

export type WegaSearchEngineInfo = {
  engine: WegaSearchEngine;
  enabled: boolean;
  elapsed: number;
  collapsed: boolean;
  searching: boolean;
  index: number;
  pageInfo: WegaSearchEnginePageInfo;
};

export interface WegaSearchResult {
  source: string;
  name: string;
  description: string;
  values: string[];
  cancelHighlight: boolean;
  zoom: () => Promise<boolean>;
  highlight: () => Promise<boolean>;
  open: () => Promise<boolean>;
  details: () => Promise<boolean>;
  templateInput: any;
}

export abstract class StatefulSearchEngine implements WegaSearchEngine {
  templateInputKey: string;

  results: WegaSearchResult[] = [];
  message = null;

  abstract title: string;
  abstract description: string;
  supportsComplexSearch = false;
  template = () => null;

  abstract engineSearch(text: string): Promise<WegaSearchResult[]>;
  comparator = (input: string) => new AndOrNotCompareFilter(input);

  async search(text: string) {
    this.results = [];
    this.message = null;

    this.results = await this.engineSearch(text);
  }

  signal(message: string) {
    this.message = message;
  }

  clear(): Promise<void> {
    this.results = [];
    return;
  }
}
