import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LegendDescription } from "../../legend/legend-description";
import { ResourceFilter } from "../../filter/resource-filter";
import { FieldsInfo } from "../../feature/fields-info";
import { QueryFeature } from "../../feature/query-feature";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { MapService } from "@domain/data/resource/map-service";
import { ConfigService } from "@shared/config/config-service";
import { LayerData } from "@domain/data/structures/layer-data";
import { GeoExtent } from "@domain/data/structures/extent";
import { ServiceState } from "@shared/wega-utils/wega-enums";
import { LayerStatistic } from "@domain/data/structures/layerStatistic";

export class RestService implements MapService {
  state: ServiceState;
  canUseFilter: boolean = false;

  constructor(private config: ConfigService, private web: WebClientProvider) { }

  getLayer(): Promise<any> {
    return null;
  }

  async getLayerStatistic(layer: string, filter: ResourceFilter) {
    return new LayerStatistic();
  }

  async getAttributesByID(featureId: string): Promise<any> {
    const url = this.config.url.replace("{{ID}}", featureId);
    const ans = await this.web.httpGet(url);

    if (ans && ans["data"]) {
      return ans["data"];
    }

    return ans;
  }

  loadLayerInfo(): Promise<FieldsInfo> {
    return null;
  }

  getPointFeatureInfo(coordinates: any): Promise<any> {
    return null;
  }

  getFeaturesByQuery(filter: ResourceFilter): Promise<[QueryFeature[], boolean]> {
    console.warn("Для RestService метод getFeaturesByQuery не реализован!");

    return Promise.resolve([[], false]);
  }

  setFilter(filter: any) { }

  saveFeatures(queryFeature: any): Promise<boolean> {
    throw new Error("Method not implemented.");
  }

  getExtentFeatureInfo(extent: GeoExtent): Promise<QueryFeature[]> {
    console.warn("Для RestService метод getExtentFeatureInfo не реализован!");

    return Promise.resolve([]);
  }

  public getLegend(): LegendDescription[] {
    return null;
  }

  public async loadSpatialData(): Promise<LayerData[]> {
    return null;
  }
}
