import { Injectable } from "@angular/core";
import { UtilsProvider } from "@shared/providers/utils.provider";

@Injectable({ providedIn: "root" })
export class ArcGisMapProvider {
  constructor(private utils: UtilsProvider) {}

  get EsriMap(): any {
    return this.utils.browser.window["__agmap"]();
  }

  get EsriMapContainer(): any {
    return document.getElementById("agmap_container");
  }
}
