import { EsriProvider } from "@wega-providers/esri.provider";
import { ArcGisMapProvider } from "../providers/arcgis-map.provider";
import { WegaPlugin } from "@wega-providers/plugins.provider";
import { ConfigService } from "@shared/config/config-service";

export class ArcGisTrackerProvider implements WegaPlugin {
  constructor(public esri: EsriProvider, public arcgis: ArcGisMapProvider) {}

  map: any;
  riftLayer: any;
  feature: any;
  tracker: any;

  static getValueByKeyIgnoreSense(obj, key) {
    const keys = Object.keys(obj);
    const realKey = keys.find((_key) => key.toLowerCase() === _key.toLowerCase());

    if (realKey) {
      return obj[realKey];
    } else {
      return "";
    }
  }

  async apply(params: any, serviceConfig: ConfigService) {
    if (!params.feature) {
      console.error("tracker plugin needs feature", params);
      return;
    }

    const riftConfig = serviceConfig.plugins.riftTracker;
    const trackerWidget = await this.getTracker(serviceConfig, riftConfig);
    const feature = params.feature;
    const imgUrl = ArcGisTrackerProvider.getValueByKeyIgnoreSense(feature.attributes, riftConfig.imageUrlField);
    let extent = ArcGisTrackerProvider.getValueByKeyIgnoreSense(feature.attributes, riftConfig.extentField) || "null";

    try {
      extent = JSON.parse(extent);
    } catch (err) {
      extent = null;
    }

    trackerWidget.setSelected({
      feature,
      image: imgUrl,
      extent,
    });
  }

  async cancel() {
    if (this.tracker) {
      this.tracker.deselect();
      this.tracker.destroy();
    }

    this.map.removeLayer(this.riftLayer);
    this.map.graphics.clear();
  }

  async getFeatureOnService(Query, feature): Promise<any> {
    let resolver = null;
    let rejecter = null;
    const prom = new Promise((res, rej) => {
      resolver = res;
      rejecter = rej;
    });
    const q = new Query();

    if (!feature) {
      alert("Не передан объект для разреза");
      return;
    }

    q.where = `objectid = ${feature.attributes.OBJECTID}`;
    q.returnGeometry = true;
    q.outFields = ["*"];

    this.riftLayer.queryFeatures(
      q,
      (featureSet) => {
        const _riftFeature = featureSet.features[0];

        resolver(_riftFeature);
      },
      (err) => {
        const msg = "Не удалось найти объект разреза в сервисе редактирования";

        alert(msg);
        rejecter(msg);
      }
    );

    return prom;
  }

  clickEvent(serviceConfig: ConfigService) {
    this.cancel();
  }

  async getTracker(serviceConfig: ConfigService, pluginConfig: any) {
    if (this.tracker) {
      return this.tracker;
    }

    const [FeatureLayer, Query, Tracker] = await this.esri.loadModules([
      "esri/layers/FeatureLayer",
      "esri/tasks/query",
      "/assets/scripts/widgets/tracker.widget.js",
    ]);

    this.map = this.arcgis.EsriMap;

    const riftConfig = <{ imageUrl: string; extentField: string; editServer: string; imageUrlField: string }>pluginConfig;

    if (!riftConfig) {
      console.warn(`Не найдена конфигурация для сервиса '${serviceConfig.title}'`);
      return;
    }

    const riftUrl = riftConfig.editServer;
    this.riftLayer = new FeatureLayer(riftUrl);
    const tracker = new Tracker({ map: this.map, div: "riftMapContainer", lineLayer: this.riftLayer });

    tracker.on("rift url change", async (newUrl, feature) => {
      const _riftFeature: any = await this.getFeatureOnService(Query, feature);

      _riftFeature.attributes[riftConfig.imageUrlField] = newUrl;
      this.riftLayer.applyEdits(
        null,
        [_riftFeature],
        null,
        (add, updates) => {
          const result = updates[0];

          if (!result.success) {
            alert("Не удалось сохранить новое изображение разреза");
          }
        },
        (err) => {
          alert("Не удалось сохранить новое изображение разреза");
        }
      );
    });

    tracker.on("extent change", async (extent, feature) => {
      const _riftFeature: any = await this.getFeatureOnService(Query, feature);

      _riftFeature.attributes[riftConfig.extentField] = JSON.stringify(extent);
      this.riftLayer.applyEdits(
        null,
        [_riftFeature],
        null,
        (add, updates) => {
          const result = updates[0];

          if (!result.success) {
            alert("Не удалось сохранить границы разреза");
          }
        },
        (err) => {
          alert("Не удалось сохранить границы разреза");
        }
      );
    });

    this.map.on("click", () => {
      this.clickEvent(serviceConfig);
    });

    this.map.addLayer(this.riftLayer);

    tracker.startup();
    this.tracker = tracker;
    return tracker;
  }
}
