import { Component, OnInit } from '@angular/core';
import { WegaAuthenticationProvider } from '@auth/providers/authentication.provider';

@Component({
  selector: 'wega-auth-control',
  templateUrl: './auth-control.component.html',
  styleUrls: ['./auth-control.component.css']
})
export class WegaAuthControlComponent implements OnInit {
  constructor(
  private auth: WegaAuthenticationProvider) {

  }

  ngOnInit() {

  }
}
