import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as xml2js from "xml-js";

@Injectable({ providedIn: "root" })
export class WebClientLegacyProvider {
  headers = {};

  get http(): HttpClient {
    return this.httpClient;
  }

  constructor(private httpClient: HttpClient) {}

  async httpGet(sourceUrl: string, corsScript: string | undefined, headers: any, parameters: any = {}, parseResponse: boolean = true): Promise<any> {
    const httpOptions: RequestInit = { headers: headers || {} };
    const url = this.concatUrlWithParameters(sourceUrl, parameters);

    let queryTextResult = "";
    let queryJsonResult = {};

    try {
      /// responseType: 'text' - потому что парсинг 'XML' все равно не поддерживается (только json), а чаще всего ответ будет именно XML
      const queryAjax = await fetch(url, httpOptions);

      queryTextResult = await queryAjax.text();
    } catch (ex) {
      // console.log('exception:');
      console.error(ex);
    }

    if (queryTextResult === "") {
      try {
        const corsUrl = (corsScript ?? "") + url.replace("?", "&");
        const queryAjax = await fetch(corsUrl, httpOptions);

        queryTextResult = await queryAjax.text();
      } catch {
        console.error("unable to fetch:" + sourceUrl);

        return null;
      }
    }

    // Если встречается этот символ, то почти наверняка ответ интерпретируется как UTF8 в то время как там win1251
    // if (queryTextResult.indexOf('�') != -1 ){
    //   console.log('кодировки в ответе некорректны');
    // }

    if (parseResponse) {
      try {
        queryJsonResult = this.parseResponse(queryTextResult);
        return queryJsonResult;
      } catch (ex) {
        // Если не удалось распарсить - возвращаем текст?
        console.error("ParseError", ex);
      }
    }

    return queryTextResult;
  }

  private parseResponse(queryTextResult: string): {} {
    const responseStart = queryTextResult.trim().substring(0, 6).toLocaleLowerCase();
    if (responseStart === "<html>" || queryTextResult.trim().substring(0, 14).toLocaleUpperCase() === "<!DOCTYPE HTML") {
      // HTML
      return queryTextResult;
    }

    if (responseStart[0] === "<") {
      // XML
      const answer = xml2js.xml2js(queryTextResult, { compact: true, trim: true, alwaysArray: true, ignoreComment: true });

      return answer;
    }

    if (responseStart[0] === "[" || responseStart[0] === "{") {
      // JSON
      const answer = JSON.parse(queryTextResult);
      return answer;
    }

    return queryTextResult;
  }

  private concatUrlWithParameters(url: string, parameters: any) {
    let answerUrl = url;
    if (parameters) {
      answerUrl = this.ensureLastUrlSymbol(answerUrl); // последний символ в URL = ?/&
    }

    for (const paramName in parameters) {
      if (answerUrl !== "") {
        answerUrl += "&";
      }

      answerUrl += paramName + "=" + encodeURIComponent(parameters[paramName]);
    }

    return answerUrl;
  }

  private ensureLastUrlSymbol(url: string): string {
    if (url.slice(-1) === "?" || url.slice(-1) === "&") {
      return url;
    }

    if (url.indexOf("?") === -1) {
      return url + "?";
    }

    return url + "&";
  }

  private async httpGetWithProxy(url: string, proxy: string): Promise<any> {
    return this.httpGet(url, proxy, {}, {}, true);
  }

  private async httpGetDefault(url: string): Promise<any> {
    return this.httpGet(url, undefined, {}, {}, true);
  }

  private async httpPost(): Promise<any> {
    throw Error("not implemented");
  }
}
