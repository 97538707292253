import { WegaSearchResult, StatefulSearchEngine } from "../../search.engine";
import { CatalogNode } from "@wega3/components/catalog-tree/catalog-node";
import { DynamicDatabase } from "@wega3/components/catalog-tree/dynamic-database";
import { ResourceProvider } from "@wega-providers/resource.provider";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";

export class CatalogResourcesSearchEngine extends StatefulSearchEngine {
  constructor(private res: ResourceProvider, private locale: LocaleProvider) {
    super();
  }

  get title(): string {
    return this.locale.current === "en" ? "Map catalog" : "Каталог карт";
  }
  get description(): string {
    return this.locale.current === "en"
      ? "Search for maps in the catalog"
      : "Поиск карт в каталоге";
  }

  async engineSearch(text: string): Promise<WegaSearchResult[]> {
    const catalogDb: DynamicDatabase = window["CATALOGDB"];

    if (!catalogDb) {
      this.signal("Не удалось найти каталоги для поиска");
      return [];
    }

    let found = <CatalogNode[]>[];
    const comparator = this.comparator(text);

    for await (const catalog of catalogDb.catalogsList) {
      if (catalog.searchScanner) {
        const res = await catalog.searchScanner.search(
          catalog,
          text,
          comparator
        );

        found = found.concat(res);
      }
    }

    return found
      .filter((node) => !node.notAvailable)
      .map((node) => {
        return <WegaSearchResult>{
          name: node.title,
          source: `каталог '${node.catalog.title}'`,
          open: () => this.activateNode(node),
          values: comparator.values,
        };
      });
  }

  async activateNode(node: CatalogNode): Promise<boolean> {
    const [_, added] = await node.addRemoveToMap(this.res);
    return Promise.resolve(!node.isOpenLink && added);
  }
}
