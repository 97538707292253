import { WegaSearchResult, StatefulSearchEngine } from "../../search.engine";
import { UtilsProvider } from "@shared/providers/utils.provider";
import { ArcGisMapProvider } from "src/app/modules/wega-ui/components/arcgis-map/providers/arcgis-map.provider";
import { EsriProvider } from "@wega-providers/esri.provider";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { RasterDBSearchDetailsComponent } from "./raster-db-search.details";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { WegaUtils } from "@shared/wega-utils/wega-utils";

declare const ArcgisToGeojsonUtils: any;

type UnifiedSearchResult = {
  type: string;
  geometry: any;
  properties: any;
  mapName: string;
  layerName: string;
  featureName: string;
  serviceUrl: string;
  layerID: string;
  featureId: string;
  attributeName: string;
  attributeValue: string;
};

export class RasterDbSearchEngine extends StatefulSearchEngine {
  private rasterDBUrl: string;

  constructor(
    private utils: UtilsProvider,
    private arcgis: ArcGisMapProvider,
    private esri: EsriProvider,
    private web: WebClientProvider,
    private appConfig: AppConfigProvider,
    private locale: LocaleProvider,
    ...apiArguments: string[]
  ) {
    super();

    this.rasterDBUrl = apiArguments[0];
  }

  templateInputKey = "input";
  template = () => RasterDBSearchDetailsComponent;

  get title(): string {
    return this.locale.current === "en" ? `Raster DB search` : `Поиск по материалам растровой БД`;
  }

  get description(): string {
    return this.locale.current === "en" ? `Search using Raster DB (${this.rasterDBUrl})` : `Поиск по растровой БД (${this.rasterDBUrl})`;
  }

  async engineSearch(text: string): Promise<WegaSearchResult[]> {
    const searchPhrase = encodeURIComponent(text);
    try {
      const searchUrl = `${this.rasterDBUrl}/ElementList.php?&srch=${searchPhrase}`;
      let rootResults: Array<any> = await this.web.httpGet(searchUrl);

      if (0 === rootResults.length) {
        this.signal("Поиск по растровой БД не принес результатов");
        return [];
      }

      const transformResults = (objects) => objects.map((o) => ({ key: o["key"], title: o["title"], type: o["type"], state: null, source: o }));
      return [
        {
          templateInput: {
            transformResults,
            result: transformResults(rootResults),
            getNodesFn: async (node) => {
              const nodeResultsUrl = `${this.rasterDBUrl}/webmapget.php?node=${node}&srch=${searchPhrase}`;
              return await this.web.httpGet(nodeResultsUrl);
            },
          },
          cancelHighlight: true,
        } as WegaSearchResult,
      ];
    } catch (err) {
      if (err instanceof Error) {
        this.signal(`Не удалось получить данные из растровой БД (url: ${this.rasterDBUrl})! Ошибка: ${err.message}`);
      } else {
        this.signal(`Неизвестная ошибка при попытке получения данных из растровой БД (url: ${this.rasterDBUrl})!`);
      }

      return [];
    }
  }
}
