import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from "@angular/core";
import { AreaFilterProvider } from "@wega-providers/area-filter.provider";
import { AttributeProvider } from "@wega-providers/attribute.provider";
import { EsriProvider } from "@wega-providers/esri.provider";
import { StorageProvider } from "@wega-providers/storage.provider";
import { ResourceProvider } from "@wega-providers/resource.provider";
import { OlProvider } from "@wega-providers/ol.provider";
import { AssetLoaderProvider } from "@wega-providers/asset-loader.provider";
import { PluginsProvider } from "@wega-providers/plugins.provider";
import { FilterProvider } from "@wega-providers/filter.provider";
import { WegaI18nModule } from "../i18n/i18n.module";
import { loadModules } from "esri-loader";
import { DynamicDatabase } from "@wega3/components/catalog-tree/dynamic-database";
import { AnalysisProvider } from "@wega-providers/analysis.provider";
import { LayerProvider } from "@wega-providers/layer.provider";
import { LegendProvider } from "@wega-providers/legend.provider";

@NgModule({
  imports: [WegaI18nModule],

  providers: [
    AreaFilterProvider,
    AssetLoaderProvider,
    AttributeProvider,

    EsriProvider,
    {
      provide: APP_INITIALIZER,
      deps: [EsriProvider],
      multi: true,
      useFactory: (esri: EsriProvider) => () =>
        Promise.all([
          (async () => {
            const [esriConfig, urlUtils] = await loadModules(["esri/config", "esri/urlUtils"], EsriProvider.esriOptions);

            esri.esriConfig = esriConfig;
            esri.urlUtils = urlUtils;
          })(),
        ]),
    },

    EsriProvider,
    FilterProvider,
    LayerProvider,
    LegendProvider,
    OlProvider,
    ResourceProvider,
    StorageProvider,
    PluginsProvider,
    AnalysisProvider,
    DynamicDatabase,
  ],
})
export class WegaProvidersModule {
  constructor(@Optional() @SkipSelf() self: WegaProvidersModule) {
    if (self) {
      throw new Error("Модуль зависимостей может быть внедрен только один раз!");
    }
  }
}
