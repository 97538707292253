import { ConfigResource } from "@shared/config/config-resource";
import { WegaUtils } from "@shared/wega-utils/wega-utils";

export class UserState {
  userId = WegaUtils.createGuid();
  timeStamp: number;

  Resources: ConfigResource[];
  Zoom: any;
  Center: any;

  constructor() {
    this.Resources = [];

    this.Zoom = null;
    this.Center = null;

    this.timeStamp = -1;
  }
}
