import { NgModule, EventEmitter } from "@angular/core";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { EsriProvider } from "@wega-providers/esri.provider";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { WegaResourceCapabilities } from "./resource-capabilities";
import { GeoExtent } from "../structures/extent";
import { ConfigResource } from "@shared/config/config-resource";
import { FeatureDescription } from "../feature/feature-description";
import { ResourceFilter } from "../filter/resource-filter";
import { VectorService } from "../service/vector/vector.service";
import { QueryFeature } from "../feature/query-feature";
import { LayerData } from "../structures/layer-data";
import { MapClickPoint } from "../structures/map-click-point";
import { FilterSpatial } from "../filter/filter-spatial";
import * as JSZip from "jszip";
import { saveAs } from "file-saver";
import { GenericService } from "../service/generic-service";
import { ShapeEncoding } from "@shared/config/config-service";
import { FeatureExport } from "../feature/feature-export";
import { UtilsProvider } from "@shared/providers/utils.provider";
import { DUMMY_RESOURCE_TOKEN } from "src/app/modules/wega-providers/utils/resource.utils";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";
import { OlProvider } from "@wega-providers/ol.provider";
import { FieldConfig } from "@shared/config/config-field";
import { WegaUtils } from "@shared/wega-utils/wega-utils";
import { GEOMETRY_OPERATION } from "@shared/wega-utils/wega-enums";
import { ViewResourceLayer, LayerProvider } from "@wega-providers/layer.provider";
import { ArcGisMapProvider } from "src/app/modules/wega-ui/components/arcgis-map/providers/arcgis-map.provider";
import { environment } from "src/environments/environment";

declare var ArcgisToGeojsonUtils: any;
declare var Terraformer: any;
declare var dbf: any;

export class WegaResource {
  public capabilities: WegaResourceCapabilities;
  public layers: ViewResourceLayer[] = [];

  get resourceExtent(): GeoExtent {
    return this._resourceExtent;
  }

  public get show(): boolean {
    return this._show;
  }

  public set show(value: boolean) {
    this._show = this.config.visibility = value;

    this.onLayersChanged.emit("null");
    return;
  }

  public get opacity(): number {
    return this._opacity;
  }

  public set opacity(value: number) {
    this._opacity = value;

    this.serviceModuleList.forEach((s) => s.setOpacity(value));
  }

  onResourceAdd() {}

  onResourceRemove() {}

  constructor(
    config: ConfigResource,
    public web: WebClientProvider,
    public esri: EsriProvider,
    public ag: ArcGisMapProvider,
    public ol: OlProvider,
    public utils: UtilsProvider,
    private globalConfig: AppConfigProvider,
    private locale: LocaleProvider,
    private layerProvider: LayerProvider,
    private _order: number
  ) {
    this.order = _order;
    this.id = config.id || WegaUtils.createGuid();
    this.config = config;
    this.title = config.title;
    this.serviceModuleList = [];
    this.onLayersChanged = new EventEmitter<string>();
    this.onFeatureHighlight = new EventEmitter<string>();
    this.featureDescription = this.config.featureDescription ?? new FeatureDescription();
    this.filter = new ResourceFilter(esri);

    // this.description = this.buildDescription();

    this._hideLayers = !globalConfig.Environment.ShowLayersList;
    this._hideLegend = !globalConfig.Environment.ShowLegend;
    this._hideSearch = !globalConfig.Environment.ShowSearch;
    this._hideInfo = !globalConfig.Environment.ShowMetadata;
    this._hideFilter = !globalConfig.Environment.ShowFilter;
    this._hideView = !globalConfig.Environment.ShowView;

    this._show = this.config.visibility;

    for (const serviceConfig of this.config.servicesList) {
      const newService = new GenericService(config, serviceConfig, web, this.utils, esri, ol, globalConfig, this.layerProvider, this.locale);

      newService.onStateChanged.subscribe(() => {
        this.updateState();
      });

      newService.onInfoUpdate.subscribe(() => {
        const serviceFeaturesRead = newService.fieldsDescription && newService.fieldsDescription.fieldsList;

        if (serviceFeaturesRead) {
          /// если в конфиге уже были заданы поля, то дополнить нужно только их (данными, считанными динамически),
          /// в противном случае - ресурс регистрирует в своем составе все возможные поля.
          const appendNonExistentFields = !this.config.featureDescription;
          this.featureDescription.mergeWith?.(newService.fieldsDescription, appendNonExistentFields);
        }

        /// если заданы производные поля (через конфиг), то их тоже надо добавить
        if (this.config.featureExtensions) {
          const newFields = this.config.featureExtensions.fieldsList;
          this.featureDescription.fieldsList = this.featureDescription.fieldsList.concat(newFields);

          /// устранение проблемы с дублированием полей при включении/выключении поля [#5313]
          /// наверняка это не самое корректное решение проблемы (разумнее было бы перехватывать сам момент добавления extendFields)
          /// однако у меня не получилось сделать лучше, и это решение по крайней мере работает
          const uniqueFeatures = Array<FieldConfig>();
          for (const feature of this.featureDescription.fieldsList) {
            const isDuplicate = uniqueFeatures.some((f) => f.equals(feature));

            if (!isDuplicate) {
              uniqueFeatures.push(feature);
            }
          }

          this.featureDescription.fieldsList = uniqueFeatures;
        }

        if (this.config.sortFields) {
          const extensionFields = this.config.featureExtensions?.fieldsList.map((f) => f.name) ?? [];
          this.featureDescription.fieldsList.sort((a, b) => {
            const aIsPriority = extensionFields.indexOf(a.name) != -1;
            const bIsPriority = extensionFields.indexOf(b.name) != -1;

            if (aIsPriority === bIsPriority) {
              return a.title.toUpperCase().localeCompare(b.title.toUpperCase());
            }

            return aIsPriority ? -1 : 1;
          });
        }
      });

      this.serviceModuleList.push(newService);
    }

    /// если в конфиге определена коллекция fields (даже равная []), то следует запретить модификацию метаданных полей ресурса
    this.config.featureDescription?.notEmpty?.() && this.featureDescription.lockFields();

    /// создание слоя для маски
    this.filterLayer = new VectorService(globalConfig, this.locale);

    /// создание слоя для шейпа
    this.shapeLayer = new VectorService(globalConfig, this.locale);

    /// создание слоя для выбранных объектов ("подсветки")
    this.selectionLayer = new VectorService(globalConfig, this.locale);

    this.selectionLayer.onFeatureHover.subscribe((featureGuid) => {
      this.onFeatureHighlight.emit(featureGuid);
      this.highlightResourceFeature(featureGuid);
    });

    this.calculateExtent();
    this.capabilities = new WegaResourceCapabilities(this);
  }

  id: string;

  _hideLayers: boolean;
  _hideLegend: boolean;
  _hideSearch: boolean;
  _hideInfo: boolean;
  _hideFilter: boolean;
  _hideView: boolean;
  _detachedLegend: boolean;

  public onLayersChanged: EventEmitter<string>;
  public onFeatureHighlight: EventEmitter<string>;
  public filter: ResourceFilter;

  public title: string;
  public description: string;

  public activeOperations = "";
  public searchStatus = "";

  public searchExpired = true;

  public highlightGuid: string;

  public selectedFeatures: QueryFeature[]; // = [];

  public featureDescription: FeatureDescription;

  public selectionLayer: VectorService;
  public filterLayer: VectorService;
  public shapeLayer: VectorService;

  public config: ConfigResource;
  public serviceModuleList: GenericService[];

  state: string;
  order: number;

  private _resourceExtent: GeoExtent;

  private _show = false;

  private _opacity = 1;

  static fromState(resourceList: any): WegaResource[] {
    const answer: WegaResource[] = [];

    return answer;
  }

  buildDescription(): string {
    return "";
  }

  public saveFeature(feature: QueryFeature) {
    this.serviceModuleList.forEach((serviceClass) => {
      if (serviceClass.supportsEdit()) {
        serviceClass.save(feature);
      }
    });
  }

  calculateExtent() {
    if (this.config.extent) {
      this._resourceExtent = this.config.extent;
    } else {
      let extentsFound = false;
      const resourceExtent = new GeoExtent();

      for (const service of this.serviceModuleList) {
        const serviceExtent: GeoExtent = service.getExtent();
        if (!!serviceExtent) {
          resourceExtent.extend(serviceExtent);
          extentsFound = true;
        }
      }

      if (extentsFound) {
        this._resourceExtent = resourceExtent;
      }
    }
  }

  async getResourceLayers(): Promise<any[]> {
    if (!this._show) {
      return [];
    }

    const layers = [];
    const layerMask = await this.filterLayer.getLayer();
    const layerShape = await this.shapeLayer.getLayer();
    const layerSelection = await this.selectionLayer.getLayer();

    layers.push(layerMask);
    layers.push(layerShape);
    layers.push(layerSelection);

    for await (const service of this.serviceModuleList) {
      const esriLayer = await service.getMapLayers();

      if (esriLayer) {
        layers.push(esriLayer);
      }
    }

    return layers;
  }

  public async importShapeFile(files: any, vectorService: VectorService): Promise<void> {
    /// избыточность кода в этом методе была вызвана необходимостью отладки
    /// + в определенные моменты разработки обработка трех типов файлов осуществлялась по-разному

    return new Promise((r) => {
      vectorService.resetImportedFile();

      if (files.length !== 3) {
        this.utils.notify("Нужно предоставить 3 файла!");
      } else {
        let count = 0;
        for (let i = 0, f; (f = files[i]); i++) {
          const ext = f.name.slice(-3).toLowerCase();
          const reader = new FileReader();

          reader.onload = async (e) => {
            const data = e.target.result;
            if (ext === "shp") {
              await vectorService.importShpFile(data);
            } else if (ext === "prj") {
              await vectorService.importPrjFile(data);
            } else if (ext === "dbf") {
              await vectorService.importDbfFile(data);
            }

            count++;
            if (count == 3) {
              await vectorService.finalizeShapeImport();
              r();
            }
          };

          if (ext === "shp") {
            reader.readAsArrayBuffer(f);
          } else if (ext === "prj") {
            reader.readAsArrayBuffer(f);
          } else if (ext === "dbf") {
            reader.readAsArrayBuffer(f);
          }
        }
      }
    });
  }

  async createDbf(features: QueryFeature[]): Promise<Blob> {
    /// ограничение DBF-файла
    const maxKeySize = 10;
    const maxValueSize = 255;

    return new Promise((resolve) => {
      let fieldDescriptors = [] as Array<{
        name: string;
        type: string;
        size: number;
      }>;
      let records = [] as Array<{ [key: string]: string }>;

      features.forEach((ft) => {
        const attrs = ft.arcgisFeature?.attributes || ft.attributes[Object.keys(ft.attributes)[0]];
        const record = {};

        for (const key in attrs) {
          let slicedKey = key.slice(0, maxKeySize);
          slicedKey = slicedKey.replace(/\./g, "_");
          if (!fieldDescriptors.find((fd) => fd.name == slicedKey)) {
            fieldDescriptors.push({
              name: slicedKey,
              type: "C",
              size: maxValueSize,
            });
          }

          var value = attrs[key];
          record[slicedKey] = (value ?? "").toString().slice(0, maxValueSize);
        }

        records.push(record);
      });

      fetch(`${this.globalConfig.Environment.DbfServiceUrl}dbf`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ fieldDescriptors, records }),
      })
        .then((response) => response.json())
        .then((data) =>
          fetch(`${this.globalConfig.Environment.DbfServiceUrl}${data.dbfName}`)
            .then((response) => response.blob())
            .then((blob) => resolve(blob))
        );
    });
  }

  public async getShapeAll(viewLayer: ViewResourceLayer, serviceList: GenericService[], encoding: ShapeEncoding, name: string) {
    const featureExport = new FeatureExport();
    const allLayersList = await this.loadAllData(serviceList, viewLayer.name);
    const layersList = allLayersList.filter((l) => l.name.toString() === viewLayer.name.toString());

    for (let index = 0; index < layersList.length; index++) {
      const layer = layersList[index];
      const tmpVectorLayer = new VectorService(this.globalConfig, this.locale);

      await tmpVectorLayer.getLayer();
      await tmpVectorLayer.addFeatures(layer.featuresList);

      const [shapeFile, transformedAttributes] = featureExport.getShape(
        tmpVectorLayer.esriLayer.graphics,
        encoding,
        layer.featuresList,
        this.featureDescription.fieldsList
      );

      const dbfFile = await this.createDbf(transformedAttributes);
      this.downloadShape(featureExport, encoding, `${name}${index === 0 ? "" : "-" + index}`, shapeFile, dbfFile);
    }
  }

  async loadAllData(serviceList: GenericService[], layerName: string): Promise<LayerData[]> {
    const answer: LayerData[] = [];

    for await (const service of serviceList) {
      const layerData: LayerData[] = await service.loadSpatialData(layerName);

      for (const data of layerData) {
        answer.push(data);
      }
    }

    return answer;
  }

  public selectionLayerExists() {
    return !!this.selectionLayer.esriLayer?.graphics?.length;
  }

  public async getShapeSelected(encoding: ShapeEncoding, name: string) {
    const featureExport = new FeatureExport();
    const [shapeFile, transformedAttributes] = featureExport.getShape(
      this.selectionLayer.esriLayer.graphics,
      encoding,
      this.selectedFeatures,
      this.featureDescription.fieldsList
    );

    /// объекты атрибутов, которые приходят из панели отбора, вместо оригинальных названий полей могут иметь уже подставленные псевдонимы,
    /// это может "сломать" выгружаемую атрибутивку, поэтому приходится возвращать назад оригинальные названия
    transformedAttributes.forEach((ft) => {
      let keysToRemove = [];
      for (const key in ft.arcgisFeature.attributes) {
        if (!this.featureDescription.fieldsList.find((fd) => fd.name == key)) {
          keysToRemove.push(key);
        } else {
          const actualKey = this.featureDescription.fieldsList.find((fd) => fd.name == key && !!fd.filterName)?.filterName;
          if (!!actualKey && !(actualKey in ft.arcgisFeature.attributes)) {
            Object.defineProperty(ft.arcgisFeature.attributes, actualKey, Object.getOwnPropertyDescriptor(ft.arcgisFeature.attributes, key));
            keysToRemove.push(key);
          }
        }

        keysToRemove.forEach((k) => delete ft.arcgisFeature.attributes[k]);
      }
    });

    const dbfFile = await this.createDbf(transformedAttributes);
    this.downloadShape(featureExport, encoding, name, shapeFile, dbfFile);
  }

  downloadShape(fex: FeatureExport, encoding: ShapeEncoding, fileName: string, shapeFile: { point; polygon; polyline }, dbfFile: Blob) {
    if (null === shapeFile) {
      this.utils.notify(this.locale.current !== "en" ? "Не удалось сформировать шейпфайл для скачивания!" : "Failed to generate a shapefile for download!");

      return;
    }

    fileName = fileName.trim(); /// если имя файла содержит в начале пробелы - ArcGIS нормально не читает проекцию
    fileName = fileName.replace(new RegExp(" ", "g"), "_"); /// а если есть пробелы - то наблюдаются проблемы с открытием DBF
    fileName = fileName.replace(new RegExp(";", "g"), "_"); /// точку с запятой тоже нельзя, иначе ArcGIS не сможет открыть шейп

    const downloadMultipleFilesAsZip = this.globalConfig.Environment.DownloadMultipleFilesAsZip;
    const files = Array<{ name: string; data: any }>();

    /// необходимо дать возможность скачивать шейпы разного класса (см. #4780)
    Object.keys(shapeFile).forEach((key) => {
      const currentShapefile = shapeFile[key];
      if (null !== currentShapefile) {
        files.push({
          name: `${fileName}-${key}.shp`,
          data: currentShapefile.shp.blob,
        });

        //files.push({ name: `${fileName}-${key}.dbf`, data: currentShapefile.dbf.blob });
        files.push({ name: `${fileName}-${key}.dbf`, data: dbfFile });

        files.push({
          name: `${fileName}-${key}.shx`,
          data: currentShapefile.shx.blob,
        });
        files.push({
          name: `${fileName}-${key}.prj`,
          data: currentShapefile.prj.blob,
        });

        //files.push({ name: `${fileName}-${key}.cpg`, data: currentShapefile.cpg?.blob ?? encoding});
        files.push({ name: `${fileName}-${key}.cpg`, data: "CP1251" });
      }
    });

    if (downloadMultipleFilesAsZip) {
      this.downloadAsZip(fileName, files);
      // this.utils.notify(`Сформирован и отправлен на загрузку Shape-файл класса '${key}'`);
    } else {
      files.forEach((file) => {
        fex.downloadFile(file.name, file.data);
      });
    }
  }

  public downloadAsZip(folder: string, files: { name: string; data: string }[]) {
    const zipFile: JSZip = new JSZip();

    files.forEach((file) => {
      zipFile.file(folder + "/ " + file.name, file.data);
    });

    zipFile.generateAsync({ type: "blob" }).then(
      (blob) => {
        saveAs(blob, folder + ".zip");
      },
      (err) => {
        console.log(err);
      }
    );
  }

  public async getJson(serviceList: GenericService[], layer: string, onlySelected: boolean, name: string) {
    const featureExport = new FeatureExport();

    if (onlySelected) {
      // let shapeFile = r.GetShape(this.selectionLayer.esriLayer.graphics, this.selectedFeatures, this.featureDescription.fieldsList);
      this.downloadJson(featureExport, this.selectionLayer.esriLayer.graphics, name);
      return;
    }

    const layersList = await this.loadAllData(serviceList, layer);

    for (let index = 0; index < layersList.length; index++) {
      const layer = layersList[index];
      const tmpVectorLayer = new VectorService(this.globalConfig, this.locale);

      await tmpVectorLayer.getLayer();
      await tmpVectorLayer.addFeatures(layer.featuresList);

      // let gj = ArcgisToGeojsonUtils.arcgisToGeoJSON(tmpVectorLayer.esriLayer);
      this.downloadJson(featureExport, tmpVectorLayer.esriLayer.graphics, `${name}${index === 0 ? "" : "-" + index}`);
    }
  }

  downloadJson(fex: FeatureExport, graphics: any, name: string) {
    const features = graphics.map((g) => {
        const f = g.toJson();
        const feature = Terraformer.ArcGIS.toGeoJSON(f);

        return feature;
      }),
      gj = JSON.stringify({ features, type: "FeatureCollection" }),
      jsonBlob = new Blob([gj], { type: "application/json" });
    fex.downloadFile(name + ".geojson", jsonBlob);
  }

  private async getFeatureInfo(queryFeaturePromise: (serviceModule: GenericService) => Promise<QueryFeature[]>) {
    this.searchExpired = false;
    this.setOperation("");

    const excludedResources = this.globalConfig.Environment.ExcludeResourcesFromSelection;
    if (excludedResources.indexOf(this.id) !== -1) {
      return;
    }

    if (!this._show) {
      // если слой не видимый, то запрос не выполняется
      return;
    }

    this.setOperation("search");

    try {
      for (const serviceModule of this.serviceModuleList) {
        if (!serviceModule.supportsClick()) {
          continue;
        }

        try {
          const features = await queryFeaturePromise(serviceModule);

          if (features) {
            await this.selectionLayer.addFeatures(features, this.config.featureDisplayAttrs);
            this.selectedFeatures = this.selectedFeatures.concat(features);
          }
        } catch (error) {
          console.log(error);
          const err = <Error>error;
          console.warn(`Отбор данных в ресурсе '${this.title}' не принес результатов (ошибка: ${err.message})`);
        }

        this.selectedFeatures.forEach((ft) => {
          ft.service = serviceModule;
        });
      }

      if (!(this.featureDescription && this.featureDescription.isLocked)) {
        for (const feature of this.selectedFeatures) {
          this.featureDescription.fillFromAtributes(feature.attributes, feature.service);
        }
      }

      this.setOperation("load");
      await this.loadAttributesForSelectedFeatures();

      if (this.selectedFeatures.length === 0) {
        this.setOperation("");
      } else {
        this.setOperation("ok");
      }

      // this.dbgSelectedFeaturesTxt += JSON.stringify(this.selectedFeatures, null, 2);
    } catch (ex) {
      this.setOperation("");
      console.log("Exception: ", ex);
    }
  }

  public async getPointFeatureInfo(coordinates: MapClickPoint, extentGeometry: any = null) {
    if (!coordinates.ctrlKey) {
      this.resetSelection();
    }

    await this.getFeatureInfo((serviceModule) => serviceModule.getPointFeatureInfo(coordinates));
    this.expandIfRequired();
  }

  public async getExtentFeatureInfo(extentEvt: any) {
    this.resetSelection();

    await this.getFeatureInfo((serviceModule) => serviceModule.getExtentFeatureInfo(extentEvt));
    this.expandIfRequired();
  }

  public expandIfRequired() {
    const maxFts = this.globalConfig.Environment.MaxFeaturesToCollapse;
    const expanded = maxFts == -1 || this.selectedFeatures.length <= maxFts;

    this.selectedFeatures.forEach((ft) => (ft.uiShowDetails = expanded));
  }

  public resetSelection() {
    this.selectedFeatures = [];
    this.selectionLayer.clear();

    this.setOperation("");
  }

  public applyFilter(operation: GEOMETRY_OPERATION = GEOMETRY_OPERATION.INTERSECT) {
    this.serviceModuleList.forEach((serviceModule) => serviceModule.setFilter(this.filter));

    if (this.filter.hasSpatialFilter) {
      // && !concreteService.IsSupportsSpatialFilter
      this.setSpatialMask([this.filter.spatialFilter], operation);
    } else {
      this.filterLayer.clear();
    }
  }

  async clearSpatialMask() {
    this.filterLayer.clear();
  }

  async setSpatialMask(spatialFilters: Array<FilterSpatial>, operation: GEOMETRY_OPERATION) {
    const [geometryEngine] = await this.esri.loadModules(["esri/geometry/geometryEngine"]);

    this.filterLayer.clear();

    /// Заливка белым цветом всей области карты
    const content = {
      type: "FeatureCollection",
      name: "planet",
      crs: {
        type: "name",
        properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
      },
      features: [
        {
          type: "Feature",
          properties: { id: 1 },
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [-180.0, 90.0],
                [180.0, 90.0],
                [180.0, -90.0],
                [-180.0, -90.0],
                [-180.0, 90.0],
              ],
            ],
          },
        },
      ],
    };

    await this.filterLayer.loadFeaturesFromGeoJSON(content, [255, 255, 255, 200]);

    const joinedGeometry = geometryEngine.union(spatialFilters.map((f) => f.geometry));
    await this.filterLayer.clipGeometryFromObjects(joinedGeometry, operation);
  }

  /// метод пока не доделан (нужен для #6240)
  async setSpatialMaskExtended(spatialFilters: Array<FilterSpatial>, operation: GEOMETRY_OPERATION) {
    const [geometryEngine, projection] = await this.esri.loadModules(["esri/geometry/geometryEngine", "esri/geometry/projection"]);

    this.filterLayer.clear();

    for await (const serviceModule of this.serviceModuleList) {
      try {
        const url = `${serviceModule.config.url}f=json`;
        const { fullExtent, spatialReference } = await this.web.httpGet<any>(url);
        console.log(fullExtent);
        console.log(spatialReference);

        if (fullExtent) {
        }
      } catch (err) {
        !environment.production && console.warn(`Не удалось извлечь полный экстент сервиса '${serviceModule.config.url}`);
      }
    }
    // const WGS84 = new SpatialReference({ wkid: 4326});
    // const NAD83 = new SpatialReference({ wkid: 26912 });

    // let point = new Point({
    //   spatialReference: WGS84,
    //   x: event.mapPoint.longitude,
    //   y: event.mapPoint.latitude
    // })
    // projection.load().then(function(){

    //   // const transformations = projection.getTransformations(WGS84, NAD83);
    //   // console.log(transformations);

    //   point = projection.project(point, NAD83);
    //   console.log(point);
    // });

    var xmin = 27276.172992028296;
    var ymin = 26839.72935899906;
    var xmax = 6673065.444939181;
    var ymax = 6673160.270641001;

    const mapWkt = this.ag.EsriMap.spatialReference.wkt;

    /// Заливка белым цветом всей области карты
    const content = {
      type: "FeatureCollection",
      name: "planet",
      crs: {
        type: "name",
        properties: {
          name: "custom_projection",
          custom: true,
          definition: mapWkt,
        },
      },
      features: [
        {
          type: "Feature",
          properties: { id: 1 },
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [xmin, ymax],
                [xmax, ymax],
                [xmax, ymin],
                [xmin, ymin],
                [xmin, ymax],
              ],
            ],
          },
        },
      ],
    };

    await this.filterLayer.loadFeaturesFromGeoJSON(content, [255, 255, 255, 200]);

    const joinedGeometry = geometryEngine.union(spatialFilters.map((f) => f.geometry));
    await this.filterLayer.clipGeometryFromObjects(joinedGeometry, operation);
  }

  async restrictView(spatialFilter: FilterSpatial) {
    const [geometryEngine, webMercatorUtils, Graphic] = await this.esri.loadModules([
      "esri/geometry/geometryEngine",
      "esri/geometry/webMercatorUtils",
      "esri/graphic",
    ]);

    const cutPolygon = spatialFilter.geometry;
    for await (const service of this.serviceModuleList) {
      // var esriLayers = await service.GetMapLayers();
      // console.log(esriLayers);
      // var esriLayer = esrila
      // console.log(esriLayers);
      // let gr = esriLayers.graphics[0];
      // let spatialRef = gr.geometry.spatialReference;
      // cutPolygon = webMercatorUtils.project(cutPolygon, spatialRef);
      // let clippedGeometry = geometryEngine.difference(gr.geometry, cutPolygon);
      // gr.geometry = clippedGeometry;
      // esriLayers.remove(gr);
      // el.add(gr);
      // esriLayers.refresh();
    }
  }

  public async selectByFilter(filter: ResourceFilter) {
    this.resetSelection();
    this.setOperation("");

    // Если слой не видимый, то поиск не выполняем
    if (!this._show) {
      return;
    }

    this.setOperation("search");

    try {
      for await (const serviceModule of this.serviceModuleList) {
        const [features, isError] = await serviceModule.getFeaturesByQuery(filter);

        if (features) {
          this.selectionLayer.addFeatures(features, this.config.featureDisplayAttrs);
          this.selectedFeatures = this.selectedFeatures.concat(features);
        }

        this.selectedFeatures.forEach((ft) => {
          ft.service = serviceModule;
        });
      }

      for (const feature of this.selectedFeatures) {
        this.featureDescription.fillFromAtributes(feature.attributes, feature.service);
      }

      this.setOperation("load");
      await this.loadAttributesForSelectedFeatures();

      this.setOperation(0 === this.selectedFeatures.length ? "" : "ok");
      // this.dbgSelectedFeaturesTxt += JSON.stringify(this.selectedFeatures, null, 2);
    } catch (error) {
      this.setOperation("");

      console.log("Ошибка: ", error);
    }

    this.onLayersChanged.emit();
  }

  private async loadAttributesForSelectedFeatures() {
    if (!this.selectedFeatures) {
      return;
    }

    /// Для каждого найденного объекта подгружаем атрибуты из всех определенных сервисов
    this.selectedFeatures
      .filter((f) => f && f.id)
      .map((feature) => {
        this.serviceModuleList.forEach(async (serviceModule) => {
          const attributes = await serviceModule.getAttributesByID(feature.id);

          if (attributes) {
            if (typeof attributes === "string") {
              feature.addHtmlResponse(serviceModule.getConfig().title, attributes);
            } else {
              feature.addAtributes(serviceModule.getConfig().title, attributes);
              this.featureDescription.fillFromAtributes(feature.attributes, serviceModule);
            }
          }
        });
      });
  }

  public setOpacity(visible: boolean) {}

  public setFilter(filter: any) {}

  public setStyle(filter: any) {}

  public highlightResourceFeature(featureId: any) {
    this.highlightGuid = featureId;
    this.selectionLayer.highlight(featureId);

    if (!featureId) {
      this.onFeatureHighlight.emit(featureId);
    }
  }

  equalsConfig(resourceConfig: ConfigResource) {
    // если бы была обеспечена уникальность и неизменяемость id в конфигурации
    // то сравнение свелось бы к "return this.config.id == resourceConfig.id"

    // "пустой" конфиг ничему не равен по определению
    if (!resourceConfig) {
      return false;
    }

    const config = this.config;

    // если число сервисов к конфигах различается, то они считаются разными
    if (config.servicesList.length !== resourceConfig.servicesList.length) {
      return false;
    }

    // предполагаем, что сервисы перечислены в одинаковом порядке в обоих ресурсах
    // и если хоть одна пара сравниваемая сервисов чем-то различается, то считаем ресурсы неодинаковыми
    for (let i = 0; i < config.servicesList.length; i++) {
      const here = config.servicesList[i];
      const there = resourceConfig.servicesList[i];

      const servicesAreEqual =
        here.type === there.type &&
        here.url === there.url &&
        (here.layers ?? []).sort().join() === (there.layers ?? []).sort().join() &&
        here.edit === there.edit &&
        here.proxy === there.proxy;

      if (!servicesAreEqual) {
        return false;
      }
    }

    // если нет отличающихся сервисов, то ресурсы считаются одинаковыми
    return true;
  }

  private setOperation(activeOperation: string) {
    this.searchStatus = activeOperation;
    this.activeOperations = activeOperation;
  }

  private updateState() {
    this.state = "";

    for (const service of this.serviceModuleList) {
      if (service.state !== "ready") {
        this.state = service.state;
      }
    }
  }

  isDummy() {
    return this.id.startsWith(DUMMY_RESOURCE_TOKEN);
  }

  isVector() {
    return this.serviceModuleList.length == 1 && this.serviceModuleList[0].config.type == "vector";
  }
}
