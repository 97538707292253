import { Component, Input, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from "@angular/core";
import { ConfigResource } from "@shared/config/config-resource";
import { UtilsProvider } from "@shared/providers/utils.provider";
import { WegaUtils } from "@shared/wega-utils/wega-utils";
import { EsriProvider } from "@wega-providers/esri.provider";
import { ResourceProvider } from "@wega-providers/resource.provider";
import { ArcGisMapProvider } from "src/app/modules/wega-ui/components/arcgis-map/providers/arcgis-map.provider";

type RasterDbResult = {
  key: string;
  title: string;
  type: string;
  source: any;
  state: any;
};

@Component({
  styles: [
    `
      .wega-raster-db-search-details {
      }

      .wega-raster-db-search-details-container {
      }

      .wega-raster-db-search-details-actions {
      }

      .wega-raster-db-search-details-item {
      }

      .wega-raster-db-search-details-title {
      }

      .wega-raster-db-search-details-type {
        display: block;
        font-size: 12px;
        font-weight: bold;
      }

      .wega-raster-db-search-details-action {
        border: none;
        border-radius: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        background: #f5f5dd;
        font-size: 12px;
        cursor: pointer;
        margin: 3px 10px 10px 0;
      }

      .wega-raster-db-search-details-action.back {
        font-size: 14px;
        background: wheat;
        margin: 0 0 10px 0;
      }

      .wega-raster-db-search-details-action:hover {
        background: gold;
      }
    `,
  ],
  selector: "wega-raster-db-search-details",
  template: `
    <div class="wega-raster-db-search-details-container">
      <button class="wega-raster-db-search-details-action back" (click)="back()" *ngIf="resultStack.length > 1">Вернуться назад</button>
      <ng-container *ngFor="let result of currentResult; let index = index">
        <div class="wega-raster-db-search-details-item">
          <span class="wega-raster-db-search-details-title">{{ index + 1 }}. {{ result.title }}</span>
          <span class="wega-raster-db-search-details-type">Тип материала: {{ result.type }}</span>
          <div class="wega-raster-db-search-details-actions">
            <ng-container *ngIf="result.type == 'doc'">
              <button class="wega-raster-db-search-details-action" (click)="openDocument(result)">Открыть документ</button>
            </ng-container>
            <ng-container *ngIf="result.type == 'zaramka'">
              <button class="wega-raster-db-search-details-action" (click)="openImage(result)">Открыть зарамочное оформление</button>
            </ng-container>
            <ng-container *ngIf="result.type == 'raster'">
              <button class="wega-raster-db-search-details-action" (click)="openRaster(result)">Открыть в WebMapGet</button>
              <button *ngIf="result.state == null" class="wega-raster-db-search-details-action" (click)="showRaster(result)">Включить на карте</button>
              <button *ngIf="result.state != null" class="wega-raster-db-search-details-action" (click)="hideRaster(result)">Отключить</button>
            </ng-container>
            <ng-container *ngIf="result.type == 'folder'">
              <button class="wega-raster-db-search-details-action read" (click)="read(result)" *ngIf="result.type == 'folder'">Раскрыть</button>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  `,
})
export class RasterDBSearchDetailsComponent implements OnInit {
  @Input() input: {
    result: Array<RasterDbResult>;
    getNodesFn: (string) => Promise<Array<RasterDbResult>>;
    transformResults: (result: Array<any>) => Array<RasterDbResult>;
  };

  constructor(public cdr: ChangeDetectorRef, public utils: UtilsProvider, public esri: EsriProvider, public arcgis: ArcGisMapProvider) {}

  ngOnInit(): void {
    this.resultStack.push(this.input.result);
    this.currentResult = this.input.result;
  }

  resultStack: Array<RasterDbResult>[] = [];
  currentResult: Array<RasterDbResult> = [];

  async read(result: RasterDbResult) {
    const resultNodes = await this.input.getNodesFn(result.key);

    this.currentResult = this.input.transformResults(resultNodes);
    this.resultStack.push(this.currentResult);

    this.cdr.detectChanges();
  }

  back() {
    this.resultStack = this.resultStack.splice(0, this.resultStack.length - 1);
    this.currentResult = this.resultStack[this.resultStack.length - 1];

    this.cdr.detectChanges();
  }

  openDocument(result: RasterDbResult) {
    this.openLink(result.source["URL"]);
  }

  openImage(result: RasterDbResult) {
    this.openLink(result.source["URL"]);
  }

  openRaster(result: RasterDbResult) {
    this.openLink(result.source["WMG_URL"]);
  }

  async showRaster(result: RasterDbResult) {
    const resourceId = WegaUtils.createGuid();

    const configResource = new ConfigResource({
      title: result.title,
      id: resourceId,
      service: [
        {
          type: "wms",
          id: WegaUtils.createGuid(),
          url: result.source["WMS_URL"],
          layers: [result.source["WMS_LAYER"]],
        },
      ],
    });

    const resourceProvider = window["CATALOGDB"].res as ResourceProvider; /// TODO: это плохое и ненадежное решение - по-хорошему надо бы переделать
    await resourceProvider.addResourceConfig(configResource);

    result.state = resourceId;
    this.cdr.detectChanges();
  }

  async hideRaster(result: RasterDbResult) {
    const resourceProvider = window["CATALOGDB"].res as ResourceProvider;
    const resourceId = result.state;
    const resource = resourceProvider.resourceList.find((r) => r.id == resourceId);

    await resourceProvider.removeResource(resource);

    result.state = null;
    this.cdr.detectChanges();
  }

  openLink(url: string) {
    const anchor = this.utils.browser.document.createElement("a");
    anchor.href = url;
    anchor.target = "_blank";
    anchor.style.display = "none";

    document.body.appendChild(anchor);
    anchor.click();
    anchor.remove();
  }
}
