import { Injectable } from "@angular/core";
import { WebClientProvider } from "./web-client.provider";
import { UtilsProvider } from "./utils.provider";
import { Params, Router } from "@angular/router";
import { WegaAppConfig } from "@shared/wega-utils/global-config";

export type WegaEnvironment = WegaAppConfig;

@Injectable({ providedIn: "root" })
export class AppConfigProvider {
  /** Код сайта по умолчанию. */
  public readonly mainRoute = "cdnr";

  /** Код подсайта, открытого в данный момент.  */
  private _currentRoute = this.mainRoute;
  public currentRoute = () => this._currentRoute;

  public Environment: WegaEnvironment = new WegaAppConfig();

  constructor(private web: WebClientProvider, private utils: UtilsProvider, private router: Router) {
    utils.setFrameworkModule("Config", {
      Environment: this.Environment,
    });
  }

  /** (1) Инициализация параметров конфигурации по умолчанию.
   * Вызывается через инжектор APP_INITIALIZER при загрузке AppModule.
   */
  async load(): Promise<void> {
    this.utils.setFrameworkModule("Store", {});

    console.log("%cЗагружены настройки по умолчанию", "color: blue");
  }

  /** (2) Загрузка конфига из init-[override, default].js ("var _w3config = ... "). */
  async loadOverride(siteCode: string): Promise<void> {
    const url = this.Environment.InitOverridePath;
    await this.utils.loadScript({ url });

    const windowKey = "_w3config";
    const configOverrideJS = this.utils.browser.window[windowKey];

    if (configOverrideJS) {
      this.Environment = { ...this.Environment, ...configOverrideJS };
    }

    console.log("%cЗагружены override-настройки", "color: blue");
  }

  /**
   * (3) Переопределение конфигурации через ng-роутер (см. route.resolver.ts).
   * Предполагается, что в методе loadOverride уже корректно переопределены InitJsonUrl и RootCatalogUrl.
   * В противном случае возможны непредвиденные побочные эффекты.  */
  async loadFromRoute(siteCode: string): Promise<void> {
    this._currentRoute = siteCode;

    // если через init-override были заданы строки в виде шаблонного литерала, то их надо "очистить"
    this.Environment.RootCatalogUrl = this.Environment.RootCatalogUrl.replace("{siteCode}", siteCode);
    this.Environment.InitJsonUrl = this.Environment.InitJsonUrl.replace("{siteCode}", siteCode);

    let configRouteJson = {};
    try {
      // подгрузка JSON-конфига и переопределение объекта Environment
      configRouteJson = await this.web.httpGet<{}>(this.Environment.InitJsonUrl);
    } catch {
      if (siteCode != this.mainRoute && siteCode != "local") {
        // если по какой-то причине запрошенный конфиг на wega-cat недоступен,
        // и это не тестовый локальный конфиг,
        // то сначала предполагаем, что задан несуществующий код подсайта
        // и пытаемся подгрузить конфиг из роута по умолчанию
        this.router.navigate([this.mainRoute]);
      } else {
        // если недоступен даже роут по умолчанию, то делаем fallback на локальный конфиг
        const localConfig = new WegaAppConfig();
        this.Environment.InitJsonUrl = localConfig.InitJsonUrl;
        this.Environment.RootCatalogUrl = localConfig.RootCatalogUrl; // сразу считаем, что и файл каталога на wega-cat неоступен

        configRouteJson = await this.web.httpGet<{}>(this.Environment.InitJsonUrl);
      }
    }

    this.Environment = { ...this.Environment, ...configRouteJson };
    console.log(`%cЗагружены настройки для сайта '${siteCode}'`, "color: blue");
    // кастомный RootCatalogUrl сам подгрузится в CatalogTreeComponent
  }

  /**
   * (4) Заключительное переопределение конфигурации через переменную _w3config_script.
   */
  async loadFromScript(): Promise<void> {
    const scriptKey = "_w3config_script";
    const configScriptJS = window[scriptKey];

    if (configScriptJS) {
      this.Environment = { ...this.Environment, ...configScriptJS };
      console.log("%cЗагружены настройки из скрипта", "color: blue");
    }
  }

  async loadFromUrlParams(queryParams: Params) {
    var googleCoords = queryParams["g"];
    var coordsString = queryParams["ll"];
    var zoomString = queryParams["z"];
    var layersString = queryParams["layers"];

    /// параметры начинающиеся с Map. Например map_GK2500=1
    /// такие параметры позволяют легко отслеживать в yandex.metrika конкретные карты
    var mapsStringsList = Object.keys(queryParams)
      .filter((k) => k.substr(0, 4) == "map_")
      .map((k) => k.substr(4));

    if (googleCoords) {
      var coordsArray = googleCoords.split(",");
      var x = parseFloat(coordsArray[1]);
      var y = parseFloat(coordsArray[0]);
      var z = Math.round(coordsArray[2]);
      this.Environment.Center = [x, y];
      this.Environment.Zoom = z;
    }

    if (coordsString) {
      var coordsArray = coordsString.split(",");
      var x = parseFloat(coordsArray[0]);
      var y = parseFloat(coordsArray[1]);
      this.Environment.Center = [x, y];
    }

    if (zoomString) {
      this.Environment.Zoom = Math.round(zoomString);
    }

    if (layersString) {
      var layersList = layersString.split(",");
      this.Environment.StartupMapIdList = layersList;
    }

    if (mapsStringsList) {
      const newMaps = mapsStringsList.filter((map) => !this.Environment.StartupMapIdList.includes(map));
      this.Environment.StartupMapIdList.push(...newMaps);
    }
  }

  async loadLocalConfig(code: string): Promise<void> {
    if (code == "local") {
      this.Environment = { ...this.Environment, ...new WegaAppConfig() };
      console.log(`%cЗагружены локальные тестовые настройки`, "color: blue");
    }
  }
}
