import { WegaCatalog } from "@domain/data/catalogs/wega-catalog";
import { CatalogNode } from "@wega3/components/catalog-tree/catalog-node";
import { UiProvider } from "@wega3/providers/ui.provider";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";
import { WegaSearchScanner } from "@domain/data/catalogs/wega-search";
import { ConfigResource } from "@shared/config/config-resource";
import { ConfigService } from "@shared/config/config-service";
import { FilterSpatial } from "@domain/data/filter/filter-spatial";
import { NodeIcon, ServiceType } from "@shared/wega-utils/wega-enums";

export class ESOCatalog implements WegaCatalog {
  title: string;
  baseUrl: string;

  private rootElements: CatalogNode[] = [];

  constructor(title: string, private url: string, public appConfig: AppConfigProvider, public web: WebClientProvider, public locale: LocaleProvider) {
    this.title = title;
    this.baseUrl = url;
    this.getEntries();
  }

  searchScanner: WegaSearchScanner;

  async getEntries(): Promise<CatalogNode[]> {
    if (!this.rootElements.length) {
      const res = await fetch(`./assets/tmp-eso/list.xml`, { cache: "no-store" });
      const text = await res.text();
      const parser = new DOMParser();
      const document = parser.parseFromString(text, "application/xml");
      const items = document.getElementsByTagName("list");

      Array.from(items).forEach((item) => {
        const id = item.getElementsByTagName("id")[0].innerHTML;

        if (id !== "323dee05139a49539e243d2f31ceaf6e") {
          // 'тестовый' справочник исключается
          const title = item.getElementsByTagName("title")[0].innerHTML.replace("Электронный справочник-определитель", "ЭСО");
          const node = new CatalogNode(this, this.appConfig, this.locale);

          node.id = id;
          node.title = title;
          node.setIcon(NodeIcon.Doc);
          node.expandable = false;

          const resource = new ConfigResource("");
          resource.title = node.title;
          resource.id = node.id;
          resource.origin = "https://vsegei.ru/ru/info/el_sprav/";

          resource.longTitle = node.title;
          node.resource = resource;

          const config = new ConfigService({});
          config.type = ServiceType.url;
          config.url = `http://esprav.vsegei.ru/?ruid=${node.id}`;
          config.visible = true;

          node.resource.servicesList.push(config);
          this.rootElements.push(node);
        }
      });
    }

    return this.rootElements;
  }

  getChildEntries(node: CatalogNode): Promise<CatalogNode[]> {
    throw new Error("Method not implemented.");
  }

  togglePage(goNextPage: boolean, node: CatalogNode): Promise<boolean> {
    throw new Error("Method not implemented.");
  }

  resetFilter() {
    throw new Error("Method not implemented.");
  }

  addTextFilter(searchText: string) {
    throw new Error("Method not implemented.");
  }

  addAttributeFilter(attribute: string, value: string) {
    throw new Error("Method not implemented.");
  }

  setSpatialFilter(spatialFilter: FilterSpatial) {
    throw new Error("Method not implemented.");
  }
}
