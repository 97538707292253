import { WegaSearchResult, StatefulSearchEngine } from "../../search.engine";
import { UtilsProvider } from "@shared/providers/utils.provider";
import { ArcGisMapProvider } from "src/app/modules/wega-ui/components/arcgis-map/providers/arcgis-map.provider";
import { EsriProvider } from "@wega-providers/esri.provider";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { UnifiedSearchDetailsComponent } from "./unified-data-search.details";

declare const ArcgisToGeojsonUtils: any;

type UnifiedSearchResult = {
  type: string;
  geometry: any;
  properties: any;
  mapName: string;
  layerName: string;
  featureName: string;
  serviceUrl: string;
  layerID: string;
  featureId: string;
  attributeName: string;
  attributeValue: string;
};

export class UnifiedApiSearchEngine extends StatefulSearchEngine {
  private apiUrl: string;
  constructor(
    private utils: UtilsProvider,
    private arcgis: ArcGisMapProvider,
    private esri: EsriProvider,
    private appConfig: AppConfigProvider,
    private locale: LocaleProvider,
    ...apiArguments: string[]
  ) {
    super();

    this.apiUrl = apiArguments[0];
  }

  templateInputKey = "input";
  template = () => UnifiedSearchDetailsComponent;

  get title(): string {
    return this.locale.current === "en" ? `Karpinsky Institute Unified Search API` : `Унифицированный поисковый веб-API Института Карпинского`;
  }

  get description(): string {
    return this.locale.current === "en"
      ? `Search using unified web-API (${this.apiUrl})`
      : `Поиск через унифицированный поисковый веб-API Института Карпинского (${this.apiUrl})`;
  }

  async engineSearch(text: string): Promise<WegaSearchResult[]> {
    let json = null;
    try {
      const url = `${this.apiUrl}/api/search?q=${encodeURIComponent(text)}`;
      const response = await fetch(`${this.apiUrl}/api/search?q=${encodeURIComponent(text)}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      json = await response.json();
      console.log(json);
    } catch (err) {
      if (err instanceof Error) {
        this.signal(`Не удалось получить данные от веб-сервиса SPIKA по запросу ${this.apiUrl}! Ошибка: ${err.message}`);
      } else {
        this.signal(`Неизвестная ошибка при попытке получения данных от веб-сервиса SPIKA по адресу ${this.apiUrl}!`);
      }

      return [];
    }

    return (json ?? []).map((result: UnifiedSearchResult, index: number) => {
      return {
        cancelHighlight: true,
        name: this.locale.current === "en" ? `Object ${index + 1}: ${result.featureName}` : `Объект ${index + 1}: ${result.featureName}`,
        description: this.locale.current === "en" ? `ID of the found object: ${result.featureId}` : `Идентификатор найденного объекта: ${result.featureId}`,

        source:
          this.locale.current === "en"
            ? `Map '${result.mapName}' (${result.serviceUrl}), layer '${result.layerName}'.`
            : `Карта '${result.mapName}' (${result.serviceUrl}), слой '${result.layerName}'.`,

        zoom: async () => {
          return new Promise(async (resolve) => {
            const source = result.geometry;
            const type = source.type;
            const geometry = ArcgisToGeojsonUtils.geojsonToArcGIS(result.geometry);
            const [Polygon, Point] = await this.esri.loadModules(["esri/geometry/Polygon", "esri/geometry/Point"]);

            if (type == "Point") {
              const point = new Point(geometry);
              this.arcgis.EsriMap.centerAt(point);

              const marker = await this.esri.putMarker(point, this.arcgis.EsriMap);
              await this.esri.clearMarker(marker, this.arcgis.EsriMap);

              resolve(true);
            }

            if (type == "Polygon") {
              const polygon = new Polygon(geometry);
              const polygonExtent = polygon.getExtent();

              this.arcgis.EsriMap.setExtent(polygonExtent);
              await this.esri.putMarker(polygonExtent.getCenter(), this.arcgis.EsriMap);

              resolve(true);
            }

            resolve(false);
          });
        },

        templateInput: {
          properties: result.properties,
        },
      };
    });
  }
}
