<div mat-dialog-content class="profile-dialog">
  <div class="profile-authoriry">
    <h1 mat-dialog-title class="profile-title">Учетная запись</h1>

    <span class="profile-not-logged profile-text" *ngIf="!auth.AuthInfo.Authenticated">
      Анонимный пользователь.
      <br />
      Временный идентификатор: {{userId}}
    </span>

    <span class="profile-logged profile-text" *ngIf="auth.AuthInfo.Authenticated">
      <mat-icon class="profile-logged-icon">verified_user</mat-icon>
      Пользователь: {{auth.AuthInfo.User}}
    </span>

    <ng-container *ngIf="!canLogin; else login">
      <div class="profile-safe">
        <span class="profile-not-safe">При работе через http аутентификация недоступна.</span>
        <button class="profile-switch-safe profile-button" (click)="forceHttps()">Перейти на https-версию веб-приложения</button>
      </div>
    </ng-container>
    <ng-template #login>
      <button class="profile-login profile-button" *ngIf="!auth.AuthInfo.Authenticated" (click)="onLogin()">Войти через учетную запись Института Карпинского</button>
      <button class="profile-logout profile-button" *ngIf="auth.AuthInfo.Authenticated" (click)="onLogout()">Выйти из учетной записи</button>
    </ng-template>
  </div>

  <wega-profile-state>

  </wega-profile-state>

  <div mat-dialog-actions class="profile-actions">
      <button mat-button (click)="cancel()" class="close-profile">Закрыть</button>
  </div>
</div>
