import { UiProvider } from "@wega3/providers/ui.provider";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { CatalogNode } from "@wega3/components/catalog-tree/catalog-node";
import { ConfigResource } from "@shared/config/config-resource";
import { ConfigService } from "@shared/config/config-service";
import { FilterSpatial } from "../filter/filter-spatial";
import { WegaCatalog } from "./wega-catalog";
import { WegaSearchScanner, DefaultSearchScanner } from "./wega-search";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";
import { NodeIcon, ServiceType } from "@shared/wega-utils/wega-enums";

type GisPackage = {
  key: number;
  label: string;
  type: string;
  address: Array<string>;
  children: Array<GisPackage>;
};

export class GisPackageCatalog implements WegaCatalog {
  title: string;
  baseurl: string;

  private rootElements: CatalogNode[] = [];
  private packages: Array<GisPackage> = [];

  constructor(title: string, baseUrl: string, public appConfig: AppConfigProvider, public web: WebClientProvider, public locale: LocaleProvider) {
    this.title = title;
    this.baseurl = baseUrl;

    this.getEntries();
  }

  searchScanner: WegaSearchScanner = {
    search: (catalog: WegaCatalog, text: string) => Promise.resolve([]),
  };

  togglePage(goNextPage: boolean, pNode: CatalogNode): Promise<boolean> {
    throw new Error("Method not implemented.");
  }

  async getEntries(): Promise<CatalogNode[]> {
    if (!this.rootElements.length) {
      const pss = await this.web.httpGet([this.baseurl, "assets/config/documents.json"].join("/"));

      this.packages = pss["packets"] as Array<GisPackage>;

      if (this.rootElements.length === 0) {
        this.rootElements = this.packages.map((p) => {
          const node = new CatalogNode(this, this.appConfig, this.locale);

          node.id = ["n-", p.key].join("");
          node.title = p.label;

          return node;
        });
      }
    }

    return this.rootElements;
  }

  async getChildEntries(parent: CatalogNode): Promise<CatalogNode[]> {
    const pid = parent.id;

    const find = (id: string, pis: GisPackage[]): GisPackage[] => {
      const pp = pis.filter((p) => id === ["n-", p.key].join(""));

      if (!!pp.length) {
        return pp[0].children;
      }

      let found;
      pis.forEach((pi) => {
        if (!found && pi.children) {
          found = find(id, pi.children);
        }
      });

      return found;
    };

    const children = find(pid, this.packages);
    if (!children) {
      return [];
    }
    const nns = children.map((c) => {
      const node = new CatalogNode(this, this.appConfig, this.locale);

      node.id = ["n-", c.key].join("");
      node.title = c.label;

      if (c.type == "leaf") {
        node.setIcon(NodeIcon.Doc);
        node.expandable = false;

        const res = new ConfigResource("");
        res.title = node.title;
        res.id = node.id;
        res.origin =
          this.locale.current === "ru"
            ? "ГИС-Пакеты оперативной геологической информации (ГИС-Атлас «Недра России»)"
            : "GIS Packages of operational geological information";

        res.longTitle = parent.title + " - " + node.title;

        node.resource = res;

        const cfg = new ConfigService({});
        cfg.type = ServiceType.url;
        cfg.url = [this.baseurl, c.address].join("/");
        cfg.visible = true;

        node.resource.servicesList.push(cfg);
      }

      return node;
    });

    return nns;
  }

  resetFilter() {
    throw new Error("Method not implemented.");
  }

  addTextFilter(pSearchText: string) {
    throw new Error("Method not implemented.");
  }

  addAttributeFilter(pAttribute: string, pValue: string) {
    throw new Error("Method not implemented.");
  }

  setSpatialFilter(pSpatFilter: FilterSpatial) {
    throw new Error("Method not implemented.");
  }
}
