<div class="profile-state">
  <ng-container *ngIf="{
    exists: exists(),
    slot: slot(),
    current: current(),
    stateExists: stateExists(),
    total: total(),
    target: target()
  } as stateInfo">
    <h1 mat-dialog-title class="profile-title">Сохраненные состояния</h1>

    <span class="profile-state-not-exists profile-text" *ngIf="!stateInfo.exists">
      Сохраненных состояний не обнаружено.
    </span>

    <span class="profile-state-exists profile-text" *ngIf="stateInfo.exists">
      Обнаружены сохраненные состояния (всего: <b style="color: blue;">{{stateInfo.total}}</b>) для подсайта <b style="color: blue;">{{stateInfo.target}}</b>
      <br />(Слот {{stateInfo.slot + 1}}: {{stateInfo.stateExists ? 'хранит состояние от ' + timeString(stateInfo.current) : 'не используется'}})
      <span class="profile-state-exists-info" *ngIf="stateInfo.stateExists" [matTooltip]="aboutState(stateInfo.current)" matTooltipClass="profile-state-exists-tooltip">
        ?
      </span>
    </span>

    <div class="profile-state-slots">
      <button *ngFor="let _ of storage.states; let index = index" [ngClass]="{
        'profile-state-slot': true,
        'selected': index==stateInfo.slot
      }" (click)="changeSlot(index)">
        {{index + 1}}
      </button>
    </div>

    <button class="profile-state-load profile-button" [disabled]="!stateInfo.exists" (click)="load()">Загрузить сохраненное состояние</button>
    <button class="profile-state-save profile-button" (click)="save()">Сохранить текущее состояние</button>
    <button class="profile-state-clear profile-button" [disabled]="!stateInfo.exists" (click)="clear()">Удалить сохраненное состояние</button>
  </ng-container>

  <button class="profile-state-text profile-button" *ngIf="!showTextState" (click)="switchTextState()">Текущее состояние</button>
  <ng-container *ngIf="showTextState">
    <textarea #stateText matInput class="profile-state-text-area" [value]="textState"></textarea>
    <button class="profile-state-text-copy" (click)="copyStateText()">Копировать текст в буфер обмена</button>
    <button class="profile-state-text-load" (click)="loadStateFromText()">Загрузить состояние из текста</button>
    <button class="profile-state-text-json-save" (click)="saveStateTextToJSON()">Сохранить в файл JSON</button>

    <input #jsonState type="file" id="profile-state-upload" multiple accept=".json">
    <button class="profile-state-text-json-load" (click)="loadStateTextFromJSON()">Загрузить из файла JSON</button>

  </ng-container>
</div>
