import { Injectable } from "@angular/core";
import { Subject, from, BehaviorSubject } from "rxjs";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { LocaleStrings, LocaleLanguage } from "../common";
import { AppConfigProvider } from "@shared/providers/config.provider";

@Injectable()
export class LocaleProvider {
  private localeStrings: LocaleStrings;

  currentLanguage$: BehaviorSubject<LocaleLanguage> = new BehaviorSubject("ru");
  localeStrings$: Subject<LocaleStrings> = new Subject<LocaleStrings>();

  constructor(
    private appConfig: AppConfigProvider,
    private webClient: WebClientProvider
  ) {
    this.load();
  }

  load() {
    from(this.webClient.httpGet<any>("./assets/l10n/wega-ui.json")).subscribe(
      (v) => {
        this.currentLanguage$.next(
          <LocaleLanguage>this.appConfig.Environment.DefaultLanguage
        );

        this.localeStrings = v;
        this.localeStrings$.next(this.localeStrings);
      }
    );
  }

  switch() {
    return this.switchTo(
      this.currentLanguage$.getValue() === "en" ? "ru" : "en"
    );
  }

  switchTo(lang: LocaleLanguage) {
    this.currentLanguage$.next(lang);
    this.localeStrings$.next(this.localeStrings);

    return lang;
  }

  string(label: string) {
    return this.localeStrings[this.current][label];
  }

  get current() {
    return this.currentLanguage$.getValue();
  }
}
