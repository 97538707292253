import { Component, OnInit, Inject } from "@angular/core";

@Component({
  selector: "wega-logout-form",
  template: "",
})
export class WegaLogoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
