import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { UtilsProvider } from "@shared/providers/utils.provider";

@Injectable({ providedIn: "root" })
export class RouteConfigResolver implements Resolve<void> {
  constructor(private utils: UtilsProvider, private appConfig: AppConfigProvider) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<void> {
    let code = route.paramMap.get("siteCode") ?? this.appConfig.mainRoute;

    /// для обратной совместимости
    code == "gisatlas" && (code = this.appConfig.mainRoute);

    await this.appConfig.loadOverride(code);
    await this.appConfig.loadFromRoute(code);
    await this.appConfig.loadFromScript();
    await this.appConfig.loadFromUrlParams(route.queryParams);

    await this.appConfig.loadLocalConfig(code);

    await this.loadAssets();
  }

  loadAssets(): Promise<void> {
    (this.appConfig.Environment.ArbitraryJs ?? []).forEach((scriptText) => {
      this.utils.loadScript({ scriptText });
    });

    (this.appConfig.Environment.ArbitraryCss ?? []).forEach((styleText) => {
      this.utils.loadStyle({ styleText });
    });

    return;
  }
}
