import { Injectable } from "@angular/core";
import { UserState } from "@domain/data/structures/user-state";
import { GeoExtent } from "@domain/data/structures/extent";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { UtilsProvider } from "@shared/providers/utils.provider";
import { Observable, of, Subject, BehaviorSubject } from "rxjs";

type StorageData = {
  states: UserState[];
  transientState: UserState;
};

@Injectable()
export class StorageProvider {
  readonly slotsCount = 5;
  private _currentSlot = 0;
  private readonly _stateKey = "wega3-state-v2";

  private data: StorageData;

  public keys = {};

  _clear() {
    this.data = {
      states: [],
      transientState: new UserState(),
    };

    this._fillStates([]);
  }

  _fillStates(states: UserState[]) {
    for (const i of [...Array(this.slotsCount)].keys()) {
      states[i] || states.push(new UserState());
    }

    this.data.states = states;
  }

  constructor(private appConfig: AppConfigProvider, private utils: UtilsProvider) {
    this._clear();
    this._loadStates();
  }

  stateInSlot(index: number) {
    return this.data.states[index];
  }

  set slot(value: number) {
    this._currentSlot = value;
  }

  get states() {
    return this.data.states;
  }

  stateInSlotExists(index: number) {
    return -1 !== this.stateInSlot(index).timeStamp;
  }

  get currentSlotState() {
    return this.stateInSlot(this._currentSlot);
  }

  _loadStates() {
    const states = this.read<UserState[]>(this.stateKey) ?? [];

    this._fillStates(states);
  }

  get stateExists() {
    return 0 !== this.stateCount;
  }

  get recentState() {
    return this.data.states.map((s) => s).sort((a, b) => b.timeStamp - a.timeStamp)[0];
  }

  get stateCount() {
    return this.data.states.filter((s) => -1 !== s.timeStamp).length;
  }

  get currentSlot() {
    return this._currentSlot;
  }

  saveState() {
    this.data.states[this.currentSlot] = JSON.parse(JSON.stringify(this.data.transientState));
    this.write(this.stateKey, this.data.states);
  }

  private get stateKey() {
    const siteID: string = this.appConfig.currentRoute();

    return siteID === this.appConfig.mainRoute ? this._stateKey : `${this._stateKey}-${siteID}`;
  }

  get siteTarget() {
    return this.appConfig.currentRoute();
  }

  register(changeFn: (state: UserState) => void) {
    changeFn(this.data.transientState);

    this.data.transientState.timeStamp = Date.now();
  }

  get transientState() {
    return this.data.transientState;
  }

  clearState() {
    this.data.states[this.currentSlot] = new UserState();
    this.write(this.stateKey, this.data.states);
  }

  clearAll() {
    this._storage.removeItem(this.stateKey);
    this._clear();
  }

  write(key: string, _: any): void {
    this._storage.removeItem(key);
    this._storage.setItem(key, JSON.stringify(_));
  }

  read<TObject>(key: string): TObject {
    return JSON.parse(this._storage.getItem(key)) as TObject;
  }

  remove(key: string): void {
    this._storage.removeItem(key);
  }

  private get _storage() {
    return this.utils.browser.storage;
  }
}
