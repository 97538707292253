import { RasterDBCatalog } from "./rasterdb-catalog";
import { ArcGisCatalog } from "./arcgis-catalog";
import { CatalogNode } from "src/app/modules/wega3/components/catalog-tree/catalog-node";
import { FilterSpatial } from "../filter/filter-spatial";
import { GisPackageCatalog } from "./gispackage-catalog";
import { UiProvider } from "@wega3/providers/ui.provider";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { ConfigResource } from "@shared/config/config-resource";
import { WegaCatalog } from "./wega-catalog";
import { WegaSearchScanner, DefaultSearchScanner } from "./wega-search";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";
import { ESOCatalog } from "./eso-catalog";
import { TemplateCatalog } from "./template-catalog";
import { DomSanitizer } from "@angular/platform-browser";
import { NodeIcon } from "@shared/wega-utils/wega-enums";
import { WegaUtils } from "@shared/wega-utils/wega-utils";

export class FileCatalog implements WegaCatalog {
  constructor(
    title: string,
    fileUrl: string,
    public web: WebClientProvider,
    public appConfig: AppConfigProvider,
    public sanitizer: DomSanitizer,
    fileContent: any,
    private locale: LocaleProvider
  ) {
    this.title = title;
    this.fileUrl = fileUrl;

    if (!!fileContent) {
      this.configsList = fileContent;
    }

    if (!!this.fileUrl) {
      this.load();
    }
  }

  title: string;

  private fileUrl: string;
  private configsList: any;

  searchScanner: WegaSearchScanner = new DefaultSearchScanner();

  togglePage(goNextPage: boolean, node: CatalogNode): Promise<boolean> {
    throw new Error("Method not implemented.");
  }

  async getEntries(): Promise<CatalogNode[]> {
    await this.load();
    // console.log(this.nodesList);

    return await this.getNodesFromConfig([]);
  }

  async getChildEntries(parentNode: CatalogNode): Promise<CatalogNode[]> {
    const subIndexes = <number[]>parentNode.catalogNodeDescr;
    const childNodesList = await this.getNodesFromConfig(subIndexes ?? []);

    return childNodesList;
  }

  resetFilter() {}

  addTextFilter(searchText: string) {}

  addAttributeFilter(attribute: string, value: string) {}

  setSpatialFilter(spatialFilter: FilterSpatial) {}

  async load() {
    if (this.configsList) {
      return;
    }

    this.configsList = await this.web.httpGet(this.fileUrl, {
      queryParams: { nocache: WegaUtils.createGuid() },
    });

    // let fileContent = await fetch(this.fileUrl + `?nocache=${WegaTools.newGuid()}`);
    // this.configsList = await fileContent.json();
  }

  async getNodesFromConfig(subIndexes: number[]): Promise<CatalogNode[]> {
    let tmpConfig = this.configsList;
    /// Проход по индексам.
    for (const index of subIndexes) {
      tmpConfig = tmpConfig[index]["children"];
    }

    const nodesList: CatalogNode[] = [];

    if (!tmpConfig) {
      return nodesList;
    }
    for (let i = 0; i < tmpConfig.length; i++) {
      const rsrConf = tmpConfig[i];
      const newNode = await this.getNewNodeFromJson(rsrConf, subIndexes.concat(i));

      if (newNode) {
        nodesList.push(newNode);
      }
    }

    return nodesList;
  }

  async getNewNodeFromJson(rsrConf: any, subndex: number[]): Promise<CatalogNode> {
    let newNode = new CatalogNode(this, this.appConfig, this.locale);

    newNode.expandable = false;
    newNode.catalogNodeDescr = subndex;
    newNode.id = "file" + newNode.catalogNodeDescr.toString();
    newNode.title = rsrConf["title"];

    if (rsrConf["module"] != null && rsrConf["url"] != null) {
      const newCatalogClass = await this.getCatalogClass(rsrConf["module"], rsrConf["title"], rsrConf["url"]);

      newNode = new CatalogNode(newCatalogClass, this.appConfig, this.locale);
      newNode.expandable = true;
      newNode.isModuleNode = true;

      // если раскомментировать следующую строчку, то перестают работать
      // RasterDBCatalog и GisPackageCatalog, если они расположены не в корне дерева
      // newNode.id = 'module_' + subndex;

      newNode.setIcon(NodeIcon.Folder);
    }

    if (rsrConf["children"] != null) {
      newNode.expandable = true;
      newNode.setIcon(NodeIcon.Folder);
    }

    if (rsrConf["service"] != null) {
      const newConfig = new ConfigResource(rsrConf);

      newNode.resource = newConfig;
      newNode.id = newConfig.id;
      newNode.setIcon(NodeIcon.Map);
    }

    const hidden = -1 !== this.appConfig.Environment.HiddenCatalog.indexOf(rsrConf["id"]);
    return hidden ? void 0 : newNode;
  }

  async getCatalogClass(catalogType: string, title: string, url: string): Promise<WegaCatalog> {
    if (-1 !== this.appConfig.Environment.HiddenCatalog.indexOf(url)) {
      return null;
    }

    switch (catalogType) {
      case "FileCatalog":
        return new FileCatalog(title, url, this.web, this.appConfig, this.sanitizer, null, this.locale);

      case "RasterDBCatalog":
        return new RasterDBCatalog(title, url, this.appConfig, this.locale);

      case "ArcGisCatalog":
        return new ArcGisCatalog(title, url, this.appConfig, this.web, this.locale);

      case "GisPackageCatalog":
        return new GisPackageCatalog(title, url, this.appConfig, this.web, this.locale);

      case "ESOCatalog":
        return new ESOCatalog(title, url, this.appConfig, this.web, this.locale);

      case "TemplateCatalog":
        return new TemplateCatalog(title, this.appConfig, this.web, this.locale, this.sanitizer, url);

      case "DynamicCatalog":
        return new Promise((resolve) => {
          import(`${url}`).then((ctor) => {
            resolve(new ctor(url, this.appConfig, this.web, this.locale));
          });
        });

      default:
        return null;
    }
  }
}
