import { ConfigService } from "@shared/config/config-service";
import { FilterRecord } from "../filter/filter-record";

export class LegendRecord {
  public condition: FilterRecord[] = [];
  public imageData64url: string;

  sourceLabel: string;
  displayLabel: string;
  symbol: any;

  injectAsHtml: boolean;
  hidden: boolean;

  snapshot: string;
  active: boolean;
  empty: boolean;

  public values: string[] = [];

  setLabel(label: any, additions: any) {
    this.sourceLabel = label;
    this.displayLabel = `${additions.prefix}${this.sourceLabel}${additions.postfix}`;
  }

  async setSymbol(symbol: any) {
    this.symbol = symbol;

    this.imageData64url = await this.buildPNG(symbol);
  }

  async setImgSymbol(dataUrl: any) {
    this.imageData64url = dataUrl;
  }

  async buildPNG(symbol: any): Promise<string> {
    var canvas = document.createElement("canvas");

    canvas.width = 20;
    canvas.height = 20;

    var ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

    switch (symbol.type) {
      case "esriPMS":
      case "picturemarkersymbol":
        return "data:" + symbol.contentType + ";base64," + symbol.imageData;

      case "esriSFS":
      case "simplefillsymbol":
        {
          ctx.fillStyle = "rgba(" + this.getColor(symbol) + ")";

          if (symbol.outline) {
            ctx.strokeStyle = "rgba(" + symbol.outline.color.toString() + ")";
          }

          ctx.lineWidth = this.getWidth(symbol);

          let offset = ctx.lineWidth + 2;
          let x1 = offset;
          let x2 = canvas.width - 2 * offset;
          let y1 = offset;
          let y2 = canvas.height - 2 * offset;

          ctx.fillRect(x1, y1, x2, y2);
          ctx.strokeRect(x1, y1, x2, y2);
        }

        break;

      case "esriSLS":
      case "simplelinesymbol":
        {
          ctx.fillStyle = "rgba(" + this.getColor(symbol) + ")";
          ctx.strokeStyle = "rgba(" + this.getColor(symbol) + ")";
          ctx.lineWidth = symbol.width;

          let offset = ctx.lineWidth + 2;
          let x1 = offset;
          let x2 = canvas.width - 2 * offset;
          let y1 = offset;
          let y2 = canvas.height - 2 * offset;

          ctx.beginPath();
          ctx.moveTo(x1, y2);
          ctx.lineTo(x2, y1);
          ctx.stroke();
        }

        break;

      case "esriSMS":
      case "simplemarkersymbol":
        {
          ctx.fillStyle = "rgba(" + this.getColor(symbol) + ")";
          if (symbol.outline) {
            ctx.strokeStyle = "rgba(" + symbol.outline.color.toString() + ")";
          }

          ctx.lineWidth = symbol.width || 1;

          let x = canvas.width / 2;
          let y = canvas.height / 2;

          ctx.beginPath();
          ctx.arc(x, y, symbol.size, 0, 2 * Math.PI);
          ctx.fill();
          ctx.stroke();
        }

        break;

      case "esriPFS":
      case "picturefillsymbols":
        {
          var img = new window.Image();
          var waitImageCreate = new Promise((resolve, reject) => {
            img.onload = () => resolve(img);
            img.onerror = reject;
          });
          var strDataURI = "data:" + symbol.contentType + ";base64," + symbol.imageData;
          img.setAttribute("src", strDataURI);
          await waitImageCreate;
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0, img.width, img.height);
        }
        break;

      default:
        {
          ctx.fillStyle = "rgba(" + this.getColor(symbol) + ")";
          ctx.strokeStyle = "rgba(" + this.getColor(symbol) + ")";
          ctx.lineWidth = symbol.width || 1;

          let x = canvas.width / 2;
          let y = canvas.height / 2;

          ctx.beginPath();
          ctx.arc(x, y, 6, 0, 2 * Math.PI);
          ctx.fill();
          ctx.stroke();
        }

        break;
    }

    let answer = ctx.canvas.toDataURL("image/png");
    return answer;
  }

  getWidth(symbol: any) {
    return !!symbol.outline ? symbol.outline.width : "1";
  }

  getColor(symbol: any) {
    return !!symbol.color ? symbol.color.toString() : "-1,-1,-1";
  }

  public getImage() {}

  static async fromArcGISRender(
    config: ConfigService,
    layerId: string,
    record: any,
    fieldsList: string[],
    fieldDelimiter: string = ","
  ): Promise<LegendRecord> {
    let legendRecord = new LegendRecord();
    let valuesList = record.value ? (record.value as string).split(fieldDelimiter) : [];

    for (let i = 0; i < fieldsList.length; i++) {
      let fieldName = fieldsList[i];
      let fieldValue = valuesList[i];
      let condition = fieldValue == "<Null>" ? new FilterRecord(fieldName, "", "null") : new FilterRecord(fieldName, fieldValue, "=");

      legendRecord.condition.push(condition);
    }

    const additions = config.addTextToLegendElements.find((l) => l.name == layerId.toString()) ?? { prefix: "", postfix: "" };
    // legendRecord.setLabel(`${additions.prefix}${record.label}${additions.postfix}`);
    legendRecord.setLabel(record.label, additions);

    await legendRecord.setSymbol(record.symbol);

    legendRecord.snapshot = JSON.stringify(legendRecord.symbol);
    return legendRecord;
  }

  static async fromDataURL(
    layerId: string,
    config: ConfigService,
    label: string,
    values: string[],
    dataUrl: string,
    fieldsList: string[],
    fieldDelimiter: string = ","
  ): Promise<LegendRecord> {
    let legendRecord = new LegendRecord();

    const additions = config.addTextToLegendElements.find((l) => l.name == layerId.toString()) ?? { prefix: "", postfix: "" };
    legendRecord.setLabel(label, additions);
    await legendRecord.setImgSymbol(dataUrl);

    legendRecord.values = values;
    return legendRecord;
  }

  addCondition(pField: string, pValue: string) {}
}
