import { WegaUtils } from "@shared/wega-utils/wega-utils";
import { StorageProvider } from "@wega-providers/storage.provider";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";
import { WegaSearchResult, StatefulSearchEngine } from "../../search.engine";

export class LLMSearchEngine extends StatefulSearchEngine {
  private apiUrl: string;
  constructor(private storage: StorageProvider, private locale: LocaleProvider, ...apiArguments: string[]) {
    super();

    this.apiUrl = apiArguments[0];
  }

  private user = WegaUtils.createGuid();
  private chat = Array<{ question: string; answer: string }>();

  get title(): string {
    return this.locale.current === "en" ? `Search using LLM of Karpinsky Institute ` : `Поиск через языковую модель Института Карпинского`;
  }

  get description(): string {
    return this.locale.current === "en" ? `Search using LLM` : `Запрос к языковой модели`;
  }

  async engineSearch(text: string): Promise<WegaSearchResult[]> {
    let data = null;
    try {
      const url = `${this.apiUrl}/llm`;
      const res = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: this.user,
          model: "saiga_mistral_7b",
          embModel: "bge-m3",
          systemPrompt: null,
          input: text,
          augmentedRetrievalOptions: [],
          retrievalResultsNumber: 0,
          temperature: 0.2,
          topK: 40,
          topP: 0.9,
          maxLength: null,
          numBeams: null,
          seed: null,
          repetitionPenalty: 1.1,
        }),
      });
      data = await res.json();
    } catch (err) {
      if (err instanceof Error) {
        this.signal(`Не удалось получить данные от LLM! Ошибка: ${err.message}`);
      } else {
        this.signal(`Неизвестная ошибка при попытке получения данных от LLM!`);
      }

      return [];
    }

    this.chat.push({ question: text, answer: data.response });

    return this.chat
      .map((result) => {
        return {
          cancelHighlight: true,
          name: result.answer,
          description: `Ваш вопрос был: ${result.question}`,
        };
      })
      .reverse() as WegaSearchResult[];
  }
}
