import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { RouterModule } from "@angular/router";
import { MetrikaModule } from "ng-yandex-metrika";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutes, AppProviders } from "./app.deps";
import { environment } from "src/environments/environment";
import { WegaProvidersModule } from "./modules/wega-providers/wega-providers.module";
import { WegaDomainModule, DOMAIN_INFO } from "./modules/wega-domain/wega-domain.module";
import { WegaAuthenticationModule } from "./modules/authentication/authentication.module";

@NgModule({
  declarations: [AppComponent],

  providers: AppProviders,

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    WegaProvidersModule,
    WegaDomainModule.forRoot(DOMAIN_INFO.DEFAULT),

    RouterModule.forRoot(AppRoutes, {
      useHash: false,
      enableTracing: false,
    }),

    WegaAuthenticationModule,

    MetrikaModule.forRoot({
      id: 48964991,
      trackHash: true,
    }),
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
