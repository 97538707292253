import { Injectable } from "@angular/core";
import { Metrika } from "ng-yandex-metrika";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class UtilsProvider {
  constructor(private yaMetrica: Metrika) {
    this.setFrameworkModule("Utils", {
      showMessage: (message) => this.notify(message),
    });
  }

  public reportFeatureUse(featureName: string) {
    this.yaMetrica.fireEvent("featureUse_" + featureName);
  }

  get browser() {
    return { window, document, storage: window["localStorage"], global: (key) => window[key] as any };
  }

  get debug() {
    return {
      freeze: (_) => console.log(JSON.parse(JSON.stringify(_))),
    };
  }

  get origin() {
    return {
      safeOrigin: `https://${window.location.host}${window.location.pathname}`,
      unsafeOrigin: `http://${window.location.host}${window.location.pathname}`,
      isSafe: window.location.protocol === "https:",
    };
  }

  public toCurrentOrigin(url: string) {
    return this.origin.isSafe ? url.replace("https", "http").replace("http", "https") : url.replace("https", "http");
  }

  private static _frameworkModule = "WEGA";

  public async loadScript(data: { url: string } | { scriptText: string }): Promise<void> {
    const document = this.browser.document;
    const body = <HTMLDivElement>document.body;
    const isUrl = (d): d is { url: string } => !!d.url;

    return new Promise((resolve) => {
      if (isUrl(data)) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.innerHTML = "";
        script.src = data.url;
        script.async = true;
        script.onload = () => resolve();
        body.appendChild(script);
      } else {
        const script = document.createElement("script");
        script.innerHTML = data.scriptText;
        script.onload = () => resolve();
        body.appendChild(script);
      }
    });
  }

  public async loadStyle(data: { url: string } | { styleText: string }): Promise<void> {
    const document = this.browser.document;
    const body = <HTMLDivElement>document.body;
    const isUrl = (d): d is { url: string } => !!d.url;

    return new Promise((resolve) => {
      if (isUrl(data)) {
        const link = document.createElement("link");
        link.type = "text/css";
        link.href = data.url;
        link.rel = "stylesheet";
        link.onload = () => resolve();
        body.appendChild(link);
      } else {
        const style = document.createElement("style");
        style.innerHTML = data.styleText;
        style.onload = () => resolve();
        body.appendChild(style);
      }
    });
  }

  public setFrameworkModule(moduleName: string, module: any) {
    const window = this.browser.window;
    const modulePath = moduleName.split(".");

    window[UtilsProvider._frameworkModule] = window[UtilsProvider._frameworkModule] || {};
    let root = window[UtilsProvider._frameworkModule];

    modulePath.forEach((m, i) => {
      if (!root[m]) {
        root[m] = {};
      }

      if (i === modulePath.length - 1) {
        root[m] = module;
        !environment.production && console.info(`Компонент ${moduleName} зарегистрирован во внешнем API`);
      }

      root = root[m];
    });
  }

  public getFrameworkModuleValue<T>(modulePath: string, defaultValue?: T) {
    let value = this.browser.window[UtilsProvider._frameworkModule];
    let resume = true;

    modulePath.split(".").forEach((m, i) => {
      if (resume) {
        if (undefined !== value[m]) {
          value = value[m];
        } else {
          !environment.production && console.warn(`'${modulePath}' не зарегистрирован во внешнем API, будет использовано значение по умолчанию`);

          resume = false;
        }
      }
    });

    return resume ? value : defaultValue;
  }

  notify(message: any, supressLog: boolean = false, timeout: number = 2500) {
    if (!supressLog) {
      this._notify(message, timeout);
    }
  }

  private _notify(message: any, timeout: number = 2500) {
    this.addMessage(message instanceof Object ? JSON.stringify(message) : message, this.createGetPanel(), timeout);
  }

  private addMessage(m: string, panel: HTMLDivElement, timeout: number) {
    const document = this.browser.document;
    const message: HTMLElement = document.createElement("span");

    message.className = "wega3-console-message off";
    message.innerText = m;

    panel.appendChild(message);
    message.className = "wega3-console-message";

    setTimeout(() => {
      message.classList.add("off");
      setTimeout(() => {
        panel.removeChild(message);
      }, 500);
    }, timeout);
  }

  private createGetPanel(): HTMLDivElement {
    const document = this.browser.document;
    const panel: any = document.getElementById("wega3-console");

    if (panel == null) {
      const div = document.createElement("div") as HTMLDivElement;
      (div as HTMLElement).id = "wega3-console";

      document.body.appendChild(div);
      return div;
    }

    return panel;
  }

  trimChars(text: string, charToRemove: string) {
    while (text.charAt(0) === charToRemove) {
      text = text.substring(1);
    }

    while (text.charAt(text.length - 1) === charToRemove) {
      text = text.substring(0, text.length - 1);
    }

    return text;
  }

  async retry<T>(fn: () => Promise<T>, attempts = 5): Promise<T> {
    let result: T;

    let attempt = 1;
    do {
      try {
        result = await fn();
      } catch {}
    } while (!result && ++attempt <= attempts);

    return result;
  }
}
