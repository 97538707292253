import { Injectable } from "@angular/core";
import { OidcSecurityService } from "angular-auth-oidc-client";

export type WegaAuthInfo = {
  Token: string;
  User: string;
  Authenticated: boolean;
};

@Injectable()
export class WegaAuthenticationProvider {
  constructor(private oidcSecurity: OidcSecurityService) {}

  private authInfo: WegaAuthInfo = {
    Authenticated: false,
    Token: undefined,
    User: undefined,
  };

  get AuthInfo(): WegaAuthInfo {
    const accessToken = this.oidcSecurity.getToken();

    if (accessToken) {
      const profileInfo = this.oidcSecurity.getPayloadFromIdToken();
      const keyName = "given_name";
      const keyMail = "email";

      this.authInfo.Token = accessToken;
      this.authInfo.Authenticated = true;
      this.authInfo.User = profileInfo[keyName] || profileInfo[keyMail];
    }

    // console.log(accessToken);
    return this.authInfo;
  }

  async login() {
    this.oidcSecurity.authorize();
  }

  async logout() {
    this.oidcSecurity.logoff();
  }
}
