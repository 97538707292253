import { GeoExtent } from "../structures/extent";
import { CatalogNode } from "@wega3/components/catalog-tree/catalog-node";
import { UiProvider } from "@wega3/providers/ui.provider";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { FilterSpatial } from "../filter/filter-spatial";
import { ConfigResource } from "@shared/config/config-resource";
import { ConfigService } from "@shared/config/config-service";
import { WegaCatalog } from "./wega-catalog";
import { WegaSearchScanner, DefaultSearchScanner } from "./wega-search";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { NodeIcon, ServiceType } from "@shared/wega-utils/wega-enums";

enum AGSNodeLevel {
  folder,
  service,
  layer,
}

class ArcGisCatalogNodeDescription {
  constructor(folder: string, serviceType: string, service: string, layer: string, layerName: string) {
    this.folderName = folder;
    this.serviceName = service && service.indexOf("/") !== -1 ? service.split("/")[1] : service;
    this.serviceType = serviceType;
    this.RootLayerID = layer;
    this.RootLayerName = layerName;
    this.level = AGSNodeLevel.folder;

    if (this.serviceName !== null && this.serviceName !== "") {
      this.level = AGSNodeLevel.service;
    }

    if (this.RootLayerID !== null) {
      this.level = AGSNodeLevel.layer;
    }
  }

  public level: AGSNodeLevel;
  public folderName: string;
  public serviceName: string;
  public serviceType: string;
  public RootLayerID: string;
  public RootLayerName: string;

  getURL() {
    return this.folderName + "/" + this.serviceName + "/" + this.serviceType;
  }

  getID(): string {
    return this.folderName + "/" + this.serviceName + "/" + this.RootLayerID;
  }

  getName(): string {
    switch (this.level) {
      case AGSNodeLevel.folder:
        return this.folderName;

      case AGSNodeLevel.service:
        return this.serviceName + "(" + this.serviceType + ")";

      case AGSNodeLevel.layer:
        return this.RootLayerName;

      default:
        return "-";
    }
  }
}

export class ArcGisCatalog implements WegaCatalog {
  constructor(_title: string, arcGisBaseUrl: string, public appConfig: AppConfigProvider, public web: WebClientProvider, private locale: LocaleProvider) {
    this.title = _title;
    this.baseurl = arcGisBaseUrl;

    this.getEntries();
  }

  title: string;
  baseurl: string;

  private rootElements: CatalogNode[] = [];

  searchScanner: WegaSearchScanner = new DefaultSearchScanner();

  togglePage(goNextPage: boolean, pNode: CatalogNode): Promise<boolean> {
    throw new Error("Method not implemented.");
  }

  async getEntries(): Promise<CatalogNode[]> {
    if (this.rootElements.length === 0) {
      this.rootElements = await this.getNodesServices("");
    }

    return this.rootElements;
  }

  getChildEntries(pNode: CatalogNode): Promise<CatalogNode[]> {
    const ndDesc: ArcGisCatalogNodeDescription = <ArcGisCatalogNodeDescription>pNode.catalogNodeDescr;

    let childElements = null;

    if (ndDesc == null) {
      return this.getEntries();
    }

    switch (ndDesc.level) {
      case AGSNodeLevel.folder:
        childElements = this.getNodesServices(ndDesc.folderName);
        break;

      case AGSNodeLevel.service:
        childElements = this.getNodesLayers(ndDesc);
        break;

      case AGSNodeLevel.layer:
        childElements = this.getNodesLayers(ndDesc);
        break;

      default:
        childElements = this.getEntries();
        break;
    }

    return childElements;
  }

  resetFilter() {
    throw new Error("Method not implemented.");
  }

  addTextFilter(pSearchText: string) {
    throw new Error("Method not implemented.");
  }

  addAttributeFilter(pAttribute: string, pValue: string) {
    throw new Error("Method not implemented.");
  }

  setSpatialFilter(pSpatFilter: FilterSpatial) {
    throw new Error("Method not implemented.");
  }

  async getNodesServices(folderPath: string): Promise<CatalogNode[]> {
    const url = this.web.createProxiedUrl(
      this.baseurl + "/rest/services/" + folderPath + "?f=json",
      this.appConfig.Environment.ProxyUrl,
      this.appConfig.Environment.EncodeProxyRequest
    );

    const elementList = await this.web.httpGet(url);
    const answer: CatalogNode[] = [];

    if (elementList && elementList["folders"] && elementList["folders"].length) {
      for (const folderName of elementList["folders"]) {
        const newNodeElement = this.getNodeElement(folderName);
        answer.push(newNodeElement);
      }
    }

    if (elementList && elementList["services"] && elementList["services"].length) {
      for (const serviceDesciption of elementList["services"]) {
        const svcTitle = serviceDesciption["name"];
        const svcType = serviceDesciption["type"];

        const newElement = this.getNodeElement(folderPath, svcType, svcTitle);
        newElement.resource = await this.getResourceFor(svcTitle, newElement.catalogNodeDescr.getURL());
        newElement.setIcon(NodeIcon.Map);
        newElement.expandable = true;

        answer.push(newElement);
      }
    }

    return answer;
  }

  async getNodesLayers(nodeDescr: ArcGisCatalogNodeDescription): Promise<CatalogNode[]> {
    const ans: CatalogNode[] = [];

    const url = this.web.createProxiedUrl(
      this.baseurl + "/rest/services/" + nodeDescr.getURL() + "?f=json",
      this.appConfig.Environment.ProxyUrl,
      this.appConfig.Environment.EncodeProxyRequest
    );

    const elementList = await this.web.httpGet(url);
    // var elementList = await qQuery.json();

    if (elementList && elementList["layers"] && elementList["layers"].length) {
      const parentLayer = nodeDescr.RootLayerID ?? -1;
      for (const layerDesciption of elementList["layers"]) {
        if (layerDesciption["parentLayerId"] != parentLayer) {
          continue;
        }

        const layerID = layerDesciption["id"];
        const layerTitle = layerDesciption["name"];
        const newElement = this.getNodeElement(nodeDescr.folderName, nodeDescr.serviceType, nodeDescr.serviceName, layerID, layerTitle);
        const layersIdList = this.getLeafLayersIds(elementList["layers"], newElement.catalogNodeDescr.RootLayerID);

        newElement.resource = await this.getResourceFor(layerTitle, nodeDescr.getURL(), layersIdList);
        newElement.setIcon(NodeIcon.Map);
        newElement.expandable =
          layerDesciption["subLayerIds"] && layerDesciption["subLayerIds"].length && layerDesciption["subLayerIds"].length > 0 ? true : false;

        ans.push(newElement);
      }
    }

    return ans;
  }

  /// ArcGIS в identify запросе почему-то для иерархических слоев (когда "1" дочерний для "0") объект 2 раза - и для "0" и для "1"
  /// поэтому выбираем только дочерние. чтобы с одной стороны можно было каждый независимо отключить. С другой не иметь дублирований в identify
  getLeafLayersIds(pLayersList: any, rootLayerID: string = "-1"): string[] {
    let ans: string[] = [];
    for (const layer of pLayersList) {
      if (layer["parentLayerId"] === rootLayerID) {
        const hasChildren = layer["subLayerIds"] && layer["subLayerIds"].length && layer["subLayerIds"].length > 0;
        /// Добавляются только листовые элементы. Т.к. остальные смысла не несут, но приводят к дублированию почему-то
        if (hasChildren) {
          const childIds: string[] = this.getLeafLayersIds(pLayersList, layer["id"]);
          ans = ans.concat(childIds);
        } else {
          ans.push(layer["id"]);
        }
      }
    }

    /// если ни одного дочернего элемента не нашлось вообще, значит этот элемент и сам листовой.
    if (ans.length === 0) {
      ans.push(rootLayerID);
    }

    return ans;
  }

  getNodeElement(folderPath: string, serviceType: string = null, serviceName: string = null, layerID: string = null, layerName: string = null) {
    const desc = new ArcGisCatalogNodeDescription(folderPath, serviceType, serviceName, layerID, layerName);
    const newElement = new CatalogNode(this, this.appConfig, this.locale);

    newElement.catalogNodeDescr = desc;
    newElement.title = desc.getName();
    newElement.id = desc.getID();
    newElement.setIcon(NodeIcon.Folder);
    newElement.expandable = true;

    return newElement;
  }

  async getResourceFor(title: string, servicePath: string, layersList: string[] = []): Promise<ConfigResource> {
    const url = this.web.createProxiedUrl(
      this.baseurl + "/rest/services/" + servicePath + "?f=json",
      this.appConfig.Environment.ProxyUrl,
      this.appConfig.Environment.EncodeProxyRequest
    );

    const serviceDesc: any = await this.web.httpGet(url);

    // var queryAns = await fetch(url);
    // var serviceDesc = await queryAns.json();

    if (!serviceDesc) {
      return null;
    }

    const newServiceConfig = new ConfigService({});

    newServiceConfig.type = serviceDesc["singleFusedMapCache"] === true ? ServiceType.arcgistiled : ServiceType.arcgis;
    newServiceConfig.title = serviceDesc["mapName"];
    // TODO: proxy
    newServiceConfig.url = this.baseurl + "/rest/services/" + servicePath;

    const jsonExtent = <any>serviceDesc["fullExtent"];

    if (jsonExtent) {
      newServiceConfig.extent = new GeoExtent(
        jsonExtent["spatialReference"]["wkid"],
        jsonExtent["xmin"],
        jsonExtent["xmax"],
        jsonExtent["ymin"],
        jsonExtent["ymax"]
      );
    }

    newServiceConfig.layers = [];

    if (layersList.length > 0) {
      newServiceConfig.layers = layersList;
    } else if (serviceDesc["layers"] && Array.isArray(serviceDesc["layers"])) {
      newServiceConfig.layers = this.getLeafLayersIds(serviceDesc["layers"]);
    }

    const newResource = new ConfigResource({});

    newResource.title = title;
    newResource.servicesList.push(newServiceConfig);

    return newResource;
  }
}
