import { WegaSearchEngine, WegaSearchEngineInfo } from "../search.engine";

const createEngineInfo = (engine: WegaSearchEngine, engineInit: (engine: WegaSearchEngineInfo) => void): WegaSearchEngineInfo => {
  const engineInfo = {
    engine,
    enabled: true,
    elapsed: -1,
    collapsed: false,
    searching: false,
    index: -1,
    pageInfo: {
      currentPage: 0,
      pageSize: -1,
      totalPages: -1,
    },
  };
  engineInit(engineInfo);

  return engineInfo;
};

export const asDefault = (engine: WegaSearchEngine): WegaSearchEngineInfo => createEngineInfo(engine, (e) => (e.enabled = true));
export const asOptional = (engine: WegaSearchEngine): WegaSearchEngineInfo => createEngineInfo(engine, (e) => (e.enabled = false));

export interface WegaCompareFilter {
  scan: ({ sample, _source }) => boolean;
  values: string[];
}

export class WegaDefaultCompareFilter implements WegaCompareFilter {
  private value: string;
  get values() {
    return [this.value];
  }

  constructor(sought: string) {
    this.value = sought.toUpperCase();
  }

  scan({ sample }) {
    return sample.toUpperCase().indexOf(this.value) !== -1;
  }
}

type SearchOp = "AND" | "OR" | "NOT";
type SearchChunk = { op: SearchOp; sample: string };

export class AndOrNotCompareFilter implements WegaCompareFilter {
  private highlightedValues: string[];
  get values() {
    return this.highlightedValues;
  }

  private chunks = Array<SearchChunk>();

  constructor(req: string) {
    // через конечный автомат находятся все фразы и операторы, входящие в состав поискового запроса
    let buffer = "";
    let quoted = false;
    let op: SearchOp = "AND";

    const finalize = () => {
      // не совсем ясно, почему алгоритм сам не игнорирует охватывающие пробелы
      // как бы то ни было, пока приходится их убирать вручную
      const sample = buffer.trim();
      sample !== "" && this.chunks.push({ sample, op });

      buffer = "";
      quoted = false;
      op = "AND";

      return true;
    };

    req
      .toUpperCase()
      .split("")
      .forEach((char, index) => {
        switch (char) {
          case "|":
            quoted && (buffer += char);
            !quoted && finalize() && (op = "OR");
            break;

          case "-":
            quoted && (buffer += char);
            !quoted && finalize() && (op = "NOT");
            break;

          case " ":
            quoted && (buffer += char);
            !quoted && buffer !== "" && finalize();
            break;

          case '"':
            quoted && finalize() && (quoted = false);
            !quoted && (quoted = true);
            break;

          default:
            buffer += char;
            break;
        }

        index === req.length - 1 && finalize();
      });

    // например, для запроса 'Петербург "Московская область" | Испания - "Китай"' получим массив
    // [{ sample: 'ПЕТЕРБУРГ', op: 'AND' },
    // { sample: 'МОСКОВСКАЯ ОБЛАСТЬ', op: 'AND' },
    // { sample: 'ИСПАНИЯ', op: 'OR' },
    // { sample: 'КИТАЙ', op: 'NOT' }]
    // который уже дальше пошагово сверяется (в методе found) с проверяемым текстом

    // текстовые фразы, которые должны быть подсвечены в описаниях найденных объектов
    this.highlightedValues = this.chunks.filter((c) => c.op !== "NOT").map((c) => c.sample);
  }

  scan({ sample }) {
    const chunks = this.chunks;
    const text: string = sample.toUpperCase();

    // если запрос состоит только из одной фразы и любого оператора, кроме "НЕ", то можно использовать "классический" метод сравнения
    const isSimpleSearch = chunks.length === 1 && chunks[0].op !== "NOT";
    if (isSimpleSearch) {
      return new WegaDefaultCompareFilter(chunks[0].sample).scan({
        sample: text,
      });
    }

    let found = true;

    chunks.forEach((chunk) => {
      // для каждого сегмента определяется через простой компаратор его наличие в проверяемой строке
      const simpleComparator = new WegaDefaultCompareFilter(chunk.sample);
      const success = simpleComparator.scan({ sample: text });

      // далее производится увязка признаков входимости - текущего и аккумулятивного (полученного на предыдущем шаге)
      // Для "И" необходимо, чтобы текущий и предыдущий совпадали
      chunk.op === "AND" && (found = found && success);
      // Для "ИЛИ" это необязательно (любой из признаков входимости может быть истинным)
      chunk.op === "OR" && (found = found || success);
      // Для "НЕ" нужно, чтобы текущий признак входимости не был истинен
      chunk.op === "NOT" && (found = found && !success);
    });

    return found;
  }
}
