import { Injectable } from "@angular/core";
import { CatalogNode } from "./catalog-node";
import { AreaFilterProvider } from "@wega-providers/area-filter.provider";
import { ResourceProvider } from "@wega-providers/resource.provider";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { UiProvider } from "@wega3/providers/ui.provider";
import { WegaCatalog } from "@domain/data/catalogs/wega-catalog";
import { FileCatalog } from "@domain/data/catalogs/file-catalog";
import { RasterDBCatalog } from "@domain/data/catalogs/rasterdb-catalog";
import { ArcGisCatalog } from "@domain/data/catalogs/arcgis-catalog";
import { GisPackageCatalog } from "@domain/data/catalogs/gispackage-catalog";
import { CatalogConfig, arrayFromJson } from "@shared/config/config-catalog";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";
import { ESOCatalog } from "@domain/data/catalogs/eso-catalog";
import { ConfigResource } from "@shared/config/config-resource";
import { TemplateCatalog } from "@domain/data/catalogs/template-catalog";
import { DomSanitizer } from "@angular/platform-browser";
import { WegaUtils } from "@shared/wega-utils/wega-utils";

@Injectable()
export class DynamicDatabase {
  dataMap = new Map<string, string[]>();

  rootLevelNodes: string[] = [];
  catalogsList: WegaCatalog[] = [];
  public res: ResourceProvider;

  constructor(
    public filter: AreaFilterProvider,
    public appConfig: AppConfigProvider,
    public web: WebClientProvider,
    private locale: LocaleProvider,
    public sanitizer: DomSanitizer
  ) {}

  async initialData(url: string = null): Promise<CatalogNode[]> {
    this.catalogsList = [];

    await this.loadFromConfig(url);
    return this.catalogsList.map((_catalog) => new CatalogNode(_catalog, this.appConfig, this.locale));
  }

  async findById(requiredId: string, ignoredNodeFn: (node: CatalogNode) => boolean): Promise<CatalogNode[]> {
    let foundNodes: CatalogNode[] = [];

    for (let catalog of this.catalogsList) {
      let catalogueRootNodes = await catalog.getEntries();
      let catalogueNodes = await this.findNodesRecursively(catalogueRootNodes, (node) => node.id == requiredId, ignoredNodeFn);
      foundNodes.push(...catalogueNodes);
    }

    return foundNodes;
  }

  async findNodesRecursively(
    nodesList: CatalogNode[],
    compare: (node: CatalogNode) => boolean,
    ignoredNodeFn: (node: CatalogNode) => boolean
  ): Promise<CatalogNode[]> {
    let foundNodes: CatalogNode[] = [];

    for (let node of nodesList) {
      if (!ignoredNodeFn(node)) {
        if (compare(node)) {
          foundNodes.push(node);
        }

        if (node.expandable) {
          let childNodes = await node.catalog.getChildEntries(node);
          let foundChildNodes = await this.findNodesRecursively(childNodes, compare, ignoredNodeFn);
          foundNodes.push(...foundChildNodes);
        }

        if (node.isModuleNode && !!node.catalog.searchScanner) {
          let catalogRootNodes = await node.catalog.getEntries();
          let catalogNodes = await this.findNodesRecursively(catalogRootNodes, compare, ignoredNodeFn);
          foundNodes.push(...catalogNodes);
        }
      }
    }

    return foundNodes;
  }

  async loadFromConfig(url: string) {
    const cors = this.appConfig.Environment.CorsScript;
    const configModulesJson = await this.web.httpGet(url, { queryParams: { nocache: WegaUtils.createGuid() } });
    const catalogConfigs: CatalogConfig[] = arrayFromJson(configModulesJson);

    for (const config of catalogConfigs) {
      const catalogClass = await this.getCatalogClass(config.module, config.title, config.url, config.children);

      if (catalogClass) {
        this.catalogsList.push(catalogClass);
      }
    }
  }

  async getCatalogClass(catalogType: string, title: string, url: string, children: any): Promise<WegaCatalog> {
    if (-1 !== this.appConfig.Environment.HiddenCatalog.indexOf(url)) {
      return null;
    }

    switch (catalogType) {
      case "FileCatalog":
        return new FileCatalog(title, url, this.web, this.appConfig, this.sanitizer, children, this.locale);

      case "RasterDBCatalog":
        return new RasterDBCatalog(title, url, this.appConfig, this.locale);

      case "ArcGisCatalog":
        return new ArcGisCatalog(title, url, this.appConfig, this.web, this.locale);

      case "GisPackageCatalog":
        return new GisPackageCatalog(title, url, this.appConfig, this.web, this.locale);

      case "ESOCatalog":
        return new ESOCatalog(title, url, this.appConfig, this.web, this.locale);

      case "TemplateCatalog":
        return new TemplateCatalog(title, this.appConfig, this.web, this.locale, this.sanitizer, url);

      case "DynamicCatalog":
        return new Promise((resolve) => {
          import(`${url}`).then((ctor) => {
            resolve(new ctor(url, this.appConfig, this.web, this.locale));
          });
        });

      default:
        return null;
    }
  }

  async getChildren(node: CatalogNode) {
    return await node.loadChildren(this.res);
  }

  isExpandable(node: string): boolean {
    return true;
  }
}
