import { LegendRender } from "./legend-render";
import { LegendRecord } from "./legend-record";
import { LegendParser } from "./legend-parser";
import { ConfigService } from "@shared/config/config-service";

export class LegendDescription {
  public layer: any;
  public layerID: string;
  public rendersList: LegendRender[] = [];
  public name: string;
  public legendUrls: { [key: string]: string } = {};
  public legendFiles = [];

  public getArcGISLegendRender() {}

  public addLegendStyle(legendRender: LegendRender) {
    this.rendersList.push(legendRender);
  }

  setURL(layer: string, url: string) {
    this.legendUrls[layer] = url;
  }

  recordIsExcluded(layerId: any, config: ConfigService, label: string) {
    const forbidden = config.forbiddenLegendElements.find((x) => x.name == layerId.toString())?.label === label;
    return forbidden;
  }

  public async fromArcGISLegendJson(legendInfoArray: any, layerId: any, config: ConfigService) {
    const newRender = new LegendRender();
    const layersJson = legendInfoArray.layers.filter((l) => l.layerId.toString() === layerId.toString());

    if (!layersJson || layersJson.length === 0) {
      return;
    }

    const layerLegend = layersJson[0].legend;
    for (const record of layerLegend) {
      const dataUrl = "data:" + record.contentType + ";base64," + record.imageData;
      const newLegendRecord = await LegendRecord.fromDataURL(layerId, config, record.label, record.values, dataUrl, null, null);

      /// TODO: как иначе определить, когда элемент легенды пуст?
      if (
        newLegendRecord.imageData64url ==
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABtJREFUOI1jYaAyYBk1cNTAUQNHDRw1cKQaCAAQKwBR5g8A3AAAAABJRU5ErkJggg=="
      ) {
        newLegendRecord.empty = true;
      }

      if (!this.recordWithLabelAndSymbolExists(newRender, newLegendRecord) && !this.recordIsExcluded(layerId, config, newLegendRecord.sourceLabel)) {
        newRender.addRecord(newLegendRecord);
      }
    }

    config.legendParsers.forEach((parserId) => {
      LegendParser.transform(newRender, parserId);
    });

    this.rendersList.push(newRender);
  }

  public async fromArcGISJson(jsonRenderer: any, config: ConfigService) {
    const newRender = new LegendRender();

    switch (jsonRenderer.type) {
      case "uniqueValue": {
        const records = [
          ...jsonRenderer.uniqueValueInfos,
          ...(!!jsonRenderer.defaultSymbol
            ? [
                {
                  description: "",
                  label: jsonRenderer.defaultLabel,
                  symbol: jsonRenderer.defaultSymbol,
                  value: null,
                },
              ]
            : []),
        ];

        newRender.renderType = "unique";
        for (const record of records) {
          const newLegendRecord = await LegendRecord.fromArcGISRender(
            config,
            this.layerID,
            record,
            [jsonRenderer.field1, jsonRenderer.field2, jsonRenderer.field3],
            jsonRenderer.fieldDelimiter
          );

          if (!this.recordWithLabelAndSymbolExists(newRender, newLegendRecord) && !this.recordIsExcluded(this.layerID, config, newLegendRecord.sourceLabel)) {
            newRender.addRecord(newLegendRecord);
          }
        }

        break;
      }

      case "simple": {
        const newLegendRecord = await LegendRecord.fromArcGISRender(config, this.layerID, jsonRenderer, []);

        newRender.renderType = "unique";
        newRender.addRecord(newLegendRecord);

        break;
      }
    }

    config.legendParsers.forEach((parserId) => {
      LegendParser.transform(newRender, parserId);
    });

    this.rendersList.push(newRender);
  }

  recordWithLabelAndSymbolExists(render: LegendRender, record: LegendRecord) {
    return (
      render.recordsList.filter((r) => {
        return r.snapshot === record.snapshot && r.sourceLabel === record.sourceLabel && r.imageData64url == record.imageData64url;
      }).length !== 0
    );
  }

  public fromSld(sld: any) {}
}
