import { InjectionToken, inject } from "@angular/core";
import { EsriProvider } from "@wega-providers/esri.provider";
import { ArcGisMapProvider } from "../wega-ui/components/arcgis-map/providers/arcgis-map.provider";
import { WegaMapEngine } from "../wega-ui/map-engine";
import { ArcGisLocatorSearchEngine } from "../search-engines/engines/arcgis-locator/arcgis-locator.engine";
import { WegaSearchEngine } from "../search-engines/search.engine";
import { CacheSearchEngine } from "../search-engines/engines/cache-search/cache-search.engine";
import { CatalogResourcesSearchEngine } from "../search-engines/engines/catalog-resources/catalog-resources.engine";
import { GeologicalMonumentsSearchEngine } from "../search-engines/engines/geological-monuments/geological-monuments.engine";
import { SpatialFiltersSearchEngine } from "../search-engines/engines/spatial-filters-search/spatial-filters-search.engine";
import { UnifiedApiSearchEngine } from "../search-engines/engines/unified-api-search/unified-api-search.engine";
import { LocaleProvider } from "../i18n/providers/i18n.provider";
import { ResourceProvider } from "@wega-providers/resource.provider";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { UtilsProvider } from "@shared/providers/utils.provider";
import { UiProvider } from "./providers/ui.provider";
import { AreaFilterProvider } from "@wega-providers/area-filter.provider";
import { StorageProvider } from "@wega-providers/storage.provider";
import { AttributeQuerySearchEngine } from "../search-engines/engines/attribute-data/attribute-query.engine";
import { WegaPlugin } from "@wega-providers/plugins.provider";
import { ArcGisTrackerProvider } from "../wega-ui/components/arcgis-map/plugins/tracker.plugin";
import { RasterDbSearchEngine } from "../search-engines/engines/raster-db-search/raster-db-search.engine";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { LLMSearchEngine } from "../search-engines/engines/llm-search.engine.ts/llm-search.engine";

export const MAP_ENGINE = new InjectionToken<WegaMapEngine>("wega.engine");

export const REGISTERED_PLUGINS = new InjectionToken<Record<string, WegaPlugin>>("wega.plugins", {
  providedIn: "root",
  factory: () => {
    const riftPlugin = "riftTracker";
    const plugins: Record<string, WegaPlugin> = {
      [riftPlugin]: new ArcGisTrackerProvider(inject(EsriProvider), inject(ArcGisMapProvider)),
    };

    return plugins;
  },
});

export const REGISTERED_SEARCH_ENGINES = new InjectionToken<Record<string, (args: string[]) => WegaSearchEngine>>("wega.search-engines", {
  providedIn: "root",
  factory: () => ({
    ["arcgis-locator"]: (args: string[]) => new ArcGisLocatorSearchEngine(inject(ArcGisMapProvider), inject(EsriProvider), inject(LocaleProvider)),

    ["catalog-resources"]: (args: string[]) => new CatalogResourcesSearchEngine(inject(ResourceProvider), inject(LocaleProvider)),

    ["attribute-data"]: (args: string[]) =>
      new AttributeQuerySearchEngine(inject(ArcGisMapProvider), inject(EsriProvider), inject(ResourceProvider), inject(LocaleProvider)),

    ["unified-api"]: (args: string[]) =>
      new UnifiedApiSearchEngine(
        inject(UtilsProvider),
        inject(ArcGisMapProvider),
        inject(EsriProvider),
        inject(AppConfigProvider),
        inject(LocaleProvider),
        ...args
      ),

    ["raster-db"]: (args: string[]) =>
      new RasterDbSearchEngine(
        inject(UtilsProvider),
        inject(ArcGisMapProvider),
        inject(EsriProvider),
        inject(WebClientProvider),
        inject(AppConfigProvider),
        inject(LocaleProvider),
        ...args
      ),

    ["cached-data"]: (args: string[]) =>
      new CacheSearchEngine(
        inject(UtilsProvider),
        inject(ResourceProvider),
        inject(UiProvider),
        inject(ArcGisMapProvider),
        inject(EsriProvider),
        inject(LocaleProvider)
      ),

    ["spatial-filters"]: (args: string[]) => new SpatialFiltersSearchEngine(inject(AreaFilterProvider), inject(LocaleProvider)),
    ["geological-monuments"]: (args: string[]) => new GeologicalMonumentsSearchEngine(inject(AppConfigProvider), inject(LocaleProvider)),
    ["llm-api"]: (args: string[]) => new LLMSearchEngine(inject(StorageProvider), inject(LocaleProvider), ...args),
  }),
});
