import { ServiceType } from "@shared/wega-utils/wega-enums";
import { ArcGisService } from "./arcgis/arcgis.service";
import { GenericService } from "./generic-service";

export class WegaServiceCapabilities {
  service: GenericService;

  constructor(service: GenericService) {
    this.service = service;
  }

  get canBeDownloaded() {
    return this.service.getConfig().downloadEnabled && this.canBeServedAsShape;
  }

  get canBeServedAsShape() {
    const type = this.service.getConfig().type.toString();
    const isArcGis = type === ServiceType.arcgis.toString() || type === ServiceType.arcgistiled.toString();
    /*supportsStatistics = isArcGis && (service => {
      const
      layers = service.getArcGISLayersInfo();

      let
      supports = false;

      for (const layerKey in layers) {
        const
        layer = layers[layerKey];

        supports = supports && layer.advancedQueryCapabilities.supportsStatistics;
      }

      return supports;
    })(<ArcGisService> this.service.getConcreteService())*/

    return (
      isArcGis ||
      // || type === ServiceType.arcgisfeature.toString(),
      type === ServiceType.wfs.toString() ||
      type === ServiceType.vector.toString() ||
      type === ServiceType.nextgiswebmap.toString()
    );
  }

  get canBeServedAsJSON() {
    return this.canBeServedAsShape;
  }

  get canGetAttributes() {
    return this.service.getConfig().type !== ServiceType.wms;
  }

  get canGetLegend() {
    return true;
    //this.service.getConfig().type !== ServiceType.wms
  }

  get canGetSearch() {
    return false;
    // return (
    //   this.service.getConfig().type !== ServiceType.wms
    // );
  }

  get requiredPlugins() {
    return this.service.getConfig().plugins;
  }

  /** Можно ли редактировать атрибутивные данные.
   * На данном этапе - считается, что, если в конфиге ресурса редактирование разрешено, то и сервис не против
   */
  get canAttributesBeEdited() {
    return true;
  }

  /** Можно ли редактировать геометрию. Сейчас пока вообще нельзя. */
  get canGeometryBeEdited() {
    return false;
  }

  /** Можно ли использовать фильтр по легенде. */
  get canFilterLegend() {
    return this.service.getConfig().type.toString() === ServiceType.arcgis.toString();
  }
}
