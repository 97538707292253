import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { loadModules } from "esri-loader";
import { LegendDescription } from "../../legend/legend-description";
import { ResourceFilter } from "../../filter/resource-filter";
import { FieldsInfo } from "../../feature/fields-info";
import { QueryFeature } from "../../feature/query-feature";
import { EsriProvider } from "@wega-providers/esri.provider";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { MapService } from "@domain/data/resource/map-service";
import { ConfigService } from "@shared/config/config-service";
import { LayerData } from "@domain/data/structures/layer-data";
import { MapClickPoint } from "@domain/data/structures/map-click-point";
import { FieldConfig } from "@shared/config/config-field";
import { WegaServiceCapabilities } from "../service-capabilities";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";
import { OlProvider } from "@wega-providers/ol.provider";
import { UtilsProvider } from "@shared/providers/utils.provider";
import { ServiceState } from "@shared/wega-utils/wega-enums";
import { LayerStatistic } from "@domain/data/structures/layerStatistic";

export class WmtsService implements MapService {
  state: ServiceState;
  canUseFilter: boolean = false;
  layer: any;

  constructor(
    public utils: UtilsProvider,
    public config: ConfigService,
    public appConfig: AppConfigProvider,
    public esri: EsriProvider,
    public ol: OlProvider,
    public capabilities: WegaServiceCapabilities
  ) {
    this.esri.setProxy(this.config);
  }

  async getLayer(): Promise<any> {
    const [WMTSLayer, WMTSLayerInfo, esriRequest] = await loadModules(["esri/layers/WMTSLayer", "esri/layers/WMTSLayerInfo", "esri/request"]);

    /// текущее ограничение: в списке можно указать только один слой
    const layers = this.config.layers.join(",") || null;
    const capsUrl = this.config._source["capabilitiesUrl"] || this.config.url;
    const caps = await this.ol.readWMTSCapabilities(capsUrl);
    const tileMatrixSet = caps["Contents"]["Layer"].find((l: any) => l["Identifier"] === layers)["TileMatrixSetLink"][0]["TileMatrixSet"];

    const layerInfo = new WMTSLayerInfo({
      identifier: layers,
      tileMatrixSet,
      format: "png",
    });

    esriRequest.setRequestPreCallback((ioArgs: any) => {
      /// модификация capabilities-запроса, штатный запрос почему-то не устраивает ArcGIS Server
      if (ioArgs.url === `${this.config.proxy}${this.config.url}/1.0.0/WMTSCapabilities.xml`) {
        ioArgs.url = capsUrl;
      }

      return ioArgs;
    });

    this.layer = new WMTSLayer(this.config.url, { layerInfo });
    return this.layer;
  }

  async getLayerStatistic(layer: string, filter: ResourceFilter) {
    return new LayerStatistic();
  }

  // временные (будем надеяться) методы, позволяющие обойти проблему с отказом WMTS-слоя удаляться, менять прозрачность и т.п. (#5299)
  wmts__removeLayer() {
    const map = this.utils.browser.global("__agmap")();
    Object.keys(map._layers).forEach((key) => {
      const layer = map._layers[key];

      if (layer.guid === this.layer.guid) {
        map.removeLayer(layer);
      }
    });
  }

  wmts__setOpacity(opacity: number) {
    const map = this.utils.browser.global("__agmap")();
    Object.keys(map._layers).forEach((key) => {
      const layer = map._layers[key];

      if (layer.guid === this.layer.guid) {
        layer.setOpacity(opacity);
      }
    });
  }

  wmts__setVisibility(visible: boolean) {
    const map = this.utils.browser.global("__agmap")();
    Object.keys(map._layers).forEach((key) => {
      const layer = map._layers[key];

      if (layer.guid === this.layer.guid) {
        visible && layer.show();
        !visible && layer.hide();
      }
    });
  }

  getLegend(): LegendDescription[] {
    console.warn("Method not implemented.");
    return [];
  }

  loadLayerInfo(): Promise<FieldsInfo> {
    return null;
  }

  getPointFeatureInfo(coordinates: MapClickPoint): Promise<QueryFeature[]> {
    throw new Error("Method not implemented.");
  }

  getExtentFeatureInfo(extentEvt: any): Promise<QueryFeature[]> {
    throw new Error("Method not implemented.");
  }

  getAttributesByID(featureID: string): Promise<any> {
    throw new Error("Method not implemented.");
  }

  getFeaturesByQuery(filter: ResourceFilter): Promise<[QueryFeature[], boolean]> {
    throw new Error("Method not implemented.");
  }

  loadSpatialData(): Promise<LayerData[]> {
    return null;
  }

  setFilter(filter: ResourceFilter) {
    console.warn("Method not implemented.");
  }

  saveFeatures(queryFeature: any): Promise<boolean> {
    return null;
  }
}
