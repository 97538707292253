import { Injectable } from "@angular/core";
import { loadModules, loadCss } from "esri-loader";
import { interval as rxinterval } from "rxjs";
import { take, map as rxmap } from "rxjs/operators";
import { ResourceFilter } from "@domain/data/filter/resource-filter";
import { WegaResource } from "@domain/data/resource/wega-resource";
import { UtilsProvider } from "@shared/providers/utils.provider";
import { ConfigService } from "@shared/config/config-service";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { AppConfigProvider } from "@shared/providers/config.provider";

@Injectable()
export class EsriProvider {
  constructor(private webClient: WebClientProvider, private appConfig: AppConfigProvider, private utils: UtilsProvider) {
    utils.setFrameworkModule("ESRI", {
      _provider: this,
      loadModules: this.loadModules,
    });
  }

  //static esriOptions = { url: 'assets/scripts/esri/3.28compact/init.js' };
  static esriOptions = { baseUrl: "https://jslibs.vsegei.ru/", url: "https://jslibs.vsegei.ru/arcgis_js_api/library/3.28/3.28compact/init.js" };

  private glMarkers: any = [];

  async loadModules(modules: string[]) {
    return loadModules(modules, EsriProvider.esriOptions);
  }

  loadCss(cssFiles: string[], absolute: boolean) {
    return cssFiles.map((file) => {
      return loadCss(`${absolute ? EsriProvider.esriOptions.baseUrl : ""}${file}`);
    });
  }

  async zoomToFeature({ field, resource, id, map }, withMarker = false): Promise<{ feature: any; marker: any }> {
    const filter = new ResourceFilter(this);
    filter.addConditionStructure(field, "=", id);
    await (<WegaResource>resource).selectByFilter(filter);

    // берется 1-й найденный объект, хотя их может быть больше
    const feature = resource.selectedFeatures[0];

    // перейти к экстенту объекта
    const geometry = feature?.arcgisFeature?.geometry;
    if (geometry) {
      const marker = await this.zoomToGeometry({ geometry, map }, withMarker);
      return { feature, marker };
    }

    return { feature: null, marker: null };
  }

  /**
   * Сейчас этот метод решает частную задачу - получить хоть какой-то экстент объекта
   * и нужен в тех случаях, когда zoomToFeature не срабатывает. Обычно это те случаи,
   * когда фильтр (метод getLayerFeaturesByQuery) по какой-то причине не возвращает геометрию объекта.
   * Тогда можно попробовать получить экстент напрямую через запрос к слою по ID объекта.
   */
  async directZoomFeatureById({ url, layer, id, map }, withMarker = false) {
    // TODO: proxy
    const response = await this.webClient.httpGet<any>(`${url}/${layer}/${id}?f=json`);
    const geometry = response.feature.geometry;

    return await this.zoomToGeometry({ geometry, map }, withMarker);
  }

  async zoomToGeometry({ geometry, map }, withMarker = false): Promise<any> {
    const extent = geometry.getExtent && geometry.getExtent();
    let marker = null;

    if (extent) {
      // предпочтительный вариант, экстент получается явным образом
      map.setExtent(extent);
      withMarker && (marker = await this.putMarker(extent.getCenter(), map));
    } else if (geometry.rings) {
      // из колец можно получить полигон, а потом его экстент
      const [Polygon] = await this.loadModules(["esri/geometry/Polygon"]);
      const polygon = new Polygon(geometry);
      const polygonExtent = polygon.getExtent();

      map.setExtent(polygonExtent);
      withMarker && (marker = await this.putMarker(polygonExtent.getCenter(), map));
    } else if (geometry.points) {
      // точки (multipoint) - берем первую
      const _point = geometry.points[0];
      const [Point, SpatialReference] = await loadModules(["esri/geometry/Point", "esri/SpatialReference"]);
      const point = new Point(_point[0], _point[1], new SpatialReference({ wkid: 4326 }));

      map.centerAt(point);
      withMarker && (marker = await this.putMarker(point, map));
    } else if (geometry.x && geometry.y) {
      // точка (объект класса Point)
      map.centerAt(geometry);
      withMarker && (marker = await this.putMarker(geometry, map));
    } else if (geometry.paths) {
      // мультилиния
      const [Polyline] = await this.loadModules(["esri/geometry/Polyline"]);
      const polylineJson = {
        paths: geometry.paths,
        spatialReference: { wkid: map.spatialReference.latestWkid },
      };

      const polyline = new Polyline(polylineJson);
      const extent = polyline.getExtent();

      map.setExtent(extent);
      withMarker && (marker = await this.putMarker(extent.getCenter(), map));
    } else {
      console.log(`Не удалось распознать ттп геометрии ${geometry}`);
    }

    return marker;
  }

  /** Подсветить объект (с эффектом мигания). */
  async flashFeature({ resource, id, feature, res }, interval = 500, cycles = 3) {
    resource.selectionLayer.clear();

    rxinterval(interval)
      .pipe(
        take(cycles * 2),
        rxmap((o: any) => o % 2 === 0)
      )
      .subscribe(async (on) => {
        // надо бы отписаться в ngOnDestroy
        on && this.on({ resource, id, feature, res });
        !on && this.off({ resource });
      });
  }

  async on({ resource, feature, id, res }) {
    await resource.selectionLayer.addFeatures([feature]);
    res.highlightFeature(resource.id, id);
  }

  off({ resource }) {
    resource.selectionLayer.clear();
  }

  async queryByFeatureId({ resource, id }) {
    const filter = new ResourceFilter(this);
    filter.addConditionStructure("OBJECTID", "=", id);

    await resource.selectByFilter(filter);
    resource.searchExpired = false;
  }

  async putMarker(point: any, map: any): Promise<any> {
    const [Graphic, PictureMarkerSymbol] = await loadModules(["esri/graphic", "esri/symbols/PictureMarkerSymbol"]);

    const pictureSymbol = new PictureMarkerSymbol({
      url: "/assets/images/MapMarker_Flag1_Left_Pink.png",
      width: 64,
      height: 64,
      xoffset: -10,
      yoffset: 36,
    });

    const graphic = new Graphic(point, pictureSymbol);
    map.graphics.add(graphic);

    this.glMarkers.push(graphic);
    this.glMarkers.reverse();

    return graphic;
  }

  async clearMarker(marker: any, map: any, timeout = 3000) {
    return new Promise((r) => {
      setTimeout(() => {
        marker && map.graphics.remove(marker);
        r(void 0);
      }, timeout);
    });
  }

  async clearAllMarkers(map: any) {
    return new Promise(async (r) => {
      while (this.glMarkers.length) {
        await this.clearMarker(this.glMarkers.pop(), map, 0);
      }

      r(void 0);
    });
  }

  proxiedServices: Array<{ url: string; proxy: string }> = [];

  public esriConfig: any;
  public urlUtils: any;

  setProxy(serviceConfig: ConfigService) {
    const proxy = this.appConfig.Environment.ProxyUrl || serviceConfig.proxy;
    const encodeProxyRequest = this.appConfig.Environment.EncodeProxyRequest || serviceConfig.encodeProxyRequest;

    if (proxy) {
      this.esriConfig.defaults.io.corsEnabledServers.push(proxy);
      this.esriConfig.defaults.io.proxyUrl = proxy;
      this.esriConfig.defaults.io.alwaysUseProxy = false;

      this.urlUtils.addProxyRule({
        urlPrefix: serviceConfig.url,
        proxyUrl: proxy,
      });

      if (!this.proxiedServices.find((ws) => ws.proxy === proxy && ws.url === serviceConfig.url)) {
        this.proxiedServices.push({ proxy, url: serviceConfig.url });
      }

      serviceConfig.proxy = proxy;
      serviceConfig.encodeProxyRequest = encodeProxyRequest;
    }
  }
}
