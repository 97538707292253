import { Component, ElementRef, OnDestroy } from "@angular/core";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { StorageProvider } from "@wega-providers/storage.provider";
import { ProfileComponent } from "@wega3/components/profile/profile.component";

@Component({
  selector: "wega-app",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "wega-gen3";

  constructor(storage: StorageProvider, appConfig: AppConfigProvider) {
    const loginOrigin = storage.read<string>(ProfileComponent.loginOriginKey);
    storage.remove(ProfileComponent.loginOriginKey);

    if (loginOrigin && loginOrigin != appConfig.mainRoute) {
      window.location.replace(`site/${loginOrigin}`);
    }
  }
}
