import { NgModule, EventEmitter } from "@angular/core";
import { WmsService } from "./wms/wms.service";
import { WfsService } from "./wfs/wfs.service";
import { ArcGisService } from "./arcgis/arcgis.service";
import { ArcGisFeatureService } from "./arcgisfeature/arcgis-feature.service";
import { RestService } from "./rest/rest.service";
import { UrlService } from "./url/url.service";
import { VectorService } from "./vector/vector.service";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { EsriProvider } from "@wega-providers/esri.provider";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { WegaServiceCapabilities } from "./service-capabilities";
import { FieldsInfo } from "../feature/fields-info";
import { ConfigService } from "@shared/config/config-service";
import { MapService } from "../resource/map-service";
import { LegendDescription } from "../legend/legend-description";
import { ResourceFilter } from "../filter/resource-filter";
import { LayerData } from "../structures/layer-data";
import { MapClickPoint } from "../structures/map-click-point";
import { QueryFeature } from "../feature/query-feature";
import { GeoExtent } from "../structures/extent";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";
import { WmtsService } from "./wmts/wmts.service";
import { OlProvider } from "@wega-providers/ol.provider";
import { UtilsProvider } from "@shared/providers/utils.provider";
import { XyzService } from "./xyz/xyz.service";
import { ServiceType } from "@shared/wega-utils/wega-enums";
import { ConfigResource } from "@shared/config/config-resource";
import { LayerProvider } from "@wega-providers/layer.provider";
import { NextGisService } from "./nextgis/nextgis.service";
import { LayerStatistic } from "../structures/layerStatistic";

export class GenericService {
  public onInfoUpdate: EventEmitter<string> = new EventEmitter<string>();
  public onStateChanged: EventEmitter<string> = new EventEmitter<string>();

  _web: WebClientProvider;
  public fieldsDescription: FieldsInfo = new FieldsInfo();

  async getAttributesByID(featureID: string) {
    return this.mapService.getAttributesByID(featureID);
  }

  private _config: ConfigService;
  public getConfig(): ConfigService {
    return this._config;
  }

  private mapService: MapService;
  public getMapService(): MapService {
    return this.mapService;
  }

  public esriLayer: any;

  public layerLegend: LegendDescription[] = [];
  public allowMapClick: boolean = true;
  public state: "ready" | "drawing map" | "error drawing map" = "ready";
  canUseFilter(): boolean {
    return this.mapService.canUseFilter;
  }

  public capabilities: WegaServiceCapabilities;

  constructor(
    public resourceConfig: ConfigResource,
    public config: ConfigService,
    public web: WebClientProvider,
    public utils: UtilsProvider,
    public esri: EsriProvider,
    public ol: OlProvider,
    public appConfig: AppConfigProvider,
    public layerProvider: LayerProvider,
    public locale: LocaleProvider
  ) {
    this._config = config;
    this._web = web;

    this.capabilities = new WegaServiceCapabilities(this);
    this.mapService = this.createMapService();
    this.layerLegend = this.mapService.getLegend();

    this.loadFieldsDescription(); // TODO: асинхронная операция, не всегда успевает выполняться!
  }

  setFilter(filter: ResourceFilter) {
    this.mapService.setFilter(filter);
    this.esriLayer?.refresh();
  }

  /// Сервис поддерживает получение данных по координатам
  public supportsClick(): boolean {
    return this._config.supportClick && this.allowMapClick;
  }

  /// Сервис поддерживает отрисовку карты
  public supportsMap(): boolean {
    return this._config.supportMap;
  }

  public setOpacity(opacity: number) {
    this.esriLayer?.setOpacity(opacity);

    if (this._config.type == "wmts") {
      // для совместимости с WMTS-слоями
      this.mapService["wmts__setOpacity"]?.(opacity);
    }
  }

  public setVisibility(visible: boolean) {
    this.esriLayer?.setVisibility(visible);
  }

  async getMapLayers(): Promise<any[]> {
    /// Массив на будущее - потому, что может быть еще, например, векторный слой с выбранными features
    /// или множественные фильтрующие слои
    const service = this;

    /// Если слой не поддерживает отрисовку карты - сервис ничего не возвращает.
    if (!this._config.supportMap) {
      return [];
    }

    if (!this.esriLayer) {
      this.esriLayer = await this.mapService.getLayer();
      if (this._config.opacity) {
        this.setOpacity(this._config.opacity);
      }

      if (this.esriLayer) {
        this.esriLayer.guid = this._config.id;
        this.esriLayer.on("update-start", function () {
          // console.log ("--- loading map: start");

          service.state = "drawing map";
          service.onStateChanged.emit("loading");
        });

        this.esriLayer.on("update-end", function () {
          // console.log ("--- loading map: finish");

          service.state = "ready";
          service.onStateChanged.emit("ready");
        });

        this.esriLayer.on("error", function (a, b, c) {
          // console.log("--- loading map: error !", a, b, c);

          service.state = "error drawing map";
          service.onStateChanged.emit("error");
        });

        this.esriLayer.on("resume", function () {
          // console.log("--- loading map: resume");

          service.state = "drawing map";
          service.onStateChanged.emit("loading");
        });
      }
    }

    return this.esriLayer;
  }

  public async loadSpatialData(layer: string): Promise<LayerData[]> {
    return await this.mapService.loadSpatialData(layer);
  }

  public async getLayerStatistic(layer: string, filter: ResourceFilter) {
    if (layer == null || layer == undefined) {
      return new LayerStatistic();
    }
    return await this.mapService.getLayerStatistic(layer, filter);
  }

  public supportsEdit(): boolean {
    return this._config.edit;
  }

  save(feature: QueryFeature) {
    this.mapService.saveFeatures(feature);
  }

  getExtent(): GeoExtent {
    if (this._config.extent) {
      return this._config.extent;
    }

    return void 0;
  }

  public async getPointFeatureInfo(coordinates: MapClickPoint): Promise<QueryFeature[]> {
    if (!this._config.supportClick) {
      return [];
    }

    const features = await this.mapService.getPointFeatureInfo(coordinates);
    return features || [];
  }

  public async getExtentFeatureInfo(extentEvent: any): Promise<QueryFeature[]> {
    if (!this._config.supportClick) {
      return [];
    }

    const features = await this.mapService.getExtentFeatureInfo(extentEvent);
    return features || [];
  }

  public async getFeaturesByQuery(filter: ResourceFilter): Promise<[QueryFeature[], boolean]> {
    if (!this._config.supportQuery) {
      return [[], false];
    }

    const [features, error] = await this.mapService.getFeaturesByQuery(filter);
    return [features, error];
  }

  async getServiceInfo() {
    return await this.mapService.loadLayerInfo();
  }

  async loadFieldsDescription() {
    this.fieldsDescription = await this.mapService.loadLayerInfo();
    this.onInfoUpdate.emit("fieldsDescription");
  }

  private createMapService(): MapService {
    switch (this._config.type) {
      case ServiceType.wms:
        return new WmsService(this._config, this._web, this.appConfig, this.esri, this.layerProvider, this.locale, this.capabilities);

      case ServiceType.wfs:
        return new WfsService(this._config, this.appConfig, this.esri, this.locale, this.capabilities);

      case ServiceType.wmts:
        return new WmtsService(this.utils, this._config, this.appConfig, this.esri, this.ol, this.capabilities);

      case ServiceType.xyz:
        return new XyzService(this._config, this._web, this.esri, this.appConfig);

      case ServiceType.arcgis:
      case ServiceType.arcgistiled:
        return new ArcGisService(this);
      case ServiceType.nextgiswebmap:
        return new NextGisService(this);

      case ServiceType.arcgisfeature:
        return new ArcGisFeatureService(this._config, this.esri);

      case ServiceType.rest:
        return new RestService(this._config, this._web);

      case ServiceType.url:
        return new UrlService(this._config);

      case ServiceType.vector:
        return new VectorService(this.appConfig, this.locale, this._config, this._web);

      case ServiceType.geojson:
        return new VectorService(this.appConfig, this.locale, this._config, this._web);

      case ServiceType.kml:
        return new VectorService(this.appConfig, this.locale, this._config, this._web);

      default:
        console.error(`Не предусмотрен обработчик сервисов типа ${this._config.type}!`);
    }

    return null;
  }

  async downloadShape(layer: string) {
    const spatialData = await this.loadSpatialData(layer);
  }
}
