import { Injectable } from "@angular/core";
import WMSCapabilities from "ol/format/WMSCapabilities";
import { WegaLayer } from "@domain/data/structures/layer-info";
import WMTSCapabilities from "ol/format/WMTSCapabilities";

@Injectable()
export class OlProvider {
  constructor() {}

  async readWMSLayers(url: string, sourceVersion: string): Promise<Array<WegaLayer>> {
    const trim = (txt: string, char: string) => {
      while (txt.charAt(0) === char) {
        txt = txt.substring(1);
      }

      while (txt.charAt(txt.length - 1) === char) {
        txt = txt.substring(0, txt.length - 1);
      }

      return txt;
    };

    const flatten = (tree) => {
      const result = [];

      function _flatten(node, parentTitles = []) {
        if (!node) {
          return;
        }

        if (node.Title) {
          parentTitles = parentTitles.concat(node.Title);
        }

        if (node.Layer && node.Layer.length > 0) {
          for (const childLayer of node.Layer) {
            _flatten(childLayer, parentTitles);
          }
        } else {
          const wegaLayer = new WegaLayer();
          wegaLayer.name = node.Name;
          wegaLayer.title = [...parentTitles, ...[node.Title]].join(": ");
          wegaLayer.extent = node.BoundingBox && node.BoundingBox.extent;
          wegaLayer.crs = node.BoundingBox && node.BoundingBox.extent;

          result.push(wegaLayer);
        }
      }

      _flatten(tree);
      return result;
    };

    const parser = new WMSCapabilities();
    const version = sourceVersion ? sourceVersion : "1.1.1";
    const response = await fetch(`${trim(url, "?")}?service=wms&version=${version}&request=GetCapabilities`);
    const text = await response.text();
    const result = parser.read(text);
    const layers = flatten(result.Capability.Layer);

    return layers;
  }

  async readWMTSCapabilities(wmtsUrl: string): Promise<any> {
    const parser = new WMTSCapabilities();
    const response = await fetch(wmtsUrl);
    const text = await response.text();
    const capabilities = parser.read(text);

    return capabilities;
  }
}
