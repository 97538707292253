import { Component, Inject, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, Input } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { StorageProvider } from "@wega-providers/storage.provider";
import { UiProvider } from "@wega3/providers/ui.provider";
import { UserState } from "@domain/data/structures/user-state";
import { WegaAuthenticationProvider } from "src/app/modules/authentication/providers/authentication.provider";
import { UtilsProvider } from "@shared/providers/utils.provider";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";

@Component({
  selector: "wega-profile-state",
  templateUrl: "./profile-state.component.html",
  styleUrls: ["./profile-state.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileStateComponent implements OnInit {
  constructor(
    public storage: StorageProvider,
    public ui: UiProvider,
    public auth: WegaAuthenticationProvider,
    public utils: UtilsProvider,
    private locale: LocaleProvider
  ) {}

  ngOnInit(): void {}

  load(): void {
    const savedState: UserState = this.storage.currentSlotState;

    this.ui.recoverState(savedState);
  }

  exists() {
    return this.storage.stateExists;
  }

  slot() {
    return this.storage.currentSlot;
  }

  current() {
    return this.storage.currentSlotState;
  }

  stateExists() {
    return this.storage.stateInSlotExists(this.slot());
  }

  total() {
    return this.storage.stateCount;
  }

  target() {
    return this.storage.siteTarget;
  }

  save(): void {
    this.storage.saveState();
  }

  clear(): void {
    this.storage.clearState();
  }

  @ViewChild("stateText") textAreaState: ElementRef<HTMLTextAreaElement>;
  showTextState = false;
  textState = "";
  switchTextState() {
    this.showTextState = !this.showTextState;

    if (this.showTextState) {
      this.textState = JSON.stringify(this.storage.transientState);
    }
  }

  copyStateText() {
    const element = this.textAreaState.nativeElement;

    element.focus();
    element.select();

    this.utils.browser.document.execCommand("copy");
    this.utils.notify(
      this.locale.current !== "en" ? "Состояние было скопировано в буфер обмена в виде текста" : "The state was copied to the clipboard as text"
    );
  }

  loadStateFromText() {
    const element = this.textAreaState.nativeElement;
    const text = element.value;
    const stateFromText = JSON.parse(text);

    this.ui.recoverState(stateFromText);
  }

  saveStateTextToJSON() {
    const window = this.utils.browser.window;
    const document = this.utils.browser.document;
    const link = document.createElement("a") as HTMLAnchorElement;
    const json = [JSON.stringify(this.storage.transientState)];
    const blob = new Blob(json, { type: "text/plain;charset=utf-8" });
    const url = window.URL || window.webkitURL;
    const time = (() => {
      const date = new Date(Date.now());
      return [date.toLocaleDateString("ru-RU"), date.toLocaleTimeString("ru-RU")].join("-");
    })();

    link.download = `wega-${this.auth.AuthInfo.User ?? this.getUserId()}-${time}.json`;
    link.href = url.createObjectURL(blob);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  @ViewChild("jsonState") jsonStateInput: ElementRef<HTMLInputElement>;
  loadStateTextFromJSON() {
    const jsonStateUpload = this.jsonStateInput.nativeElement;

    jsonStateUpload.onchange = (evt: any) => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        const jsonState = JSON.parse(event.target.result);

        this.textState = JSON.stringify(jsonState);
        this.ui.recoverState(jsonState);
      };

      reader.readAsText(evt.target.files[0]);
      this.jsonStateInput.nativeElement.value = null;
    };

    jsonStateUpload.click();
  }

  changeSlot(index: number) {
    this.storage.slot = index;
  }

  getUserId(): string {
    return this.storage.currentSlotState.userId;
  }

  timeString(state: UserState) {
    const savedDateTime = state.timeStamp,
      dateTime = -1 !== savedDateTime && new Date(savedDateTime);

    return dateTime && `${dateTime.toLocaleDateString("ru-RU")}г., ${dateTime.toLocaleTimeString("ru-RU")}`;
  }

  aboutState(state: UserState) {
    return `
    Карты (${state.Resources.length}):
    ${state.Resources.map((m) => m.title).join(", ")},
    ; Центр: ${JSON.stringify(state.Center)},
    ; Увеличение: ${JSON.stringify(state.Zoom)}
    `;
  }
}
