import { WegaLayer } from "../structures/layer-info";

export class WfsReader {
  private _address: string;
  private _proxy: string;

  constructor(address: string, proxy: string = null) {
    this._address = address;
    this._proxy = proxy;
  }

  async getLayersList(): Promise<Array<WegaLayer>> {
    const capabilities = `${this._proxy}${this._address}service=wfs&version=1.0.0&request=GetCapabilities`,
      fetchResult = await fetch(capabilities, { method: "GET", credentials: "omit", mode: "cors" }),
      text = await fetchResult.text(),
      parser = new DOMParser(),
      xmlDoc = parser.parseFromString(text, "text/xml"),
      wfsLayers = new Array<WegaLayer>();

    let withNamespace = false,
      layers = xmlDoc.getElementsByTagName("FeatureType");

    if (!layers.length) {
      layers = xmlDoc.getElementsByTagName("wfs:FeatureType");
      withNamespace = true;
    }

    for (let index = 0; index < layers.length; index++) {
      const element = layers[index],
        wfsLayer = new WegaLayer();

      let xml = parser.parseFromString(element.outerHTML, "text/html");

      var name = withNamespace ? xml.getElementsByTagName("wfs:Name")[0] : xml.getElementsByTagName("Name")[0];
      var title = withNamespace ? xml.getElementsByTagName("wfs:Title")[0] : xml.getElementsByTagName("Title")[0];

      //var srs = nv ? l.getElementsByTagName("wfs:SRS")[0] : l.getElementsByTagName("SRS")[0];
      //var llBbox  = nv ? l.getElementsByTagName("wfs:LatLongBoundingBox")[0] : l.getElementsByTagName("LatLongBoundingBox")[0];

      try {
        wfsLayer.name = name.textContent.split(":")[name.textContent.split(":").length - 1];
      } catch {
        wfsLayer.name = name.textContent;
      }

      wfsLayer.title = title.textContent;
      wfsLayers.push(wfsLayer);
    }

    return wfsLayers;
  }
}
