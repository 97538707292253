import { ResourceProvider } from "@wega-providers/resource.provider";
import { UiProvider } from "@wega3/providers/ui.provider";
import { WegaCatalog } from "@domain/data/catalogs/wega-catalog";
import { ConfigResource } from "@shared/config/config-resource";
import { WegaResource } from "@domain/data/resource/wega-resource";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { SafeHtml } from "@angular/platform-browser";
import { BehaviorSubject } from "rxjs";
import { NodeIcon, ServiceType } from "@shared/wega-utils/wega-enums";

export class CatalogNode {
  get supportsAdd(): boolean {
    return this.resource != null && !this.resource.disabled;
  }

  constructor(private _catalog: WegaCatalog, private appConfig: AppConfigProvider, private locale: LocaleProvider) {
    this.catalog = _catalog;
    this.level = 0;
    this.isLoading = false;
    this.title = _catalog ? _catalog.title : "";

    this.expandable = true;
    this.setIcon(NodeIcon.Plain);
    // this.LoadChildren();
  }

  public id: string;

  public title: string;

  private _icon: NodeIcon;
  public icon(expanded: boolean, hasChild: boolean): string {
    switch (this._icon) {
      case NodeIcon.Doc:
        return "file_copy";

      case NodeIcon.Map:
        if (this.resource.servicesList.filter((s) => s.type == ServiceType.url).length == this.resource.servicesList.length) {
          this._icon = NodeIcon.Link;
          return "link";
        } else if (hasChild) {
          if (expanded) {
            return "remove_circle_outline";
          } else {
            return "add_circle_outline";
          }
        } else {
          return "language";
        }

      case NodeIcon.Image:
        return "panorama";

      case NodeIcon.Link:
        return "link";

      case NodeIcon.Problem:
        return "report_problem";

      case NodeIcon.Folder:
        return expanded ? "remove_circle_outline" : "add_circle_outline";
      // return 'folder';

      case NodeIcon.Plain:
      default:
        return "label";
    }
  }

  public expandable: boolean;
  public children: CatalogNode[] = [];

  public resource: ConfigResource = null;
  public level: number;
  public isLoading: boolean;
  public isModuleNode: boolean;

  public catalog?: WegaCatalog;
  public get notShown() {
    return this.resource?.hidden || -1 !== this.appConfig.Environment.HiddenResources.indexOf(this.resource?.id);
  }

  public get notAvailable() {
    return this.resource?.disabled;
  }

  public get whyNotAvailable(): string {
    return this.locale.current !== "en" ? "временно недоступно" : "temporarily unavailable";
  }

  // нужно для работы механизма пагинации
  public isLastOnPage: boolean;
  public isOnFirstPage: boolean;
  public isOnLastPage: boolean;
  public parent: CatalogNode;
  public total: number;
  public current: number;

  /// объект, в котором каждый каталог может хранить специфичные для него данные
  public catalogNodeDescr: any;

  public isAdded = false;

  public added(res: ResourceProvider) {
    if (this.resource?.switchChildrenOnly) {
      return this.isAdded;
    }

    return this.isAlreadyAdded(res);
    // return this.isAdded;
  }

  //// временно добавил поля.. чтобы компилировалось.
  featureDisplayAttrs: any[];

  private _cat: WegaCatalog;

  async loadChildren(res: ResourceProvider) {
    const children = this.id ? await this.catalog.getChildEntries(this) : await this.catalog.getEntries();

    for (const node of children) {
      this.isAdded = node.isAlreadyAdded(res);
    }

    this.children = children;
    return children;

    // this.LoadChildren();
  }

  public isAlreadyAdded(res: ResourceProvider) {
    if (!this.resource || !res) {
      this.isAdded = false;
      return false;
    }

    const selectedResources = res.getResourceList();
    const resource = this.findThisResourceByConfig(selectedResources);

    return resource != null;
  }

  public async addRemoveToMap(res: ResourceProvider, state: "switch" | "add" | "remove" = "switch"): Promise<[WegaResource, boolean]> {
    if (this.isOpenLink) {
      const url = this.resource.servicesList[0].url;
      window.open(url);

      return [void 0, false];
    }

    const selectedResources = res.getResourceList();
    const resource: WegaResource = this._resourceWithIdExists(selectedResources) || this.findThisResourceByConfig(selectedResources);

    if (state == "switch") {
      if (resource) {
        this.isAdded = false;
        await res.removeResource(resource);
        return [resource, this.isAdded];
      } else {
        this.isAdded = true;
        const newResource = await res.addResourceConfig(this.resource);
        return [newResource, this.isAdded];
      }
    } else if (state == "add") {
      this.isAdded = true;
      if (resource) {
        return [resource, this.isAdded];
      } else {
        const newResource = await res.addResourceConfig(this.resource);
        return [newResource, this.isAdded];
      }
    } else if (state == "remove") {
      this.isAdded = false;
      if (resource) {
        await res.removeResource(resource);
        return [resource, this.isAdded];
      } else {
        return [resource, this.isAdded];
      }
    }
  }

  get isOpenLink() {
    if (!this.resource) {
      return false;
    }

    for (const svc of this.resource.servicesList) {
      if (svc.isMap()) {
        return false;
      }
    }

    return true;
  }

  private _resourceWithIdExists(selectedResources: WegaResource[]) {
    const foundResource = selectedResources.filter((sr) => sr.id === this.resource.id);

    return !!foundResource.length ? foundResource[0] : null;
  }

  private findThisResourceByConfig(selectedResources: WegaResource[]) {
    /// Try - внутри equalsConfig могут возникать исключения.
    try {
      /// сравниваем ресурс с каждым добавленным в wega - если там есть хоть один - добавлять еще раз нельзя
      for (const res of selectedResources) {
        if (res.equalsConfig(this.resource)) {
          this.isAdded = true;
          return res;
        }
      }
    } catch (e) {}

    return null;
  }

  setIcon(icon: NodeIcon) {
    this._icon = icon;
  }

  exposeData: () => [SafeHtml, number] = null;

  exposedData$$ = new BehaviorSubject<SafeHtml>(null);
  exposedData$ = this.exposedData$$.asObservable();

  exposedDataOffset$$ = new BehaviorSubject<number>(0);
  exposedDataOffset$ = this.exposedDataOffset$$.asObservable();
}
