import { WegaSearchResult, StatefulSearchEngine } from "../../search.engine";
import { ResourceProvider } from "@wega-providers/resource.provider";
import { EsriProvider } from "@wega-providers/esri.provider";
import { ArcGisMapProvider } from "src/app/modules/wega-ui/components/arcgis-map/providers/arcgis-map.provider";
import { QueryFeature } from "@domain/data/feature/query-feature";
import { AttributeDataDetailsComponent } from "./attribute-data.details";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";
import { AttributeSearchResult, AttributeSearchResultObject } from "./attribute-data.engine";
import { LayerData } from "@domain/data/structures/layer-data";

export class AttributeQuerySearchEngine extends StatefulSearchEngine {
  constructor(private arcgis: ArcGisMapProvider, private esri: EsriProvider, private res: ResourceProvider, private locale: LocaleProvider) {
    super();
  }

  get title(): string {
    return this.locale.current === "en" ? "Selected resources" : "Выбранные ресурсы";
  }

  get description(): string {
    return this.locale.current === "en" ? "Search by attribute data of selected resources" : "Поиск по атрибутивным данным отобранных ресурсов";
  }

  supportsComplexSearch = false;

  templateInputKey = "input";
  template = () => AttributeDataDetailsComponent;

  async engineSearch(text: string): Promise<WegaSearchResult[]> {
    let foundFeatures = [];
    const resources = this.res.searchableResources();

    for (const resource of resources) {
      const layerData: LayerData[] = await resource.loadAllData(resource.serviceModuleList, null);

      const features = layerData
        .map((ld) => ld.featuresList)
        .reduce((a, b) => a.concat(b), [])
        .map((ld) => ((ld.source = resource), ld));

      const filteredFeatures = this.filterOutResults(features, text);
      foundFeatures = foundFeatures.concat(...filteredFeatures);
    }

    const results = [];

    foundFeatures.forEach((foundObject, index) => {
      const geometry = foundObject.geometry;
      results.push({
        source: foundObject.resource.title,
        name: `Найденный объект №${index + 1}`,
        cancelHighlight: true,
        templateInput: {
          attrs: foundObject.attributes,
          searchKey: text,
        },

        values: [text],
        zoom: geometry
          ? async () => {
              const marker = await this.esri.zoomToGeometry({ geometry, map: this.arcgis.EsriMap }, true);
              await this.esri.clearMarker(marker, this.arcgis.EsriMap);
            }
          : null,
      });
    });

    if (0 === resources.length) {
      this.signal("Поиск по отобранным ресурсам не выполнен, так как на карту не добавлено ни одного ресурса");
    }

    return results;
  }

  private filterOutResults(features: QueryFeature[], text: string): Array<AttributeSearchResultObject> {
    const foundObjects = [];
    const txt = text.split(" ").reverse().pop().toUpperCase();

    features.forEach((feature: QueryFeature) => {
      // console.log(feature.geometry);
      const fndObject = {
        attributes: [],
        geometry: undefined,
        source: undefined,
        resource: undefined,
      };

      for (const attrCollectionName in feature.attributes) {
        const attributeCollection = feature.attributes[attrCollectionName];
        for (const attributeName in attributeCollection) {
          const attributeValue = attributeCollection[attributeName];

          if (attributeValue && attributeValue.toString().toUpperCase().indexOf(txt) !== -1) {
            fndObject.attributes.push({
              key: attributeName,
              value: attributeValue,
            });
          }
        }
      }

      fndObject.attributes.length > 0 &&
        ((fndObject.source = feature), (fndObject.resource = feature.source), (fndObject.geometry = feature.geometry), foundObjects.push(fndObject));
    });

    return foundObjects;
  }
}
