class CatalogConfig {
  module: string;
  title: string;
  url: string;
  children: any;

  constructor(jsonDescription: any) {
    this.module = jsonDescription["module"];
    this.title = jsonDescription["title"];
    this.url = jsonDescription["url"];
    this.children = jsonDescription["children"];
  }
}

const arrayFromJson = (configModulesJson: any): CatalogConfig[] => {
  const list: CatalogConfig[] = [];

  configModulesJson.forEach((cd) => {
    list.push(new CatalogConfig(cd));
  });

  return list;
};

export { CatalogConfig, arrayFromJson };
