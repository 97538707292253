export class MapGeometry {
  public type: string;
  public srs: string;
  public rings: any[];
  public paths: any[];
  public x: number;
  public y: number;

  constructor() {}

  getEsriGeometry() {
    switch (this.type) {
      case "point":
        return {
          x: this.x,
          y: this.y,
          spatialReference: { wkid: this.srs },
        };

      case "polygon":
        return {
          rings: this.rings,
          spatialReference: { wkid: this.srs },
        };

      case "multiline":
        return {
          paths: this.paths,
          spatialReference: { wkid: this.srs },
        };

      default:
        return {};
    }
  }

  fillFromGML(gmlCoords: any): any {
    var gmlType = Object.keys(gmlCoords)[0];

    this.srs = "3857"; //pGMLCoords[vType][0]["_attributes"]["srsName"];

    if (gmlType == "gml:Box") {
      this.type = "polygon";
      let coord = gmlCoords[gmlType][0]["gml:coordinates"][0]["_text"][0].split(" ");
      this.rings = this.getBboxRings(coord);
    } else if (gmlType == "gml:Polygon") {
      this.type = "polygon";
      this.rings = [];

      try {
        var coords = gmlCoords[gmlType][0]["gml:coordinates"][0]["_text"][0].split(" ");
      } catch {
        /// при последнем обновлении карты ЕГКМ координаты стали находиться именно в такой структуре (почему?)
        var coords = gmlCoords[gmlType][0]["gml:outerBoundaryIs"][0]["gml:LinearRing"][0]["gml:coordinates"][0]["_text"][0].split(" ");
      }

      for (let crd of coords) {
        this.rings.push(crd.split(","));
      }

      this.rings = [this.rings];
    } else if (gmlType == "gml:Point") {
      this.type = "point";
      var coords = gmlCoords[gmlType][0]["gml:coordinates"][0]["_text"][0].split(" ")[0].split(",");
      this.x = coords[0];
      this.x = coords[1];
    } else if (gmlType == "gml:LineString") {
      this.type = "multiline";
      this.paths = [];
      let coords = gmlCoords[gmlType][0]["gml:coordinates"][0]["_text"][0].split(" ");

      for (let crd of coords) {
        this.paths.push(crd.split(",").map((x) => parseFloat(x)));
      }
      this.paths = [this.paths];
    }
  }

  getBboxRings(coord: any): any[] {
    let c1 = coord[0].split(",");
    let c2 = coord[1].split(",");
    let x1 = c1[0];
    let x2 = c2[0];
    let y1 = c1[1];
    let y2 = c2[1];

    if (x1 == x2 && y1 == y2) {
      this.x = x1;
      this.y = y1;
      this.type = "point";
    }

    return [
      [
        [x1, y1],
        [x2, y1],
        [x2, y2],
        [x1, y2],
        [x1, y1],
      ],
    ];
  }

  getPoint(x1: any, y1: any): any[] {
    throw new Error("Method not implemented.");
  }

  fillFromArcGIS(gmlCoords: any): any {
    this.x = gmlCoords.x;
    this.y = gmlCoords.y;
    this.rings = gmlCoords.rings;
    this.paths = gmlCoords.paths;
    this.srs = gmlCoords.spatialReference.latestWkid || gmlCoords.spatialReference.wkid;

    if (this.x) {
      this.type = "point";
    }

    if (this.rings) {
      this.type = "polygon";
    }

    if (this.paths) {
      this.type = "polyline";
    }
  }

  static fromGML(gmlCoords: any): MapGeometry {
    const geom: MapGeometry = new MapGeometry();
    geom.fillFromGML(gmlCoords);

    return geom;
  }

  static fromArcGIS(geometry: any): MapGeometry {
    const geom: MapGeometry = new MapGeometry();
    geom.fillFromArcGIS(geometry);

    return geom;
  }
}
