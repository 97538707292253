import { ConfigResource } from "@shared/config/config-resource";
import { WegaResource } from "@domain/data/resource/wega-resource";
import { FilterSpatial } from "@domain/data/filter/filter-spatial";

export type WegaEvent<T = { [key: string]: string }> =
  | { type: "FULL_EXTENT" }
  | { type: "PREVIOUS_EXTENT" }
  | { type: "EXTENT_CHANGED"; extent: [] }
  | { type: "MEASURE_TRIGGER"; enabled: boolean }
  | { type: "OVERVIEW_MAP"; enabled: boolean }
  | { type: "RESIZE"; state: "open" | "resize" }
  | { type: "GLOBAL_SPATIAL_FILTER"; filters: FilterSpatial[] }
  | { type: "START_SELECT" }
  | { type: "DROP_SELECT" }
  | { type: "QUERY_STARTED" }
  | { type: "QUERY_COMPLETED" }
  | { type: "INFO_WINDOW"; enabled: boolean }
  | { type: "CUSTOM_RESOURCES"; resources: ConfigResource[] }
  | { type: "CUSTOM_EXTENT"; zoom?: number; center?: number[]; extent?: [] }
  | { type: "AREA_FILTER"; state: "done" }
  | { type: "AREA_FILTER_CLEAR" }
  | { type: "AREA_FILTER_NODE_SEARCH"; data: T }
  | { type: "CURRENT_LAYER"; enabled: boolean }
  | { type: "DASHBOARD" }
  | { type: "INQUIRY" }
  | { type: "LAYERS_OPENED"; state: "" }
  | { type: "LAYERS_CHANGED"; state: "" }
  | { type: "LEGEND_FILTER"; state: { active: boolean; source: any; resource: WegaResource } }
  | { type: "RESOURCE_CHANGED"; state: { resource: WegaResource; added: boolean } }
  | { type: "RESOURCE_MOVED"; state: { resource: WegaResource; from: number; to: number } }
  | { type: "RESOURCE_VISIBLE"; state: { resource: WegaResource; visible: boolean } }
  | { type: "RESOURCE_PARAMS"; state: { resource: WegaResource; params: { [key: string]: any } } }
  | { type: "SEARCH_ACTIVATED"; state: { text: string } }
  | { type: "CUSTOM_CONFIG_LOADED" }
  | { type: "SWIPE_MAP_CHANGED"; state: { config: ConfigResource; enabled: boolean } }
  | { type: "SHOW_PROJECTIONS"; state: { enabled: boolean; input: any } };

export type WegaToolsInstrument = "EXPORT" | "MEASURE" | "DEEP_SEARCH" | "PROJECTION" | "OVERVIEW";

export class ExtentHistory {
  private _extents = [];
  private _extentStackState: "disabled" | "disabledOnce" | "enabled" = "enabled";

  push(extent) {
    this._extents.push(extent);
  }

  pop() {
    return this._extents.pop();
  }

  peek() {
    return this._extents[this._extents.length - 1];
  }

  clear() {
    this._extents = [];
  }

  available() {
    return this._extents.length > 0;
  }

  state(): "disabled" | "disabledOnce" | "enabled" {
    return this._extentStackState;
  }

  setState(state: "disabled" | "disabledOnce" | "enabled") {
    this._extentStackState = state;
  }
}
