import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { FilterItem, AreaItem, AreaSource } from "../utils/area-filter.utils";
import { WegaUtils } from "@shared/wega-utils/wega-utils";

@Injectable()
export class AreaFilterProvider {
  public availableItems: FilterItem[] = [];
  filteredItems: Observable<FilterItem[]>;

  public filterForTree: Array<FilterItem> = [];
  public currentFilterItems: FilterItem[] = Array<FilterItem>();

  constructor(private appConfig: AppConfigProvider, private web: WebClientProvider) {}

  filterCount() {
    return this.currentFilterItems.length - 1;
  }

  async fillAreaFilter(): Promise<FilterItem[]> {
    const url: string = this.appConfig.Environment.FiltersUrl;
    const { filters } = await this.web.httpGet<any>(url, { queryParams: { nocache: WegaUtils.createGuid() } });

    /// коллекция, используемая в поле поиска
    this.availableItems = [];

    /// коллекция, формируемая для дерева
    this.filterForTree = [];

    filters.forEach((source: AreaSource) => {
      const key = source.key;
      const items = source.items;

      items.forEach((item) => {
        this.extractForFilter(source, item, this.availableItems, this.filterForTree);
      });
    });

    return this.filterForTree;
  }

  extractForFilter(source: AreaSource, item: AreaItem, autocomplete: FilterItem[], tree: Array<FilterItem>, parent: string = null) {
    if (!item.hidden) {
      const filterItem = new FilterItem();
      filterItem.source = source;

      filterItem.title = item.name;
      filterItem.alias = item.value;
      filterItem.bounds = this.applyBounds(item.bounds);
      filterItem.type = item.type;
      filterItem.address = item.url;
      filterItem.active = filterItem.default = !!item.isDefault;

      if (filterItem.default) {
        this.currentFilterItems.push(filterItem);
      }

      filterItem.id = WegaUtils.createGuid();
      filterItem.parent = parent;
      filterItem.children = [];

      item.type !== "label" && autocomplete.push(filterItem);
      tree.push(filterItem);

      item.children?.forEach((i) => {
        this.extractForFilter(source, i, this.availableItems, filterItem.children, filterItem.id);
      });
    }
  }

  applyBounds(bounds: any): any {
    return bounds;
  }
}
