import { EventEmitter } from "@angular/core";
import { loadModules } from "esri-loader";
import { LegendDescription } from "../../legend/legend-description";
import { ResourceFilter } from "../../filter/resource-filter";
import * as terraformer from "terraformer";
import * as terraformerAG from "terraformer-arcgis-parser";
import * as shpjs from "shpjs";
import { QueryFeature } from "../../feature/query-feature";
import { FieldsInfo } from "../../feature/fields-info";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { MapService } from "@domain/data/resource/map-service";
import { ConfigService } from "@shared/config/config-service";
import { MapClickPoint } from "@domain/data/structures/map-click-point";
import { LayerData } from "@domain/data/structures/layer-data";
import { GeoExtent } from "@domain/data/structures/extent";
import { WegaServiceCapabilities } from "../service-capabilities";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";
import { ServiceState, GEOMETRY_OPERATION, ServiceType } from "@shared/wega-utils/wega-enums";
import * as tj from "@mapbox/togeojson";
import { LayerStatistic } from "@domain/data/structures/layerStatistic";

export class VectorService implements MapService {
  constructor(private globalConfig: AppConfigProvider, private locale: LocaleProvider, config: ConfigService = null, private web: WebClientProvider = null) {
    this.config = config || new ConfigService({});
    this.onFeatureHover = new EventEmitter<string>();
  }

  public static SelectedGeometry: any = null;

  public onFeatureHover: EventEmitter<string>;
  public esriLayer: any;
  state: ServiceState;
  canUseFilter: boolean = false;

  private selectedFeatureGUID: string;
  config: ConfigService;

  /// временные хранилища буферов файлов
  fileShp: any;
  filePrj: any;
  fileDbf: any;

  private colorOrdinal: number[] = [0, 255, 255, 100];
  private colorHighlight: number[] = [255, 215, 0, 100]; // [190, 0, 0, 150];
  private colorSelected: number[] = [50, 50, 50, 50];

  public getLegend(): LegendDescription[] {
    return null;
  }

  async getLayerStatistic(layer: string, filter: ResourceFilter) {
    return new LayerStatistic();
  }

  setVisibility(visible: boolean) {
    this.esriLayer.setVisibility(visible);
  }

  clear() {
    while (this.esriLayer && this.esriLayer.graphics && this.esriLayer.graphics.length > 0) {
      this.esriLayer.remove(this.esriLayer.graphics[0]);
    }
  }

  async addFeatures(featuresList: QueryFeature[], displayAttrs: string[] = []) {
    if (!featuresList) {
      return;
    }

    let colorSelected = this.colorSelected;
    if (window["ONETIME_CUSTOM_HIGHLIGHT_COLOR"]) {
      // потом надо избавиться от этого хака
      colorSelected = window["ONETIME_CUSTOM_HIGHLIGHT_COLOR"];
    }

    for (const feature of featuresList) {
      feature.setDefaultDisplayName(displayAttrs);
      await this.addFeature(feature, colorSelected);
    }

    if (window["ONETIME_CUSTOM_HIGHLIGHT_COLOR"]) {
      window["ONETIME_CUSTOM_HIGHLIGHT_COLOR"] = undefined;
    }
  }

  async highlight(featureId: any) {
    let selectedGR = null;

    this.selectedFeatureGUID = featureId;
    for (const gr of this.esriLayer.graphics) {
      const isSelected = gr.attributes["guid"] === featureId;
      const colorCode = isSelected ? this.colorHighlight : this.colorSelected;

      const symbol = await this.getSymbol(gr.geometry.type, colorCode);
      gr.setSymbol(symbol);

      if (isSelected) {
        selectedGR = gr;
        VectorService.SelectedGeometry = gr;
      }
    }

    /// Переместить выбранный на передний план
    if (selectedGR) {
      this.esriLayer.remove(selectedGR);
      this.esriLayer.add(selectedGR);
    }
  }

  async getSymbol(pGeomType: string, color: number[], size: number = 15) {
    const [SimpleLineSymbol, SimpleMarkerSymbol, SimpleFillSymbol, Color] = await loadModules([
      "esri/symbols/SimpleLineSymbol",
      "esri/symbols/SimpleMarkerSymbol",
      "esri/symbols/SimpleFillSymbol",
      "esri/Color",
    ]);

    const stylePoint = new SimpleMarkerSymbol(
      this.config.style || {
        color,
        size,
        angle: 0,
        xoffset: 0,
        yoffset: 0,
        type: "esriSMS",
        style: "esriSMSSquare",
        outline: {
          color: [0, 0, 0, 255],
          width: 1,
        },
      }
    );

    const styleLine = new SimpleLineSymbol(
      this.config.style || {
        type: "esriSLS",
        style: "esriSLSSolid",
        color,
        width: size,
      }
    );

    const stylePoly = new SimpleFillSymbol(
      this.config.style || {
        type: "esriSFS",
        style: "esriSFSSolid",
        color,
        outline: {
          type: "esriSLS",
          style: "esriSLSSolid",
          color: [0, 0, 0, 255],
          width: 3,
        },
      }
    );

    const markerArray = [];
    markerArray["point"] = stylePoint;
    markerArray["line"] = styleLine;
    markerArray["polyline"] = styleLine;
    markerArray["polygon"] = stylePoly;

    const curMarker = markerArray[pGeomType];
    return curMarker;
  }

  async resetImportedFile() {
    this.fileDbf = null;
    this.filePrj = null;
    this.fileShp = null;
  }

  async importShpFile(arrayBuffer: any): Promise<VectorService> {
    this.fileShp = arrayBuffer;
    return this;
  }

  async importPrjFile(arrayBuffer: any): Promise<VectorService> {
    this.filePrj = arrayBuffer;
    return this;
  }

  async importDbfFile(arrayBuffer: any): Promise<VectorService> {
    this.fileDbf = arrayBuffer;
    return this;
  }

  async finalizeShapeImport(): Promise<void> {
    const projString = new TextDecoder().decode(this.filePrj);
    const shp = shpjs.parseShp(this.fileShp, projString);

    const dbf = shpjs.parseDbf(this.fileDbf, <any>"1251");
    const geojson = shpjs.combine([shp, dbf]);

    this.fileShp = null;
    this.filePrj = null;
    this.fileDbf = null;

    await this.loadFeaturesFromGeoJSON(geojson);
  }

  public async clipGeometryFromObjects(clipGeometry: any, operation: GEOMETRY_OPERATION) {
    const [geometryEngine, webMercatorUtils, Graphic] = await loadModules(["esri/geometry/geometryEngine", "esri/geometry/webMercatorUtils", "esri/graphic"]);

    const clipGraphics = new Graphic({ geometry: clipGeometry });
    let cutPolygon = clipGraphics.geometry;

    if (this.esriLayer.graphics.length > 0) {
      const spatialRef = this.esriLayer.graphics[0].geometry.spatialReference;
      cutPolygon = webMercatorUtils.project(cutPolygon, spatialRef);
    }

    // console.log(cutPolygon);
    for (const gr of this.esriLayer.graphics) {
      const clippedGeometry = geometryEngine[operation.toString()](gr.geometry, cutPolygon);
      gr.geometry = clippedGeometry;

      this.esriLayer.remove(gr);
      this.esriLayer.add(gr);
    }

    this.esriLayer.refresh();
  }

  loadFeaturesFromKML(content: string) {
    console.log("kml");
    //var tj = require('@mapbox/togeojson');
    var kml = new DOMParser().parseFromString(content, "text/xml");
    var converted = tj.kml(kml);
    this.loadFeaturesFromGeoJSON(converted);
  }

  public async loadFeaturesFromGeoJSON(geoJson: any, color: number[] = null) {
    const vector = this;
    const [Graphic] = await loadModules(["esri/graphic"]);

    for (const featureJson of geoJson["features"]) {
      const featureTerraformer = new terraformer.Feature(featureJson);
      const featureArcGIS = terraformerAG.convert(featureTerraformer);

      const gr = new Graphic(featureArcGIS);
      if (gr.geometry) {
        const symbolColor = color != null ? color : this.colorOrdinal;
        const symbol = await this.getSymbol(gr.geometry.type, symbolColor);

        gr.setSymbol(symbol);
        vector.esriLayer.add(gr);
      } else {
        console.log("no geometry for ", gr);
      }
    }
  }

  async addFeature(feature: QueryFeature, colorSelected) {
    const [Graphic] = await loadModules(["esri/graphic"]);

    const gr = new Graphic({ geometry: feature.coords });
    if (!gr || !gr.geometry || !gr.geometry.type) {
      return;
    }

    const symbol = await this.getSymbol(gr.geometry.type, colorSelected);
    gr.setSymbol(symbol);
    gr.setAttributes({ guid: feature.guid });

    const esriLayer = await this.getLayer();
    esriLayer.add(gr);
  }

  async getLayer(): Promise<any> {
    if (!this.esriLayer) {
      const [GraphicsLayer, FeatureLayer] = await loadModules(["esri/layers/GraphicsLayer", "esri/layers/FeatureLayer"]);
      const layerDefinition = {
        geometryType: "esriGeometryPolygon",
        fields: [
          {
            name: "BUFF_DIST",
            type: "esriFieldTypeInteger",
            alias: "Buffer Distance",
          },
        ],
      };

      const featureCollection = {
        layerDefinition,
        featureSet: null,
      };

      this.esriLayer = new FeatureLayer(featureCollection);
      this.esriLayer.guid = Math.round(Math.random() * 100000);

      const vector = this;

      this.esriLayer.on("mouse-over", (evt) => {
        const evtGuid = evt.graphic.attributes.guid;

        if (evtGuid !== vector.selectedFeatureGUID) {
          vector.onFeatureHover.emit(evtGuid);
          vector.selectedFeatureGUID = evtGuid;
        }
      });

      if (this.config.type == ServiceType.geojson || this.config.type == ServiceType.vector) {
        if (this.config && this.config.url) {
          const content = await this.web.httpGet(this.config.url);
          this.loadFeaturesFromGeoJSON(content);
        }

        if (this.config && this.config.content) {
          this.loadFeaturesFromGeoJSON(this.config.content);
        }
      }

      if (this.config.type == ServiceType.kml) {
        if (this.config && this.config.url) {
          const contentObj = await fetch(this.config.url);
          const content = await contentObj.text();
          this.loadFeaturesFromKML(content);
        }
      }
    }

    return this.esriLayer;
  }

  loadLayerInfo(): Promise<FieldsInfo> {
    return null;
  }

  async getPointFeatureInfo(coordinates: MapClickPoint): Promise<QueryFeature[]> {
    const [geometryEngine, webMercatorUtils, Graphic, Point, SpatialReference] = await loadModules([
      "esri/geometry/geometryEngine",
      "esri/geometry/webMercatorUtils",
      "esri/graphic",
      "esri/geometry/Point",
      "esri/SpatialReference",
    ]);

    const features = [];
    const point = new Point(coordinates.x, coordinates.y, new SpatialReference(coordinates.srs));
    for (const gr of this.esriLayer.graphics) {
      const type = gr.geometry.type;

      let operator = null;
      if (type == "polygon") {
        operator = (a) => gr.geometry.contains(a);
      } else if (type == "polyline") {
        const tolerance = 1000;
        const bufferedPoint = geometryEngine.buffer(point, tolerance, "meters");
        operator = () => geometryEngine.intersects(bufferedPoint, gr.geometry);
      }

      const within = operator(point);
      if (within) {
        const feature = new QueryFeature(this.config, <WegaServiceCapabilities>{ canAttributesBeEdited: false }, this.locale);
        feature.addArcGISAtributes(this.config.title, gr, null, null);

        features.push(feature);
      }
    }

    return Promise.resolve(features);
  }

  getExtentFeatureInfo(extent: GeoExtent): Promise<QueryFeature[]> {
    return Promise.resolve([]);
  }

  getAttributesByID(featureId: string): Promise<any> {
    throw new Error("Method not implemented.");
  }

  getFeaturesByQuery(filter: ResourceFilter): Promise<[QueryFeature[], boolean]> {
    throw new Error("Method not implemented.");
  }

  setFilter(filter: any) {
    throw new Error("Method not implemented.");
  }

  saveFeatures(queryFeature: any): Promise<boolean> {
    throw new Error("Method not implemented.");
  }

  public async loadSpatialData(): Promise<LayerData[]> {
    return this.esriLayer.features;
  }
}
