import { Injectable } from "@angular/core";

@Injectable()
export class WegaDomainProvider {
  constructor() {}

  get Catalogs() {
    return {
      ArcGIS: {},
      WMS: {},
    };
  }
}
