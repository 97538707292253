import { GenericService } from "../service/generic-service";
import { FieldsInfo } from "./fields-info";
import { FieldConfig } from "@shared/config/config-field";

export class FeatureDescription {
  /// Заблокировать изменение списка полей.
  private locked: boolean;
  public get isLocked(): boolean {
    return this.locked;
  }

  /// Список полей объекта.
  public fieldsList: FieldConfig[];

  constructor(configArray: [] = null) {
    this.fieldsList = [];

    if (configArray) {
      for (const fieldConfig of configArray) {
        const newfldDesc = new FieldConfig(fieldConfig);

        this.fieldsList.push(newfldDesc);
      }
    }
  }

  public notEmpty() {
    return this.fieldsList?.length !== 0;
  }

  public addField(module: string, name: string, title: string, service: GenericService) {
    const newField = new FieldConfig();

    newField.name = name;
    newField.filterName = name;
    newField.module = module;
    newField.title = title;
    newField.service = service;

    this.fieldsList.push(newField);
    return newField;
  }

  mergeWith(fieldInfo: FieldsInfo, appendIfMissing: boolean) {
    if (this.locked) {
      // return;
    }

    for (const field of fieldInfo.fieldsList) {
      // ищется совпадение полей, внесенных в конфиг и полученных динамически
      const foundField = this.fieldsList.find((f) => f.name === field.name || f.name === field.filterName);
      if (foundField) {
        // foundField.module = foundField.module ?? field.module;
        foundField.layer = foundField.layer ?? field.layer ?? "-1";

        const getValuesFnArray = foundField.getValuesFn ? [foundField.getValuesFn] : [];
        if (field.getValuesFn) {
          getValuesFnArray.push(field.getValuesFn);
        }

        foundField.getValuesFn = (value) => {
          return new Promise((resolve) => {
            Promise.all(getValuesFnArray.map((func) => func(value))).then((arrayOfResults) => {
              const concatenatedResult = [].concat(...arrayOfResults);

              const uniqueObjects = concatenatedResult.filter((obj, index, self) => self.findIndex((o) => o.name === obj.name) === index);

              uniqueObjects.sort((a, b) => a.name.localeCompare(b.name));
              resolve(uniqueObjects);
            });
          });
        };

        foundField.type = foundField.type ?? field.type;
        foundField.service = field.service;
      } else {
        appendIfMissing && this.fieldsList.push(field);
      }
    }
  }

  /// Заблокировать изменение списка полей.
  /// Пока используется как костыль, чтобы при Identify не перезаписывать поля (которые предзагружены)
  lockFields() {
    this.locked = true;
  }

  public fillFromAtributes(attributes: any, service: GenericService) {
    if (this.locked) {
      return;
    }

    for (const moduleName in attributes) {
      for (const key in attributes[moduleName]) {
        if (!this.contains(moduleName, key)) {
          const field = this.addField(moduleName, key, key, service);
          if (attributes[moduleName][key]) {
            field.type = typeof attributes[moduleName][key];
          }
        }
      }
    }
  }

  contains(moduleName: string, fieldName: string) {
    for (const fld of this.fieldsList) {
      if (fld.module === moduleName && fld.name === fieldName) {
        return true;
      }
    }

    return false;
  }
}
