import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";
import { ResourceProvider } from "./resource.provider";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { UtilsProvider } from "@shared/providers/utils.provider";
import { EsriProvider } from "./esri.provider";
import { MapClickPoint } from "@domain/data/structures/map-click-point";
import { WegaResource } from "@domain/data/resource/wega-resource";
import { QueryFeature } from "@domain/data/feature/query-feature";
import { ArcGisService } from "@domain/data/service/arcgis/arcgis.service";
import { GenericService } from "@domain/data/service/generic-service";
import { LocaleProvider } from "../../i18n/providers/i18n.provider";
import { OlProvider } from "./ol.provider";
import { ServiceType } from "@shared/wega-utils/wega-enums";
import { WegaUtils } from "@shared/wega-utils/wega-utils";
import { QueryHoverFilter } from "@shared/wega-utils/global-config";
import { LayerProvider } from "./layer.provider";
import { ArcGisMapProvider } from "../../wega-ui/components/arcgis-map/providers/arcgis-map.provider";

export class AttributeTable {
  title: string;
  layerId: string;
  id: string;
  type: ServiceType;
  featureLayerAG: any;
  featureTableAG: any;
  anyOtherData: any;
  underlyingService: GenericService;
}

@Injectable()
export class AttributeProvider {
  constructor(
    private esri: EsriProvider,
    private ag: ArcGisMapProvider,
    private ol: OlProvider,
    private res: ResourceProvider,
    private utils: UtilsProvider,
    private layerProvider: LayerProvider,
    private appConfig: AppConfigProvider,
    private locale: LocaleProvider
  ) {}

  getAttributeTable(layerName: string, service: GenericService): AttributeTable {
    if (service.getConfig().type.toString() === ServiceType.arcgis.toString() || service.getConfig().type.toString() === ServiceType.arcgistiled.toString()) {
      const table = new AttributeTable();

      table.title = layerName; // "Таблица для слоя \'" + this.current.layer + "\'";
      table.type = ServiceType.arcgis;
      table.id = WegaUtils.createGuid();
      table.layerId = layerName;
      table.underlyingService = service;

      return table;
    }
  }

  async getFeatureDataForMapPoint(screenPoint: MapClickPoint, res: ResourceProvider): Promise<string> {
    const [Polygon] = await this.esri.loadModules(["esri/geometry/Polygon"]);
    const emulatedResources: WegaResource[] = res.resourceList.map((r) => {
      const emulatedResource = new WegaResource(
        r.config,
        res.web,
        this.esri,
        this.ag,
        this.ol,
        this.utils,
        this.appConfig,
        this.locale,
        this.layerProvider,
        -1
      );

      emulatedResource.selectedFeatures = [];

      r.serviceModuleList.forEach((s, a) => {
        emulatedResource.serviceModuleList[a] = r.serviceModuleList[a];
      });

      return emulatedResource;
    });

    // далее идет код, собранный по кускам из различных сегментов ResourceModule
    // TODO: привести к единой кодовой базе данный класс и ResourceModule
    for (let i = 0; i < emulatedResources.length; i++) {
      const resource = emulatedResources[i];

      for (let i = 0; i < resource.serviceModuleList.length; i++) {
        const serviceClass = resource.serviceModuleList[i];

        if (serviceClass.supportsClick()) {
          try {
            const fts = await serviceClass.getPointFeatureInfo(screenPoint);

            if (fts) {
              resource.selectedFeatures = resource.selectedFeatures.concat(fts);
            }
          } catch (ex) {
            console.log(ex);
          }
        }

        resource.selectedFeatures.forEach((ft) => {
          ft.service = serviceClass;
        });
      }

      for (const feature of resource.selectedFeatures) {
        resource.featureDescription.fillFromAtributes(feature.attributes, feature.service);
      }

      if (resource.selectedFeatures) {
        for (let j = 0; j < resource.selectedFeatures.length; j++) {
          const feature = resource.selectedFeatures[j];

          if (feature && feature.id) {
            const featureID = feature.id;

            for (let i = 0; i < resource.serviceModuleList.length; i++) {
              const service = resource.serviceModuleList[i];
              const attributes = await service.getAttributesByID(featureID);

              if (attributes) {
                if (typeof attributes === "string") {
                  feature.addHtmlResponse(service.getConfig().title, attributes);
                } else {
                  feature.addAtributes(service.getConfig().title, attributes);
                  resource.featureDescription.fillFromAtributes(feature.attributes, service);
                }
              }
            }
          }
        }
      }
    }

    const data: QueryFeature[][] = emulatedResources.map((r) => r.selectedFeatures);
    const features = data.reduce((f, n) => f.concat(n));

    const layersCount = data.length;
    const featuresCount = features.length; // data.map(n => n.length).reduce((f,n) => f + n);
    const sampleSize = 5;

    const queryHoverFilters = [
      {
        field: "Nom1000",
        title: "Номенклатурный лист",
        predicate: (attributes) => Object.keys(attributes).length === 18,
        useForExtent: () => true,
      },
      {
        field: "Nom200",
        title: "Номенклатурный лист (200)",
        predicate: (attributes) => Object.keys(attributes).length !== 18,
        useForExtent: () => true,
      },
      {
        field: "Название округа",
        title: "Федеральный округ",
        useForExtent: () => true,
      } as QueryHoverFilter,

      {
        field: "ADMIN_L3",
        title: "Федеральный округ",
        predicate: (attributes) => Object.keys(attributes).length === 9,
      } as QueryHoverFilter,

      {
        field: "ADMIN_L4",
        title: "Субъект федерации",
        predicate: (attributes) => Object.keys(attributes).length === 9,
      } as QueryHoverFilter,

      {
        // layer: 'FO_2500',
        field: "NAME",
        title: "Муниципальное образование",
        predicate: (attributes) => Object.keys(attributes).length === 9,
        useForExtent: () => true,
      },
    ];

    let fields = [];
    let examples = [];

    if (!queryHoverFilters.length) {
      // var allAttributes = [];
      // var allCoords = [];

      if (!!featuresCount) {
        data.forEach((d) => {
          // var attrs = d.map(di => di.attributes[Object.keys(di.attributes)[0]]);
          // var coords = d.map(di => di.coords);

          // allAttributes = allAttributes.concat(attrs);
          // allCoords = allCoords.concat(coords);

          try {
            const firstOccurence = d[0].attributes;
            const firstKeyDataOccurence = Object.keys(firstOccurence)[0];

            const ats = Object.keys(firstOccurence[firstKeyDataOccurence]);
            fields = fields.concat(ats);
          } catch {}
        });

        this.shuffleArray(fields);
        examples = fields.slice(0, sampleSize);
      }
    }

    if (featuresCount !== 0) {
      const info = [
        "<span style=''>В данном участке присутствуют сведения </span>",
        "<span style=''> " + (featuresCount === 1 ? "об" : "о") + " </span>",
        "<span style='font-weight: bold;'>" + featuresCount + "</span>",
        "<span style=''> " + (featuresCount === 1 ? "объекте" : "объектах") + " в </span>",
        "<span style='font-weight: bold;'>" + layersCount + "</span>",
        "<span style=''> " + (layersCount === 1 ? "слое" : "слоях") + ".</span>",
        "</br>",
        "</br>",
      ];

      if (queryHoverFilters.length) {
        const anotherInfo = [];

        queryHoverFilters.forEach((qhf) => {
          const field = qhf.field;
          let found = false;

          features.forEach((ft, i) => {
            const ats = ft.attributes;
            const atk = Object.keys(ats)[0];
            const attributes = ats[atk];
            const predicate = qhf.predicate || ((d: {}) => true);
            const fn = predicate(attributes) && attributes[field];
            const useExtent = (qhf.useForExtent || ((d: {}) => false))(attributes);
            const foundValues = [];

            if (fn) {
              if (found) {
                if (foundValues.indexOf(fn) !== -1) {
                  anotherInfo.push(", " + fn);
                  foundValues.push(fn);
                }
              } else {
                if (useExtent) {
                  const coords = ft.coords;

                  // TODO: избавиться от хака
                  window[["__wega_hack_attr", i].join("")] = () => {
                    const polygon = new Polygon(coords);
                    const extent = polygon.getExtent();

                    window["__agmap"]().setExtent(extent);
                  };
                }

                if (useExtent) {
                  anotherInfo.push(
                    "<span style=''>" +
                    qhf.title +
                    ": </span><span style='color: blue; font-weight: bold; cursor: pointer;' onclick='window[\"__wega_hack_attr" +
                    i +
                    "\"](); return false;'>" +
                    fn
                  );
                } else {
                  anotherInfo.push("<span style=''>" + qhf.title + ": </span><span style='font-weight: bold;'>" + fn);
                }

                found = true;
              }
            }
          });

          if (found) {
            anotherInfo.push("</span></br>");
          }
        });

        return info.concat(anotherInfo).join("");
      } else {
        return info
          .concat([
            "<span style=''>Эти объекты, в частности, содержат информацию о: </span>",
            "<span style='font-weight: bold;'>" + examples.join("; ") + "</span>",
            "<span style=';'> и др. (всего показателей: </span>",
            "<span style='color: blue; font-weight: bold;'>" + fields.length + "</span>",
            "<span style=''>).</span>",
          ])
          .join("");
      }
    } else {
      return ["<span style='color: red;'>Этот участок не содержит никаких данных.</span>"].join("");
    }
  }

  shuffleArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));

      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  findFeatureUrl(table: AttributeTable): string {
    const sourceUrl = table.underlyingService.getConfig().url;

    try {
      if (table.type === ServiceType.arcgis) {
        const layersInfo = (table.underlyingService.getMapService() as ArcGisService).getArcGISLayersInfo();
        let targetLayerId = null;

        Object.keys(layersInfo).forEach((lk) => {
          const layer = layersInfo[lk];

          // if (l.name == table.layerId) {
          if (layer.id.toString() === table.layerId.toString()) {
            targetLayerId = layer.id;
          }
        });

        if (!targetLayerId) {
          throw new Error("No URL found");
        } else {
          return sourceUrl + "/" + targetLayerId;
        }
      } else {
        throw new Error("Wrong service");
      }

      // return table.underlyingService.getConfig().url;
    } catch (e) {
      console.warn(`Не удалось найти url для FeatureService: ${sourceUrl}`);
      return sourceUrl + "/0";
    }
  }

  tableToRowArray(rows: any, options?: { truncateAt: number }): Array<Array<any>> {
    const data = rows;
    const table = [];
    const keys = Object.keys(data[0]);

    table.push(keys);

    for (let index = 0; index < data.length; index++) {
      const row = [];
      const item = data[index];

      keys.forEach((key) => {
        let value = item[key];

        if (options?.truncateAt && value && value.length > options.truncateAt) {
          value = value.substring(0, options.truncateAt);
        }

        row.push(value);
      });

      table.push(row);
    }

    return table;
  }

  async exportToXLSX(title, rows) {
    const XLSX = await import("xlsx");
    const sheet = this.tableToRowArray(rows, { truncateAt: 32767 });
    const ws = XLSX.utils.aoa_to_sheet(sheet);
    const wb = XLSX.utils.book_new();

    wb.Props = {
      Title: "data-excel",
      Subject: "",
      Author: "Институт Карпинского",
      CreatedDate: new Date(),
    };

    XLSX.utils.book_append_sheet(wb, ws, "Таблица 1");
    XLSX.writeFile(wb, `${title}.xlsx`);
  }

  exportToCSV(title, rows) {
    const sheet = this.tableToRowArray(rows);
    const csvContent = "data:text/csv;charset=utf-8," + sheet.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");

    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${title}.csv`);
    link.click();
    link.remove();
  }

  exportToXML(title, rows) {
    const refEntity = (s: string) => {
      return this.removeXMLInvalidChars(
        s.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/&/g, "&amp;").replace(/'/g, "&apos;").replace(/"/g, "&quot;"),
        false
      );
    };

    const data = rows;
    const keys = Object.keys(data);

    let xml = `<?xml version='1.0' encoding='UTF-8'?>\n\t<table layer='${title}'>\n`;

    for (let i = 0; i < keys.length; i++) {
      const attributes = data[keys[i]];
      const keysAttr = Object.keys(attributes);

      xml += "\t\t<row index='" + i + "'>\n";

      for (let j = 0; j < keysAttr.length; j++) {
        const attrName = keysAttr[j];
        const attrValue = attributes[attrName];

        xml += "\t\t\t<attribute name='" + refEntity("" + attrName) + "' value='" + refEntity("" + attrValue) + "'></attribute>\n";
      }

      xml += "\t\t</row>\n";
    }

    xml += "\t</table>";
    saveAs(new Blob([xml], { type: "application/octet-stream" }), title + ".xml");
  }

  // https://gist.github.com/john-doherty/b9195065884cdbfd2017a4756e6409cc
  removeXMLInvalidChars(text: string, removeDiscouragedChars: boolean): string {
    text = String(text || "").replace(this.regex, "");

    if (removeDiscouragedChars) {
      text = text.replace(this.altRegex, "");
    }

    return text;
  }

  regex = /((?:[\0-\x08\x0B\f\x0E-\x1F\uFFFD\uFFFE\uFFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF]))/g;
  altRegex = new RegExp(
    "([\\x7F-\\x84]|[\\x86-\\x9F]|[\\uFDD0-\\uFDEF]|(?:\\uD83F[\\uDFFE\\uDFFF])|(?:\\uD87F[\\uDF" +
    "FE\\uDFFF])|(?:\\uD8BF[\\uDFFE\\uDFFF])|(?:\\uD8FF[\\uDFFE\\uDFFF])|(?:\\uD93F[\\uDFFE\\uD" +
    "FFF])|(?:\\uD97F[\\uDFFE\\uDFFF])|(?:\\uD9BF[\\uDFFE\\uDFFF])|(?:\\uD9FF[\\uDFFE\\uDFFF])" +
    "|(?:\\uDA3F[\\uDFFE\\uDFFF])|(?:\\uDA7F[\\uDFFE\\uDFFF])|(?:\\uDABF[\\uDFFE\\uDFFF])|(?:\\" +
    "uDAFF[\\uDFFE\\uDFFF])|(?:\\uDB3F[\\uDFFE\\uDFFF])|(?:\\uDB7F[\\uDFFE\\uDFFF])|(?:\\uDBBF" +
    "[\\uDFFE\\uDFFF])|(?:\\uDBFF[\\uDFFE\\uDFFF])(?:[\\0-\\t\\x0B\\f\\x0E-\\u2027\\u202A-\\uD7FF\\" +
    "uE000-\\uFFFF]|[\\uD800-\\uDBFF][\\uDC00-\\uDFFF]|[\\uD800-\\uDBFF](?![\\uDC00-\\uDFFF])|" +
    "(?:[^\\uD800-\\uDBFF]|^)[\\uDC00-\\uDFFF]))",
    "g"
  );
}
