import { LegendRender } from "./legend-render";
import { LegendRecord } from "./legend-record";

export class LegendParser {
  static defaultParsers: { [pid: string]: (lr: LegendRender) => void } = {
    "ag-font": (lr: LegendRender) => {
      const matches = LegendParser.isHtmlString;
      const transform = LegendParser.transformArcGisString;

      lr.recordsList.forEach((rec) => {
        if (matches(rec.sourceLabel)) {
          rec.displayLabel = transform(rec);
          rec.injectAsHtml = true;
        }
      });
    },
  };

  static transform(render: LegendRender, parserId: string): void {
    const parseFn = this.defaultParsers[parserId];

    if (!parseFn) {
      console.warn(`Парсер ${parserId} не зарегистрирован!`);
    } else {
      parseFn(render);
    }
  }

  static isHtmlString(text: string): boolean {
    const parseResult = new DOMParser().parseFromString(text, "text/html");

    return Array.from(parseResult.body.childNodes).some((node) => node.nodeType === 1);
  }

  static transformArcGisString(record: LegendRecord): string {
    const fontMatch = record.sourceLabel.match(/<fnt\s{1,}name\s{0,}=\s{0,}"(.*?)">(.*?)<\/fnt>(.*)/i);

    if (fontMatch != null) {
      const contentWithFont = fontMatch[2];
      const fontName = fontMatch[1];
      const contentWithNoFont = fontMatch[3];

      return `<span style='font-family: ${fontName};'>${contentWithFont}</span><span>${contentWithNoFont}</span>`;
    }

    return record.sourceLabel;
  }
}
