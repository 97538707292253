import { APP_INITIALIZER, Provider, InjectionToken, inject } from "@angular/core";
import { Routes } from "@angular/router";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { UtilsProvider } from "@shared/providers/utils.provider";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { RouteConfigResolver } from "@shared/resolvers/route.resolver";

export const appConfigLoader = (config: AppConfigProvider) => () => Promise.all([config.load()]);

export const AppProviders: Provider[] = [
  UtilsProvider,
  WebClientProvider,

  AppConfigProvider,
  {
    provide: APP_INITIALIZER,
    deps: [AppConfigProvider],
    multi: true,
    useFactory: appConfigLoader,
  },
];

export const AppRoutes: Routes = [
  {
    path: "site/:siteCode",
    resolve: { _: RouteConfigResolver },
    loadChildren: () => import("./modules/wega3/wega3.module").then((m) => m.Wega3Module),
  },

  { path: "", redirectTo: "site/cdnr", pathMatch: "full" },
  { path: "site", redirectTo: "site/cdnr", pathMatch: "full" },

  {
    path: "health",
    loadChildren: () => import("./modules/service-health/service-health.module").then((m) => m.ServiceHealthModule),
  },

  { path: "**", redirectTo: "site/cdnr" },
];
