export class FilterRecord {
  public attrName: string;
  public attrValue: string;
  public operator: string;
  public enabled: boolean;
  public sequenceCondition: "AND" | "OR" = "AND";

  constructor(name: string, value: string, operator: string = null) {
    this.attrName = name;
    this.attrValue = value;
    this.operator = operator || "like";
    this.enabled = true;
  }

  wrapWithQuotesIfNotNumber(value: any): string {
    /// запрос, выполняющийся при формировании шейпа срабатывает, только если численные значения не заключаются в кавычки
    /// ...&where=OBJECTID >= '1' AND OBJECTID < '1001'&... - ошибка
    /// ...&where=OBJECTID >= 1 AND OBJECTID < 1001&... - работает
    return !isNaN(value) ? value : "'" + value + "'";
  }

  getSqlRecord(caseSensitive: boolean = false) {
    let attrName = this.attrName;
    let attrValue = this.attrValue;

    if (!this.enabled) {
      return "";
    }

    let attrValueClean = attrValue;
    let attrValuesListClean = [attrValue];

    // в SQL одиночные кавычки - спецсимвол. Вместо этого используется две кавычки напр: x = 'i''am'
    if (Array.isArray(attrValue)) {
      attrValuesListClean = <string[]>attrValue.map((value) => value.replace(/'/g, "''"));
    } else {
      attrValueClean = attrValue.replace(/'/g, "''");
    }

    switch (this.operator.trim().toLocaleLowerCase()) {
      case "=":
        if (!attrValueClean || attrValueClean.trim().toLowerCase() === "null") {
          return attrName + " is null ";
        }

        return attrName + " = " + this.wrapWithQuotesIfNotNumber(attrValueClean);

      case "<>":
      case "!=":
        if (!attrValueClean || attrValueClean.toLowerCase().trim() === "null") {
          return attrName + " is not null ";
        }

        return attrName + " <> " + this.wrapWithQuotesIfNotNumber(attrValueClean);

      case "like":
      case "not like":
        attrName = caseSensitive ? attrName : `lower(${attrName})`;
        attrValueClean = attrValueClean.replace("*", "%");
        attrValueClean = caseSensitive ? attrValue : attrValue.toLowerCase();
        return attrName + " " + this.operator + " '%" + attrValueClean + "%'";

      case "starts":
        attrName = caseSensitive ? attrName : `lower(${attrName})`;
        attrValueClean = caseSensitive ? attrValue : attrValue.toLowerCase();
        return attrName + " like '" + attrValueClean + "%'";

      case "ends":
        attrName = caseSensitive ? attrName : `lower(${attrName})`;
        attrValueClean = caseSensitive ? attrValue : attrValue.toLowerCase();
        return attrName + " like '%" + attrValueClean + "'";

      case "null":
      case "not null":
        return attrName + " is " + this.operator;

      case "is null":
      case "is not null":
        return attrName + " " + this.operator;

      case "in":
        return attrName + " in (" + attrValuesListClean.join(",") + ")";

      case ">":
        return attrName + " > " + attrValueClean;

      case "<":
        return attrName + " < " + attrValueClean;

      default:
        return attrName + " " + this.operator + " " + this.wrapWithQuotesIfNotNumber(attrValueClean);
    }
  }
}
