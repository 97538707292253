import { WegaSearchResult, StatefulSearchEngine } from "../../search.engine";
import { UtilsProvider } from "@shared/providers/utils.provider";
import { ArcGisMapProvider } from "src/app/modules/wega-ui/components/arcgis-map/providers/arcgis-map.provider";
import { EsriProvider } from "@wega-providers/esri.provider";
import { ResourceProvider } from "@wega-providers/resource.provider";
import { DynamicDatabase } from "@wega3/components/catalog-tree/dynamic-database";
import { CatalogNode } from "@wega3/components/catalog-tree/catalog-node";
import { ConfigResource } from "@shared/config/config-resource";
import { UiProvider } from "@wega3/providers/ui.provider";
import { WegaResource } from "@domain/data/resource/wega-resource";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";

export class CacheSearchEngine extends StatefulSearchEngine {
  constructor(
    private utils: UtilsProvider,
    private res: ResourceProvider,
    private ui: UiProvider,
    private arcgis: ArcGisMapProvider,
    private esri: EsriProvider,
    private locale: LocaleProvider
  ) {
    super();
  }

  get title(): string {
    return this.locale.current === "en" ? "Cached attribute data API" : "Веб-API модуля кэшированных атрибутивных данных";
  }
  get description(): string {
    return this.locale.current === "en"
      ? "Search using the cached attribute data web service (https://astr.staging.vsegei.ru)"
      : "Поиск через веб-сервис кэшированных атрибутивных данных (https://astr.staging.vsegei.ru)";
  }

  doesResourceContainConfig(configRes: ConfigResource, { url, layer }) {
    return !!configRes.servicesList.find((service) => {
      return service.url === url && -1 !== service.layers.indexOf(layer);
    });
  }

  cachedResourceComparator = ({ url, layer }) => {
    return {
      values: [void 0], // в данном компараторе это поле не требуется, поэтому используется просто заглушка
      scan: ({ _source: node }) => {
        const configRes = <ConfigResource>node.resource;
        const itDoes = this.doesResourceContainConfig(configRes, {
          url,
          layer,
        });

        return itDoes;
      },
    };
  };

  async engineSearch(text: string): Promise<WegaSearchResult[]> {
    const url = `https://astr.staging.vsegei.ru/api/searchAll`;

    // почему-то иногда с первого раза запрос к ASTR не срабатывает
    // поэтому сейчас приходится при неудаче высылать повторный запрос
    // потом надо будет как-нибудь с этим разобраться
    const json = await this.utils.retry(async () => {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          SearchText: `${text}`,
        }),
        headers: { "Content-Type": "application/json" },
      });

      return await response.json();
    });

    !json && this.signal("Не удалось установить соединение с сервером атрибутивных данных");

    // делегат сложного поиска (WegaCompareFilter) не используется, т.к. парсинг строки происходит на сервере
    // однако ASTR не возвращает список фраз для подсветки; чтобы получить их на клиенте, эмулируется поиск по "пустому" тексту
    const highlightedValues = this.comparator(text).values;

    return (json ?? []).map((found: any, index: number) => {
      const layerUrl = found["URL"];
      const layerNum = found["Номер слоя"];
      const objectId = found["ИД объектов"];

      return {
        cancelHighlight: true,
        name: this.locale.current === "en" ? `Object ${index + 1}` : `Объект ${index + 1}`,
        description: this.locale.current === "en" ? `ID of the found object: ${objectId}` : `Идентификатор найденного объекта: ${objectId}`,

        source:
          this.locale.current === "en"
            ? `Service '${layerUrl}', layer '${found["Название слоя"]}', layer number '${layerNum}'.`
            : `Сервис '${layerUrl}', слой '${found["Название слоя"]}', номер слоя '${layerNum}'.`,

        values: highlightedValues,
        highlight: () => this.highlightFeature(layerUrl, layerNum, objectId),
        details: () => this.showDetails(layerUrl, layerNum, objectId),
      };
    });
  }

  async highlightFeature(url: string, layer: string, objectId: string): Promise<boolean> {
    // находим конфиг ресурса в каталоге карт, а также его самого в перечне уже добавленных на карту ресурсов
    const [configRes, existingResource] = await this.findResource({
      url,
      layer,
      oid: objectId,
    });

    if (!existingResource) {
      // ресурс не был добавлен на карту
      // надо создать его из найденного зарегистрированного в каталоге конфига
      const resource = await this.res.addResourceConfig(configRes);

      const { feature, marker } = await this.esri.zoomToFeature({ field: "OBJECTID", resource, id: objectId, map: this.arcgis.EsriMap }, true);
      if (feature) {
        // почему-то не всегда возвращает результат
        await this.esri.flashFeature({
          resource,
          id: objectId,
          feature,
          res: this.res,
        });
        await this.esri.clearMarker(marker, this.arcgis.EsriMap);
      } else {
        // приходится использовать "обходный" метод
        const _marker = await this.esri.directZoomFeatureById({ url, layer, id: objectId, map: this.arcgis.EsriMap }, true);
        await this.esri.clearMarker(_marker, this.arcgis.EsriMap);
      }

      return Promise.resolve(true);
    } else {
      // ресурс уже был добавлен на карту
      // удалить ресурс с карты
      this.res.removeResource(existingResource);
      await this.esri.clearAllMarkers(this.arcgis.EsriMap);

      return Promise.resolve(false);
    }
  }

  private async findResource({ url, layer, oid }): Promise<[ConfigResource, WegaResource]> {
    // находим ресурс в каталоге карт
    const configRes = await this.findResourceConfig({ url, layer });
    if (!configRes) {
      if (this.locale.current === "en") {
        this.utils.notify("Could not find the resource in the map directory! Perhaps there was an out of sync with the remote database.");
      } else {
        this.utils.notify("Не удалось найти ресурс в каталоге карт! Возможно, произошла рассинхронизация c удаленной БД.");
      }

      return void 0;
    }

    // найти существующий ресурс (на основе зарегистрированного в каталоге конфига)
    const existingResource = this.res.resourceList.find((r) => this.doesResourceContainConfig(r.config, { url, layer }));

    if (existingResource) {
      return [configRes, existingResource];
    }

    return [configRes, void 0];
  }

  async showDetails(url: string, layer: string, objectId: string) {
    let [configRes, resource] = await this.findResource({
      url,
      layer,
      oid: objectId,
    });

    if (!resource) {
      resource = await this.res.addResourceConfig(configRes);
    }

    await this.esri.queryByFeatureId({ resource, id: objectId });
    this.ui.toggleInfo(true);
  }

  async findResourceConfig({ url, layer }): Promise<ConfigResource> {
    const catalogDb: DynamicDatabase = window["CATALOGDB"];
    const cacheComparator = this.cachedResourceComparator({ url, layer });
    let found = <CatalogNode[]>[];

    for await (const catalog of catalogDb.catalogsList) {
      if (catalog.searchScanner) {
        const res = await catalog.searchScanner.search(catalog, "", cacheComparator);

        found = found.concat(res);
      }
    }

    return 0 !== found.length ? found[0].resource : void 0;
  }
}
