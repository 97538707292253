import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import * as xml2js from "xml-js";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { WegaUtils } from "@shared/wega-utils/wega-utils";

@Injectable({ providedIn: "root" })
export class WebClientProvider {
  constructor(public client: HttpClient) {}

  async httpGet<T extends unknown>(
    sourceUrl: string,
    params: { headers?: any; queryParams?: any; responseType?: any } = {
      headers: {},
      queryParams: {},
      responseType: "json",
    },
    disableCache: boolean = false
  ): Promise<T> {
    const headers = new HttpHeaders(params.headers);
    if (disableCache) {
      headers.set("Cache-Control", "no-cache, no-store, must-revalidate, post-check=0, pre-check=0");
      headers.set("Pragma", "no-cache");
      headers.set("Expires", "0");
    }

    const res = await this.client
      .get(sourceUrl, {
        responseType: params.responseType,
        headers,
        params: new HttpParams({ fromObject: { ...params.queryParams } }),
      })
      .toPromise();

    return <T>res;
  }

  createProxiedUrl(url: string, proxy: string, encodeQueryParams: boolean) {
    let _url = url;
    if (proxy) {
      const proxiedUrl = new URL(`${proxy}${url}`, window.location.origin).href;
      _url = encodeQueryParams ? this.encodeUrlQueryParams(proxiedUrl) : proxiedUrl;
    }

    return _url;
  }

  encodeUrlQueryParams(sourceUrl: string) {
    const [url, ...rest] = sourceUrl.split("?");
    const alreadyEncoded = decodeURIComponent(rest.join("?")) !== rest.join("?");
    if (alreadyEncoded) {
      return sourceUrl;
    }

    return `${url}?${encodeURIComponent(rest.join("?"))}`;
  }

  urlAvailable(url: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.client
        .get(`${url}?nocache=${WegaUtils.createGuid()}`)
        .pipe(catchError((error: HttpErrorResponse) => of(error.status || 404)))
        .subscribe((res) => resolve(res.toString().substring(0, 1) == "2"));
    });
  }
}
