import { Component, OnInit, Inject, OnDestroy } from "@angular/core";

@Component({
  selector: "wega-login-form",
  template: "",
})
export class WegaLoginComponent implements OnInit, OnDestroy {
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
