import { WegaSearchResult, StatefulSearchEngine } from "../../search.engine";
import { AreaFilterProvider } from "@wega-providers/area-filter.provider";
import { FilterItem } from "src/app/modules/wega-providers/utils/area-filter.utils";
import { MapFiltersComponent } from "@wega3/components/filter-spatial/map-filters.component";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";

export class SpatialFiltersSearchEngine extends StatefulSearchEngine {
  constructor(
    private area: AreaFilterProvider,
    private locale: LocaleProvider
  ) {
    super();
  }

  get title(): string {
    return this.locale.current === "en"
      ? "Administrative division"
      : "Административное деление";
  }
  get description(): string {
    return this.locale.current === "en"
      ? "Spatial filter list search"
      : "Поиск по списку пространственных фильтров";
  }

  async engineSearch(text: string): Promise<WegaSearchResult[]> {
    const filters = this.area.availableItems;
    const comparator = this.comparator(text);

    return filters
      .filter((filter) => comparator.scan({ sample: filter.title }))
      .map((filter) => {
        return <WegaSearchResult>{
          name: filter.title,
          open: () => this.activateFilter(filter),
          values: comparator.values,
        };
      });
  }

  activateFilter(filter: FilterItem): Promise<boolean> {
    const windowKey = "__filterSearchSet";
    const filterSetBySearch = window[windowKey];
    const spatialFilterComponent = <MapFiltersComponent>window["AREAFILTER"];
    const filterExists = spatialFilterComponent.globalSpatialFilterExists();

    // фильтр уже был задан из окна поиска
    if (filterExists && filterSetBySearch) {
      spatialFilterComponent.resetFilter();
      window[windowKey] = undefined;

      return Promise.resolve(false);
    }

    // фильтр уже был задан из интерфейса фильтра
    else if (!filterSetBySearch && filterExists) {
      spatialFilterComponent.resetFilter();
      window[windowKey] = windowKey;
      spatialFilterComponent.applySpatialFilter({ item: filter });

      return Promise.resolve(true);
    }

    // фильтр не был задан
    else if (!filterExists) {
      window[windowKey] = windowKey;
      spatialFilterComponent.applySpatialFilter({ item: filter });

      return Promise.resolve(true);
    }
  }
}
