import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { forkJoin, Observable, ReplaySubject, Subject } from "rxjs";

@Injectable()
export class AssetLoaderProvider {
  private loadedAssets: { [url: string]: ReplaySubject<void> } = {};

  constructor(@Inject(DOCUMENT) private readonly document: any) {}

  lazyLoad(assets: string[]): Promise<void> {
    return new Promise((r) => {
      forkJoin(assets.map((url) => this.loadAsset(url))).subscribe((_) => r());
    });
  }

  private loadAsset(url: string): Observable<void> {
    const loadedAsset = this.loadedAssets[url];

    if (loadedAsset) {
      return loadedAsset.asObservable();
    }

    const asset = new ReplaySubject<void>();
    const type = url.split(".").pop();

    type === "js" && this.loadJs(url);
    type === "css" && this.loadCss(url);

    this.loadedAssets[url] = asset;
    return asset.asObservable();
  }

  private loadJs(url: string) {
    const script = this.document.createElement("script");

    script.type = "text/javascript";
    script.async = true;
    script.src = url;

    script.onload = () => {
      this.loadedAssets[url].next();
      this.loadedAssets[url].complete();
    };

    this.document.body.appendChild(script);
  }

  private loadCss(url: string) {
    const style = this.document.createElement("link");

    style.type = "text/css";
    style.href = url;
    style.rel = "stylesheet";

    style.onload = () => {
      this.loadedAssets[url].next();
      this.loadedAssets[url].complete();
    };

    const head = document.getElementsByTagName("head")[0];
    head.appendChild(style);
  }
}
