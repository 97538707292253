import { GenericService } from "@domain/data/service/generic-service";

export class FieldConfig {
  /** Имя сервиса в котором присутствует это поле (для многосервисных ресурсов). */
  public module: string;

  /** Имя поля в сервисе и в массиве. */
  public name: string;

  /** Имя поля, использумое при поиске (в ArcGIS совпадает с title). */
  public filterName: string;

  /** Имя поля, отображаемое для пользователя. */
  public title: string;

  /** Описание поля (отображается пользователю) */
  public description: string;

  /** Примеры значений, допустимых в поле (отображается пользователю). */
  public examples: string;

  /** Наборы, в которые включено поле (для переключения наборов полей при отображении).
   * Если groups = ["A", "B"], то поле отображается пользователю только тогда, когда он выберет группу(вкладку) A или B.
   */
  public groups: string[];

  /** Поле отображается как форматированный текст. */
  public formatted: boolean;

  /** Список групп пользователей, которы разрешен просмотр поля. Если пусто, то всем. */
  public allowReadGroups: string[];

  /** Список групп пользователей, которы разрешено редактирование поля. Если пусто, то всем. */
  public allowEditGroups: string[];

  /** Тип данных, допустимый в поле (string, number, date, dictionary).
   * Может использоваться для валидации.
   */
  public type: string;

  /** Если один элемент - то первый элемент используется как префикс для значения (при отображении добавляется перед значением поля).
   * Если два - то первый используется как строка замены для найденных сопоставлений, а второй - как поисковое регулярное выражение.
   */
  public urlPrefix: string[];

  /** Допустимые значения в поле (напр. словарные). */
  possibleValues: any;

  /** Имя слоя, в котором находится поле. */
  layer: string;

  /** Не выводить поле при запросе. */
  hidden: boolean;

  /** Ссылка на сервис, служащий источником поля. */
  public service: GenericService;

  getValuesFn: (value: any) => Promise<any[]>;

  constructor(configArray: [] = null) {
    if (configArray) {
      this.module = configArray["module"];
      this.name = configArray["name"];
      this.title = configArray["title"];
      this.description = configArray["description"];
      this.examples = configArray["examples"];
      this.groups = configArray["groups"];
      this.type = configArray["type"];
      this.possibleValues = configArray["possibleValues"];
      this.filterName = configArray["filterName"] ?? this.name;
      this.urlPrefix = configArray["urlPrefix"] ?? void 0; //this.name;
    }
  }

  public validate(value: string): string {
    return value;
  }

  /** Получить список допустимых значения для поля (например, словарные значения или уникальные значения в БД). */
  public async getPossibleValues(template: string): Promise<any[]> {
    if (this.possibleValues) {
      return this.possibleValues;
    }

    if (this.getValuesFn) {
      return await this.getValuesFn(template);
    }

    return [];
  }

  addUniqueValuesQuery(fn: (value: any) => Promise<any[]>) {
    this.getValuesFn = fn;
  }

  addArcGISCodedValues(codedValues: any) {
    this.possibleValues = codedValues;
  }

  equals(another: FieldConfig) {
    return (
      this.module == another.module &&
      this.name == another.name &&
      this.filterName == another.filterName &&
      this.title == another.title &&
      this.description == another.description
    );
  }
}
