import { QueryFeature } from "./query-feature";
import { FieldConfig } from "@shared/config/config-field";
import { ShapeEncoding } from "@shared/config/config-service";
import { ExportToShape } from "./shapefile-export";
import { WegaUtils } from "@shared/wega-utils/wega-utils";

declare var navigator: any;

export class FeatureExport {
  private projection4326 = `GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137,298.257223563]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]]`;

  /// проекцию для веб-меркатора получил с помощью ArcCatalog - задал проекцию 3857 и скопировал содержимое PRJ
  /// (отличается от того, что на сайте spatialreference.org)
  ///  запись должна быть именно такой - без переносов строк, иначе ArcGIS не читает
  private projection3857 = `PROJCS["WGS_1984_Web_Mercator_Auxiliary_Sphere",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator_Auxiliary_Sphere"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",0.0],PARAMETER["Auxiliary_Sphere_Type",0.0],UNIT["Meter",1.0]]`;

  constructor() {}

  duplicateStructure<T = any>(object: any): T {
    const seen = [];
    let duplicate = JSON.stringify(object, (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.includes(value)) {
          return;
        }

        seen.push(value);
      }

      return value;
    });

    return JSON.parse(duplicate);
  }

  convert(agfeatures: any[], attrs: QueryFeature[], fields: FieldConfig[]): { features; attributes } {
    const features = [];
    const attributes = [];

    let start = agfeatures.length; // 100000;
    const oidField = "OBJECTID"; // TODO: динамически определять наименование поля

    agfeatures.forEach((feature, index) => {
      if (feature.geometry.type === "multipoint") {
        /// JS2Shapefile не способна преобразовывать multipoint [#5105]
        /// поэтому предварительно multipoint конвертируется в набор point
        const numPoints = feature.geometry.points.length;

        for (let i = 0; i < numPoints; i++) {
          const point = feature.geometry.getPoint(i);

          const guid = `mp-${WegaUtils.createGuid()}-${i}`;
          const newOid = (start++).toString();

          const newFeatureAttrs = this.duplicateStructure(feature.attributes);
          newFeatureAttrs.guid = guid;
          newFeatureAttrs[oidField] = newOid;

          const newAttrs = this.duplicateStructure(attrs[index]);
          const moduleName = Object.keys(newAttrs.attributes)[0]; // TODO: вероятно, не оптимальный вариант определения названия модуля
          newAttrs.guid = guid;

          newAttrs.attributes[moduleName][oidField] = newOid;
          newAttrs.arcgisFeature && newAttrs.arcgisFeature.attributes && (newAttrs.arcgisFeature.attributes[oidField] = newOid);

          newAttrs.getFlatAttributes = QueryFeature.prototype.getFlatAttributes.bind(newAttrs);

          features.push({ geometry: point, attributes: newFeatureAttrs });
          attributes.push(newAttrs);
        }
      } else {
        features.push(feature);
        attributes.push(attrs[index]);
      }
    });

    return { features, attributes };
  }

  public getShape(agFeatures: any[], encoding: ShapeEncoding, agAttrs: QueryFeature[] = null, fields: FieldConfig[] = null) {
    const results = { point: null, polygon: null, polyline: null };
    let { features, attributes } = this.convert(agFeatures, agAttrs, fields);

    if (attributes) {
      features = this.fillAttributesFromQueryFeature(features, attributes, fields);
    }

    const proj = this.getProjFile();
    const outputObject = new ExportToShape().createShapeFiles(features, encoding, proj);

    if (outputObject.pointShapefile.successful) {
      results.point = outputObject.pointShapefile.shapefile;
    }

    if (outputObject.polygonShapefile.successful) {
      results.polygon = outputObject.polygonShapefile.shapefile;
    }

    if (outputObject.polylineShapefile.successful) {
      results.polyline = outputObject.polylineShapefile.shapefile;
    }

    return [((results.point || results.polygon || results.polyline) && results) || null, attributes];
  }

  getProjFile() {
    /// TODO: можно скачивать файл с сайта https://spatialreference.org
    /// например: https://spatialreference.org/ref/sr-org/epsg3857-wgs84-web-mercator-auxiliary-sphere/prj/
    return this.projection3857;
  }

  fillAttributesFromQueryFeature(arcgisFeaturesList: any, queryFeaturesList: QueryFeature[], fieldsDescription: FieldConfig[]): any {
    for (const arcgisFeature of arcgisFeaturesList) {
      for (const queryFeature of queryFeaturesList) {
        const aId = arcgisFeature.attributes.guid;
        const qId = queryFeature.guid;

        if (aId === qId) {
          const flatAttributes = queryFeature.getFlatAttributes(fieldsDescription);

          for (const attrName in flatAttributes) {
            const attr = flatAttributes[attrName];
            arcgisFeature.attributes[attrName] = !attr ? " " : String(attr);
          }
        }
      }
    }

    return arcgisFeaturesList;
  }

  public downloadFile(fileName: string, content: Blob, mimeType: string = null, useBlob: boolean = true) {
    mimeType = mimeType || "application/octet-stream";

    let url = null;

    const dataURI = "data:" + mimeType + "," + content;
    const link = document.createElement("a");
    const blob = new Blob([content], { type: mimeType });

    if (typeof link.download !== "undefined") {
      url = useBlob ? window.URL.createObjectURL(blob) : dataURI;

      link.setAttribute("href", url);
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      return null;
    } else if (navigator.msSaveOrOpenBlob) {
      return navigator.msSaveOrOpenBlob(blob, fileName);
    }

    window.open(dataURI);
    window.focus();

    return null;
  }
}
