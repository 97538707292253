import { ConfigResource } from "@shared/config/config-resource";
import { ConfigService } from "@shared/config/config-service";
import { FieldConfig } from "@shared/config/config-field";
import { GenericService } from "../service/generic-service";
import { WegaServiceCapabilities } from "../service/service-capabilities";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";
import { ServiceType } from "@shared/wega-utils/wega-enums";
import { WegaUtils } from "@shared/wega-utils/wega-utils";

export class QueryFeature {
  public guid: string;
  public id: string;
  public coords: any;
  public attributes: {};
  public htmlResponses: string[];
  public defaultDisplayName: string;
  public arcgisFeature: any;

  public source: any;
  public geometry: any;

  /** Конфигурация сервиса, из которого был считан объект. */
  public serviceConfig: ConfigService;

  /** Сервис, из которого был считан объект. */
  public service: GenericService;

  /** Разрешен ли режим редактирования для данного объекта. */
  public editable = false;

  constructor(serviceConfig: ConfigService, capabilities: WegaServiceCapabilities, public locale: LocaleProvider) {
    this.attributes = {};
    this.htmlResponses = [];
    this.guid = WegaUtils.createGuid();

    this.serviceConfig = serviceConfig;
    this.editable = capabilities.canAttributesBeEdited;
  }

  public uiShowDetails = false;

  public setDefaultDisplayName(displayNameAttributes: string[]) {
    const flatAttrs = this.getFlatAttributes([]);
    let name = "";

    for (const attrName of displayNameAttributes) {
      const attrValue = flatAttrs[attrName] || flatAttrs[`${attrName} `];

      if (attrValue && attrValue !== "null" && attrValue !== "NULL") {
        name += " " + attrValue;
      }
    }

    if (!name) {
      name = this.locale.current === "en" ? "Object" : "Объект";
    }

    this.defaultDisplayName = name;
    return name;
  }

  getArcGisCoords(): any {
    return this.coords;
  }

  public getFlatAttributes(fieldsDescriptionsList: FieldConfig[] = null) {
    const answer = {};

    for (const moduleName in this.attributes) {
      if (fieldsDescriptionsList && fieldsDescriptionsList.length > 0) {
        for (const field of fieldsDescriptionsList) {
          const keyName = field.filterName;
          if (this.attributes[field.module]) {
            const val = this.attributes[field.module][field.name];

            answer[keyName] = val;
          }
        }
      } else {
        for (const attrName in this.attributes[moduleName]) {
          // let keyName = moduleName + "-" + attrName;
          const keyName = attrName;
          const val = this.attributes[moduleName][attrName];

          answer[keyName] = val;
        }
      }
    }

    return answer;
  }

  addAtributes(sublayerName: string, attributes: any, idAttrName: string = null) {
    const tmpAttr = Object.assign({}, this.attributes);
    tmpAttr[sublayerName] = attributes;
    this.attributes = tmpAttr;

    this.setID(sublayerName, idAttrName);
  }

  addHtmlResponse(subLayerName: string, response: string) {
    this.htmlResponses.push(response);
  }

  addArcGISAtributes(sublayerName: any, arcGisResponse: string, idAttrName: string = null, fieldAliases: any = null) {
    this.setGeometry(arcGisResponse["geometry"]);

    this.arcgisFeature = arcGisResponse;
    let attrs = arcGisResponse["attributes"];

    if (fieldAliases) {
      attrs = this.translateAttributes(attrs, fieldAliases);
    }

    this.addAtributes(sublayerName, attrs, idAttrName);
  }

  translateAttributes(attrsList: any, fieldAliases: any): any {
    const answer = {};

    for (const srcName in attrsList) {
      const newName = fieldAliases[srcName] ? fieldAliases[srcName] : srcName;
      answer[newName] = attrsList[srcName];
    }

    return answer;
  }

  addWMSAtributes(sublayerName: any, wmsAtributes: string, idAttrName: string = null) {
    this.setGeometry(wmsAtributes["gml:boundedBy"]);
    this.addAtributes(sublayerName, wmsAtributes, idAttrName);
  }

  private setID(sublayerName: any, idAttrName: string) {
    if (idAttrName && this.attributes[sublayerName][idAttrName]) {
      this.id = this.attributes[sublayerName][idAttrName];
    }
  }

  public setGeometry(geometry: any) {
    this.coords = geometry;
  }

  isSupportedServiceAdd(): boolean {
    const attrList = this.getFlatAttributes([]);

    return !!attrList["WMS_URL"];
  }

  getNewResource(): ConfigResource {
    const newService = new ConfigService({});
    const attrList = this.getFlatAttributes([]);

    const newResource = new ConfigResource({});
    newResource.title = this.defaultDisplayName;

    newService.type = ServiceType.wms;
    newService.title = "map";
    newService.url = attrList["WMS_URL"];
    newService.layers = [attrList["WMS_LAYER"]];
    newService.supportMap = true;

    newService.supportClick = false; /// по умолчанию отключено, т.к. когда этих слоев много - работает очень медленно
    newService.supportQuery = false;

    newResource.servicesList.push(newService);
    return newResource;
  }
}
