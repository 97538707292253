import { WegaUtils } from "@shared/wega-utils/wega-utils";
import { ResourceProvider } from "@wega-providers/resource.provider";

export const DUMMY_RESOURCE_TOKEN = "_dummy";
export const DUMMY_FILTER_TOKEN = "_dummyFilter";

export const addGlobalFilter = (
  res: ResourceProvider,
  extent: [number, number][],
  options: {
    color: number[];
  } = { color: [255, 255, 255, 255] }
) => {
  const dummy = res.resourceList.find((r) => r.id.startsWith(DUMMY_FILTER_TOKEN));
  const feature = {
    type: "FeatureCollection",
    name: "filter",
    crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
    features: [
      {
        type: "Feature",
        properties: { id: WegaUtils.createGuid() },
        geometry: { type: "Polygon", coordinates: [[...extent]] },
      },
    ],
  };

  dummy.filterLayer.clear();
  dummy.filterLayer.loadFeaturesFromGeoJSON(feature, options.color);
};

export const clearGlobalFilter = (res: ResourceProvider) => {
  const dummy = res.resourceList.find((r) => r.id.startsWith(DUMMY_FILTER_TOKEN));
  dummy.filterLayer.clear();
};
