import { Injectable } from "@angular/core";
import { StorageProvider } from "./storage.provider";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { EsriProvider } from "./esri.provider";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { UtilsProvider } from "@shared/providers/utils.provider";
import { BehaviorSubject } from "rxjs";
import { FilterSpatial } from "@domain/data/filter/filter-spatial";
import { ConfigService } from "@shared/config/config-service";
import { WegaResource } from "@domain/data/resource/wega-resource";
import { AreaFilterProvider } from "./area-filter.provider";
import { DUMMY_RESOURCE_TOKEN, DUMMY_FILTER_TOKEN, addGlobalFilter, clearGlobalFilter } from "../utils/resource.utils";
import { ConfigResource } from "@shared/config/config-resource";
import { MapClickPoint } from "@domain/data/structures/map-click-point";
import { ResourceFilter } from "@domain/data/filter/resource-filter";
import { QueryFeature } from "@domain/data/feature/query-feature";
import { PluginsProvider } from "./plugins.provider";
import { GenericService } from "@domain/data/service/generic-service";
import { LocaleProvider } from "../../i18n/providers/i18n.provider";
import { OlProvider } from "./ol.provider";
import { ExternalSitesAdapter } from "../utils/external-sites-adapter";
import { DynamicDatabase } from "@wega3/components/catalog-tree/dynamic-database";
import { ActivatedRoute, Router } from "@angular/router";
import { GEOMETRY_OPERATION } from "@shared/wega-utils/wega-enums";
import { SpatialFilterInfo } from "@shared/wega-utils/global-config";
import { FilterItem } from "../utils/area-filter.utils";
import { LayerProvider } from "./layer.provider";
import { ArcGisMapProvider } from "../../wega-ui/components/arcgis-map/providers/arcgis-map.provider";

@Injectable()
export class ResourceProvider {
  globalSpatialFilter = Array<FilterSpatial>();
  basemaps$ = new BehaviorSubject<Array<ConfigService>>([]);

  public resourceList$: BehaviorSubject<WegaResource[]> = new BehaviorSubject([]);
  public resourceList: WegaResource[];

  public mapLayersList: any[];

  private _order = 0;
  externalSitesAdapter: ExternalSitesAdapter;

  constructor(
    public web: WebClientProvider,
    public esri: EsriProvider,
    public ag: ArcGisMapProvider,
    public ol: OlProvider,
    public afp: AreaFilterProvider,
    private storage: StorageProvider,
    private appConfig: AppConfigProvider,
    private plugins: PluginsProvider,
    public layerProvider: LayerProvider,
    private locale: LocaleProvider,
    private utils: UtilsProvider,
    public database: DynamicDatabase,
    private route: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.database.res = this; // временно вынес зависимость из класа DynamicDatabase - возникала ошибка.
    this.clearResourceList();
    this.mapLayersList = [];
    this.externalSitesAdapter = new ExternalSitesAdapter(this, utils);

    this.utils.setFrameworkModule("Resource", {
      _provider: this,
      _debug: () => {
        this.resourceList.forEach((r) => (r.config.filterEnabled = true));
        this.resourceList.forEach((r) => r.serviceModuleList.forEach((s) => (s.getConfig().downloadEnabled = true)));
      },

      list: () => this.resourceList,

      clear: async () => {
        for (const r of this.resourceList) {
          await this.removeResource(r);
        }

        this.clearResourceList();
      },

      addJSONResources: async (cs: any[]) => {
        for (const config of cs) {
          await this.addResourceConfig(new ConfigResource(config));
        }
      },

      addResourceConfigs: async (cs: any[]) => {
        for (const config of cs) {
          await this.addResourceConfig(config);
        }
      },

      addFilter: (extent, options) => addGlobalFilter(this, extent, options),
      clearFilter: () => clearGlobalFilter(this),

      moveById: (id: string, index: number) => {
        const resource = this.resourceList.find((r) => r.id === id);
        const from = this.resourceList.indexOf(resource);
        this.changeResourcePosition(from, index);
      },
    });
  }

  changeResourcePosition(indexFrom: number, indexTo: number, memorize = true) {
    const old = this.resourceList.splice(indexFrom, 1)[0];
    this.resourceList.splice(indexTo, 0, old);

    this.updateLayersList();
    if (memorize) {
      this.memorizeResources();
    }
  }

  pinResources() {
    this.resourceList
      .filter((r) => r.config.pinned)
      .forEach((r) => {
        const index = this.resourceList.indexOf(r);

        this.changeResourcePosition(index, 0);
      });
  }

  public resourceListNoDummies(): WegaResource[] {
    return this.resourceList.filter((r) => !r.id.startsWith(DUMMY_RESOURCE_TOKEN));
  }

  public searchableResources(): WegaResource[] {
    return this.resourceList.filter((r) => r.show && !r.capabilities.isBaseResource && !r.capabilities.isDummyResource);
  }

  public dummyResources(): WegaResource[] {
    return this.resourceList.filter((r) => r.id.startsWith(DUMMY_RESOURCE_TOKEN));
  }

  public clearResourceList(): void {
    this.resourceList = [];
  }

  async loadConfig(url: string) {
    const config = await this.web.httpGet<any>(url);

    for (const resourceConfig of config) {
      this.addResource(resourceConfig);
    }
  }

  public async removeResource(resource: WegaResource, memorize: boolean = true) {
    this.plugins.cancelConditionalPlugins(resource, "service-start");

    const indexOf = this.resourceList.indexOf(resource);
    if (indexOf >= 0) {
      this.resourceList.splice(indexOf, 1);
    }

    // для совместимости с WMTS-слоями
    resource.show = true;
    resource.serviceModuleList.forEach((s) => {
      s.getMapService()["wmts__removeLayer"]?.();
    });

    await this.updateLayersList();
    this.utils.notify(`${this.locale.current !== "en" ? "Выключен ресурс " : "Disabled resource "} '${resource.title}'`);

    if (memorize) {
      this.memorizeResources();
    }

    resource.onResourceRemove();
  }

  public getResourceList(): WegaResource[] {
    return this.resourceList;
  }

  public async addResource(resourceConfig: object): Promise<{ config: ConfigResource; resource: WegaResource }> {
    const config = new ConfigResource(resourceConfig);
    const resource = await this.addResourceConfig(config);

    return { config, resource };
  }

  public async addResourceConfig(resourceConfig: ConfigResource, memorize = true): Promise<WegaResource> {
    const newResource = new WegaResource(
      resourceConfig,
      this.web,
      this.esri,
      this.ag,
      this.ol,
      this.utils,
      this.appConfig,
      this.locale,
      this.layerProvider,
      this._order++
    );
    this.resourceList.unshift(newResource);

    newResource.onLayersChanged.subscribe(() => this.updateLayersList());
    newResource.onFeatureHighlight.subscribe((featureId) => {
      // this.HighlightFeature(newResource.ID, featureID);
      if (featureId) {
        this.clearHighlight(newResource.id);
      }
    });

    this.pinResources();

    if (!newResource.isDummy()) {
      await this.updateLayersList();

      this.utils.notify((this.locale.current !== "en" ? "Включен ресурс '" : "Enabled resource '") + resourceConfig.title + "'");
    }

    if (memorize) {
      this.memorizeResources();
    }

    this.plugins.applyConditionalPlugins(newResource, "service-start");
    newResource.onResourceAdd();
    return newResource;
  }

  memorizeResources() {
    const newState = this.resourceListNoDummies().map((r) => r.config._source);
    this.storage.register((state) => (state.Resources = newState));

    this.updateLayersQueryParams(newState.map((r) => r.id));
  }

  public async updateCoordsQueryParams(x: number, y: number, z: number) {
    const layersIdList = this.resourceListNoDummies().map((r) => r.id);

    const queryParams = {
      g: y + "," + x + "," + z,
    };

    for (const layerId of layersIdList) {
      queryParams["map_" + layerId] = 1;
    }

    this.route.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: "",
      preserveFragment: false,
    });
  }

  public async updateLayersQueryParams(layersIdList: string[]) {
    const queryParams = {
      //layers: layersIdList.join(",")
    };

    for (const layerId of layersIdList) {
      queryParams["map_" + layerId] = 1;
    }

    this.route.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: "",
      preserveFragment: false,
    });
  }

  private async updateLayersList() {
    let list = [];

    for (const resource of this.resourceList) {
      const resourceLayers = await resource.getResourceLayers();

      list = list.concat(resourceLayers);

      // var index = map.layers.findIndex(layer => { return layer.id === "graphicsLayer1";});
      // map.layers.reorder(map.layers.getItemAt(ind), map.layers.length - 1);
    }

    this.mapLayersList = list;
  }

  public async onMapClick(coords: MapClickPoint) {
    if (this.globalSpatialFilter.length > 0) {
      let isInside = false;
      for await (const f of this.globalSpatialFilter) {
        isInside = isInside || (await f.pointIsInside(coords.x, coords.y, coords.srs));
      }

      if (!isInside) {
        this.resetSelection();

        return null;
      }
    }

    for await (const resource of this.resourceList) {
      await resource.getPointFeatureInfo(coords);
    }
  }

  async setGlobalSpatialFilter(spatialFilter: FilterSpatial) {
    this.globalSpatialFilter.push(spatialFilter);
    await this._setGlobalSpatialFilter();
  }

  async setGlobalSpatialFilters(spatialFilters: FilterSpatial[]) {
    this.globalSpatialFilter = spatialFilters;
    await this._setGlobalSpatialFilter();
  }

  async _setGlobalSpatialFilter() {
    const operation = GEOMETRY_OPERATION.DIFFERENCE;
    const dummyMask = this.resourceList.find((r) => r.id.startsWith(DUMMY_FILTER_TOKEN));
    let filteredResources = this.getResourceList();

    if (dummyMask) {
      // для фильтра целесообразно использовать псевдослой, так как его статус не зависит от статуса "обычных" слоев
      filteredResources = [dummyMask];
    }

    for (const resource of filteredResources) {
      // res.filter.SetSpatial("RELATION", spatFilter);
      // res.ApplyFilter();
      if (resource.capabilities.isBaseResource) {
        // operation = GEOMETRY_OPERATION.INTERSECT;
        // res.RestrictView(this.globalSpatialFilter);
      }

      await resource.setSpatialMask(this.globalSpatialFilter, operation);
    }
  }

  clearHighlight(skippedResourceID: string) {
    for (const resource of this.resourceList) {
      if (resource.id !== skippedResourceID) {
        resource.highlightResourceFeature(null);
      }
    }
  }

  public highlightFeature(resourceID: string, featureID: string) {
    for (const resource of this.resourceList) {
      if (resource.id === resourceID) {
        resource.highlightResourceFeature(featureID);
      } else {
        resource.highlightResourceFeature(null);
      }
    }
  }

  resetSelection() {
    this.resourceList.forEach((r) => r.resetSelection());
  }

  setSearchExpiration(hasExpired: boolean) {
    this.resourceList.forEach((r) => (r.searchExpired = hasExpired));
  }

  async getSpatialFilterFor(filters: SpatialFilterInfo[], item: FilterItem, spatialFilterName: string[]): Promise<FilterSpatial> {
    const [] = await this.esri.loadModules(["esri/geometry/Polygon"]);
    // filters = this.globalConfig.Environment.SpatialFilters[filterType];

    let foundFeatures: any[] = [];

    for (const filterKey in filters) {
      // Если предыдущий фильтр вернул результаты, то последующие можно игнорировать
      if (foundFeatures.length) {
        continue;
      }

      const filterInfo = filters[filterKey];

      /// создание динамического сервиса, нужного только для извлечения геометрии фильтра
      const resourceConfig = new ConfigResource({});
      const layerConfig = new ConfigService(filterInfo.service);
      const service = new GenericService(
        resourceConfig,
        layerConfig,
        this.web,
        this.utils,
        this.esri,
        this.ol,
        this.appConfig,
        this.layerProvider,
        this.locale
      );
      const filterService = service.getMapService();
      const resourceFilter = new ResourceFilter(this.esri);

      spatialFilterName.forEach((sfn) => {
        resourceFilter.addConditionStructure(filterInfo.field, "=", `${sfn}`);
      });

      [foundFeatures] = await filterService.getFeaturesByQuery(resourceFilter);
    }

    /// объединить все найденные features в один мультиполигон
    let filterSingleFeature = null;

    if (foundFeatures.length > 0) {
      filterSingleFeature = foundFeatures[0];
      for (let i = 1; i < foundFeatures.length; i++) {
        const featureCoords = foundFeatures[i].coords;
        filterSingleFeature.coords.rings = filterSingleFeature.coords.rings.concat(featureCoords.rings);
      }
    }

    if (filterSingleFeature) {
      const feature: QueryFeature = filterSingleFeature;
      const geometry = feature.getArcGisCoords();
      const filterResult = new FilterSpatial("SPATIAL_REL_RELATION", item.id, geometry, this.esri);

      return filterResult;
    }

    return null;
  }

  async resetFilter() {
    this.afp.currentFilterItems.forEach((f) => (f.active = false));
    this.afp.currentFilterItems[0].active = true; /// 1-й элемент - это всегда reset-element

    await this.resetMask();
  }

  async resetMask() {
    for await (const resource of this.resourceList) {
      await resource.clearSpatialMask();
    }

    this.globalSpatialFilter = [];
  }

  addWFS(url: string, layerName: string[], name: string) {
    const newWfsService = {
      ["type"]: "wfs",
      ["url"]: url,
      ["version"]: "1.1.0",
      ["wkid"]: 3857,
      ["maxFeatures"]: 10000,
      ["layers"]: layerName,
    };

    const newResource = {
      title: name,
      service: newWfsService,
    };

    this.addResource(newResource);
  }

  addWMSLayer(url: string, layerName: string, format: string = "text/html") {
    const newWmsService = {
      title: layerName,
      type: "wms",
      url,
      layers: [layerName],
      responseFormat: format,
    };

    const newResource = {
      title: layerName,
      service: newWmsService,
    };

    this.addResource(newResource);
  }

  public async onExtentSelect(event: any, resources = null) {
    if (this.globalSpatialFilter) {
      // TODO #4468
      // let srs = evt.target.map.spatialReference;
      // let geometry = evt.geometry;
      // let intersection = await this.globalSpatialFilter.intersectionWithGeometry(geometry, srs);
    }

    for await (const resource of resources ?? this.resourceList) {
      await resource.getExtentFeatureInfo(event);
    }
  }

  registerBasemapChange(basemapConfigs: ConfigService[]) {
    this.basemaps$.next(basemapConfigs);
  }
}
