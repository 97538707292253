import { GeoExtent } from "@domain/data/structures/extent";
import { ServiceType } from "@shared/wega-utils/wega-enums";
import { WegaUtils } from "@shared/wega-utils/wega-utils";
import { WegaPluginCondition } from "@wega-providers/plugins.provider";

export type ShapeEncoding = "UTF8" | "Win1251" | "default";

export class ConfigService {
  /** Идентификатор сервиса. */
  id: string;

  /** Название сервиса (которое видит пользователь). */
  title: string;

  /** Тип сервиса (wms, wfs, arcgis и т.п.) */
  type: ServiceType;

  /** URL (точка входа) сервиса. */
  url: string;

  /** URL прокси, который должен использоваться при загрузке сервиса. */
  proxy: string;

  /** Нужно ли кодировать URL при использовании прокси-сервера. По умолчанию = false. */
  encodeProxyRequest = false;

  /** Список имен слоев, которые нужно прочитать из сервиса (если он пуст, то включаются все слои). */
  layers: string[];

  /** Список слоев, которые должны быть активными при запуске карты. По умолчанию = null. */
  startLayers: string[];

  /** Список имен слоев, к которым делать getQueryInfo  (если он пуст, то используется список из layers). */
  queryLayers: string[];

  /** Список псевдонимов слоев. По умолчанию = []. */
  layerAliases: Array<{ name: string; alias: string }> = [];

  /** Максимальное количество объектов, получаемое за один запрос к ГИС-серверу. По умолчанию = 1000. */
  maxFeatures = 1000;

  /** Разрешено редактирование данных (атрибутивных) слоя. По умолчанию = false. */
  edit = false;

  /** Разрешено редактирование геометрии слоя. По умолчанию = false. */
  editGeometry = false;

  /** Имя поля, в котором содержится уникальный идентификатор объекта. */
  fieldID: string;

  /** Формат ответа (например, ожидаемый при WMS-запросе). По умолчанию = 'application/vnd.ogc.gml' */
  responseFormat = "application/vnd.ogc.gml";

  /** Видимость сервиса. По умолчанию = true. */
  visible = true;

  /** Прозрачность карты сервиса. По умолчанию = 1. */
  opacity = 1;

  /** Данные сервиса (например GeoJSON), требуются для создания сервиса с данными. */
  content: any;

  /** Версия используемого протокола. Для WMS: '1.0.0', '1.1.0', '1.1.1', '1.3.0'. Для WFS: '1.0.0'. По умолчанию = null. */
  version: string;

  /** Поддерживает ли сервис GetFeatureInfo запросы (для WMS). По умолчанию = true. */
  supportClick: boolean = true;

  /** Поддерживает ли сервис рисование карты. По умолчанию = true. */
  supportMap: boolean = true;

  /** Поддерживает ли сервис запросы объектов по атрибутам. По умолчанию = true. */
  supportQuery: boolean = true;

  /** Экстент сервиса. */
  extent: GeoExtent;

  /** Список парсеров легенды. Стандартные парсеры: 'ag-font'. По умолчанию = []. */
  legendParsers: Array<"ag-font"> = [];

  /** Текст, добавляемый к заголовку легенды. По умолчанию = []. */
  addTextToLegend: Array<{ name: string; prefix: string; postfix: string }> = [];

  /** Текст, добавляемый к описаниям элементов легенды. По умолчанию = []. */
  addTextToLegendElements: Array<{ name: string; prefix: string; postfix: string }> = [];

  /** Элементы легенды слоев, которые не нужно отображать. По умолчанию = []. */
  forbiddenLegendElements: Array<{ name: string; label: string }> = [];

  /** Список слоев сервиса, для которых не надо выводить легенду. Чтобы спрятать все слои, можно указать ["*"]. По умолчанию = []. */
  hideLegends: Array<string> = [];

  /** Список слоев сервиса, которые выводятся спрятанными. По умолчанию = []. */
  /** Пока полноценно реализовано только для ArcGIS-слоев */
  hideLayers: Array<string> = [];

  /** Правила трансформации текста ответа (для WMS GetFeatureInfo). По умолчанию = []. */
  htmlRewrite: Array<{ find: string; replaceWith: string }> = [];

  /** Список шрифтов, которые необходимо внедрить на страницу для обеспечения корректного отображения сервиса. По умолчанию = {}. */
  requiredFonts: { [key: string]: string } = {};

  /** Список всех слоев сервиса, создается динамически. По умолчанию = []. */
  availableLayers: Array<{ name: string; title: string; active: boolean; enlisted: boolean; children: any[] }> = [];

  /** Доступность сервиса. Определяется динамически. */
  public onlineStatus: {
    status: string;
    message: string;
    indicator: string; // временно
  } = { status: "-1", message: "", indicator: "black" };

  /** Наличие кэшированного зеркала для сервиса. По умолчанию = '-1'. */
  public cacheStatus = "-1";

  /** Дополнительные сведения о сервисе (в свободной форме). По умолчанию = null. */
  public description: string = null;

  /** Описание нестандартного стиля. По умолчанию = null. */
  public style: string = null;

  /** Разрешено ли скачивания данных сервиса. По умолчанию = false. */
  public downloadEnabled = false;

  /** Способен ли сервис предоставлять список слоев. По умолчанию = true. */
  public layersListEnabled = true;

  /** Кодировка данных сервиса (используется для формирования CPG-файла при скачивании). */
  public encoding: ShapeEncoding;

  /** Имя поля, используемое в качестве имени объекта в при отборе данных. По умолчанию = [].  */
  public attributeName: string[] = [];

  /** Дополнительные параметры. По умолчанию = { "x": "y" }.  */
  public customParameters: { [key: string]: string } = { x: "y" };

  /** Неструктурированные сведения о плагинах-обработчиках ресурса (их парсинг реализуется поставщиками конкретных плагинов). */
  public plugins: { [pluginId: string]: { call: Array<WegaPluginCondition> } & { [key: string]: string } } = {};

  /** Исходный объект JSON-конфига. */
  public _source: any = {};

  constructor(sourceConfig: any) {
    this._source = sourceConfig;

    this.type = this.getServiceType(sourceConfig["type"]);
    this.id = sourceConfig["id"] || WegaUtils.createGuid();
    this.url = sourceConfig["url"];
    this.layers = sourceConfig["layers"];
    this.startLayers = sourceConfig["startLayers"];
    this.queryLayers = sourceConfig["queryLayers"];
    this.layerAliases = sourceConfig["layerAliases"] || [];
    this.maxFeatures = sourceConfig["maxFeatures"] || this.maxFeatures;
    this.hideLegends = sourceConfig["hideLegends"] || this.hideLegends;
    this.hideLayers = sourceConfig["hideLayers"] || this.hideLayers;
    this.addTextToLegend = sourceConfig["addTextToLegend"] || this.addTextToLegend;
    this.addTextToLegendElements = sourceConfig["addTextToLegendElements"] || this.addTextToLegendElements;
    this.forbiddenLegendElements = sourceConfig["forbiddenLegendElements"] || this.forbiddenLegendElements;

    this.supportClick = ConfigService.getConfigValue(sourceConfig, "supportClick", this.supportClick);
    this.supportMap = ConfigService.getConfigValue(sourceConfig, "supportMap", this.supportMap);
    this.supportQuery = ConfigService.getConfigValue(sourceConfig, "supportQuery", this.supportQuery);
    this.downloadEnabled = ConfigService.getConfigValue(sourceConfig, "downloadEnabled", this.downloadEnabled);
    this.layersListEnabled = ConfigService.getConfigValue(sourceConfig, "layersListEnabled", this.layersListEnabled);
    this.visible = ConfigService.getConfigValue(sourceConfig, "visible", this.visible);
    this.edit = ConfigService.getConfigValue(sourceConfig, "edit", this.edit);
    this.editGeometry = ConfigService.getConfigValue(sourceConfig, "editGeometry", this.editGeometry);

    this.title = sourceConfig["title"] || this.id;
    this.version = sourceConfig["version"] || this.version;
    this.opacity = sourceConfig["opacity"] || this.opacity;
    this.fieldID = sourceConfig["fieldID"] || this.fieldID;
    this.responseFormat = sourceConfig["responseFormat"] || this.responseFormat;

    this.proxy = sourceConfig["proxy"] || this.proxy;
    this.encodeProxyRequest = ConfigService.getConfigValue(sourceConfig, "encodeProxyRequest", false);

    this.content = sourceConfig["content"] || this.content;
    this.attributeName = sourceConfig["attributeName"] || this.attributeName;
    this.customParameters = sourceConfig["customParameters"] || this.customParameters;

    this.description = sourceConfig["description"]?.join ? sourceConfig["description"].join("\n") : sourceConfig["description"];
    this.style = sourceConfig["style"];
    this.legendParsers = sourceConfig["legendParsers"] || [];
    this.requiredFonts = sourceConfig["requiredFonts"] || {};
    this.encoding = sourceConfig["encoding"] || "UTF8";
    this.htmlRewrite = sourceConfig["htmlRewrite"] || [];

    if (sourceConfig["extent"]) {
      this.extent = new GeoExtent("4326", sourceConfig["extent"][0], sourceConfig["extent"][1], sourceConfig["extent"][2], sourceConfig["extent"][3]);
    }

    this.plugins = sourceConfig["plugins"] || {};
  }

  public static getConfigValue(configArray: object, variableName: string, defaultValue: any): any {
    if (typeof configArray === "object" && configArray.hasOwnProperty(variableName)) {
      return configArray[variableName];
    }

    return defaultValue;
  }

  merge(newServiceConfig: ConfigService) {
    this.url = newServiceConfig.url || this.url;
    this.layers = newServiceConfig.layers || this.layers;
    this.queryLayers = newServiceConfig.queryLayers || this.queryLayers;

    this.title = newServiceConfig.title || this.title;
    this.visible = newServiceConfig.visible || this.visible;
    this.opacity = newServiceConfig.opacity || this.opacity;
    this.edit = newServiceConfig.edit || this.edit;
    this.editGeometry = newServiceConfig.editGeometry || this.editGeometry;
    this.fieldID = newServiceConfig.fieldID || this.fieldID;
    this.responseFormat = newServiceConfig.responseFormat || this.responseFormat;
    this.proxy = newServiceConfig.proxy || this.proxy;
    this.content = newServiceConfig.content || this.content;
  }

  getServiceType(textValue: string): ServiceType {
    const type: ServiceType = ServiceType[textValue];
    return type;
  }

  isMap() {
    const thisIsMap =
      this.type === ServiceType.vector ||
      this.type === ServiceType.arcgis ||
      this.type === ServiceType.arcgisfeature ||
      this.type === ServiceType.arcgistiled ||
      this.type === ServiceType.nextgiswebmap ||
      this.type === ServiceType.wfs ||
      this.type === ServiceType.wms ||
      this.type === ServiceType.wmts ||
      this.type === ServiceType.kml ||
      this.type === ServiceType.geojson ||
      this.type === ServiceType.xyz;

    return thisIsMap;
  }
}
