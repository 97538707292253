import {
  Directive,
  ElementRef,
  Renderer2,
  Input,
  OnDestroy,
  OnInit,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { LocaleProvider } from "../providers/i18n.provider";
import { LocaleStrings } from "../common";
import { Subscription } from "rxjs";

@Directive({ selector: "[locale]" })
export class LocaleValueDirective implements OnInit, OnDestroy, OnChanges {
  private subscription: Subscription;

  private default: LocaleStrings;
  @Input() locale: LocaleStrings;

  ngOnChanges(_: SimpleChanges): void {
    if (!this.default && this.locale) {
      this.default = {};

      for (const key in this.locale) {
        const initial = this.element.nativeElement[key];
        this.default[key] = initial;
      }
    }
  }

  constructor(
    private localeProvider: LocaleProvider,
    private element: ElementRef
  ) {}

  ngOnInit() {
    this.subscription = this.localeProvider.currentLanguage$.subscribe(
      (language) => {
        for (const key in this.locale) {
          const value = this.locale[key][language];

          if (value) {
            this.element.nativeElement[key] = value;
          } else {
            this.element.nativeElement[key] = this.default[key];
          }
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
