import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { WegaAuthenticationProvider } from "../providers/authentication.provider";
import { OidcSecurityService } from "angular-auth-oidc-client";

@Injectable()
export class WegaAuthenticationGuard implements CanActivate {
  constructor(public auth: WegaAuthenticationProvider) {}

  canActivate() {
    return this.auth.AuthInfo.Authenticated;
  }
}
